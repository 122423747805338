import React, { useState, useEffect } from 'react';
import AdminLayout from '../../Common/AdminLayout/AdminLayout';
import { Col, Container, Row, Table, Dropdown } from 'react-bootstrap';
import { getOrderList } from '../../../api/order';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import "./OrderList.css";
import { extractDateFromDateAndTime } from './../../../lib/commonFunction';
import Loader from '../../Common/Loader/Loader';
import { orderStatusApi } from './../../../api/order';
import { showNotifications } from '../../../lib/toaster';
import { ToastContainer } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';

const OrderList = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleLayoutStateChange = (clickedInParent: boolean) => {
        setIsCollapsed(!clickedInParent);
    };
    const [orderList, setOrderList] = useState<any[]>([]);
    const [sortPropertyOne, setSortPropertyOne] = useState("");
    const [sortPropertyTwo, setSortPropertyTwo] = useState("");
    const [sortOne, setSortOne] = useState("asc");
    const [sortTwo, setSortTwo] = useState("asc");
    const [noFoods, setNoFoods] = useState<boolean>(false);

    const [prioritySerialOne, setPrioritySerialOne] = useState<number>(0);
    const [prevButton, setPrevButton] = useState<boolean>(false);
    const [nextButton, setNextButton] = useState<boolean>(false);
    const [pageValue, setPageValue] = useState<number | null>();
    const [count, setCount] = useState<number>(1);
    const [totalValue, setTotalValue] = useState<number>(0);
    const [limit, setLimit] = useState<number>(25);
    const [page, setPage] = useState<number>(1);
    const pageCount = Math.ceil(totalValue / limit);

    let orderStatus = ["PENDING", "CANCELED", "PROCESSING", "FULFILLED"]
    const [deliveryDate, setDeliveryDate] = useState<any>("");
    
    useEffect(() => {
        getOrderList(sortPropertyOne, sortPropertyTwo, sortOne, sortTwo, limit, page, deliveryDate).then((data) => {
            if (data.data.orders.length === 0) {
                setNoFoods(true);
            }
            else {
                setNoFoods(false);
                setOrderList(data.data.orders);
                setPageValue(data.data.currentPage);
                setTotalValue(data.data.total);
            }

        });
        if (pageCount > 1) {
            setPrevButton(true)
        }
        if (page === 1) {
            setPrevButton(false)
        }
        // next button
        if (pageCount > 1) {
            setNextButton(true)
        }
        if (pageCount === page) {
            setNextButton(false)
        }
    }, [sortPropertyOne, sortPropertyTwo, sortOne, sortTwo, page, limit, pageCount, count, deliveryDate])


    const propertyOne = (value: string, serial: number) => {
        setSortPropertyOne(value);
        if (value === sortPropertyTwo) {
            setSortPropertyTwo("");
        }
        setPrioritySerialOne(serial);
    };

    const propertyTwo = (value: string, serial: number) => {
        setSortPropertyTwo(value);
        if (value === sortPropertyOne) {
            setSortPropertyOne("");
        }
        setPrioritySerialOne(serial);
    };
    const sortingOne = (value: string) => {
        setSortOne(value);
        setCount(count + 1);
    }
    const sortingTwo = (value: string) => {
        setSortTwo(value);
        setCount(count + 1);
    }
    const nextPage = () => {
        setPage(page + 1)
    }
    const prevPage = () => {
        setPage(page - 1)
    }

    const orderStatusChange = (orderId: number, cartId: number, status: string) => {
        let order = {
            "orderId": orderId,
            "cartId": cartId,
            "status": status
        }
        orderStatusApi(order).then((data) => {
            if (data.statusCode !== 200) {
                setNextButton(false)
                showNotifications('error', data.message)
                setCount(count + 1);
            }
            else {
                showNotifications('success', data.message)
                setCount(count + 1);
            }

        });
    }

    const orderDate = (date: Date) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        setDeliveryDate(formattedDate);
    }


    return (
        <AdminLayout onLayoutStateChange={handleLayoutStateChange}>
            <section className={`main-body ${isCollapsed ? '' : 'bodyCollapsed'}`}>
                <ToastContainer />
                <Container>
                    <Row>
                        {noFoods && noFoods === true ? <>
                            <Col md={12}>
                                <h1 className='text-center'>No Order list</h1>
                            </Col>
                            <Col md={12}>
                                      <div className="dateOrderSort">
                                      <p>Order search: </p>
                                        <DatePicker locale="en-gb" className="form-control" selected={deliveryDate} onChange={orderDate} placeholderText="Select a date" dateFormat="yyyy/MM/dd" />
                                      </div>
                                    </Col>
                        </> : <>
                            {orderList && orderList.length > 0 ?
                                <>
                                    <Col md={12}>
                                        <h1 className='text-center'>Order list</h1>
                                    </Col>
                                    <Col md={12}>
                                      <div className="dateOrderSort">
                                      <p>Order search: </p>
                                        <DatePicker locale="en-gb" className="form-control" selected={deliveryDate} onChange={orderDate} placeholderText="Select a date" dateFormat="yyyy/MM/dd" />
                                      </div>
                                    </Col>
                                    <Col ms={12} className='tableList'>
                                        <Table responsive bordered hover className='align-items-center'>
                                            <thead>
                                                <tr className='sortThead'>
                                                    <th>#</th>
                                                    <th className='tableSort'>
                                                        <Dropdown>

                                                            <Dropdown.Toggle>
                                                                E-mail
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => propertyOne("email", 1)} className={sortPropertyOne === "email" ? 'activePriority' : 'disablePriority'}>Sorting E-mail #1</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => propertyTwo("email", 2)} className={sortPropertyTwo === "email" ? 'activePriority' : 'disablePriority'}>Sorting E-mail #2</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                            {sortPropertyOne && sortPropertyOne === 'email' ? <>
                                                                <div className='markFirstActive'></div>
                                                                <div className='arrowTable'>
                                                                    <button onClick={() => sortingOne("asc")} className={sortOne === "asc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowUp} /></button>
                                                                    <button onClick={() => sortingOne("dsc")} className={sortOne === "dsc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowDown} /></button>
                                                                </div>
                                                            </> : ''}
                                                            {sortPropertyTwo && sortPropertyTwo === 'email' ? <>
                                                                <div className='markSecondActive'></div>
                                                                <div className='arrowTable'>
                                                                    <button onClick={() => sortingTwo("asc")} className={sortTwo === "asc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowUp} /></button>
                                                                    <button onClick={() => sortingTwo("dsc")} className={sortTwo === "dsc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowDown} /></button>
                                                                </div>
                                                            </> : ''}

                                                        </Dropdown>
                                                    </th>
                                                    <th className='tableSort'>
                                                        <Dropdown>

                                                            <Dropdown.Toggle>
                                                                Name
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => propertyOne("name", 1)} className={sortPropertyOne === "name" ? 'activePriority' : 'disablePriority'}>Sorting Name #1</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => propertyTwo("name", 2)} className={sortPropertyTwo === "name" ? 'activePriority' : 'disablePriority'}>Sorting Name #2</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                            {sortPropertyOne && sortPropertyOne === 'name' ? <>
                                                                <div className='markFirstActive'></div>
                                                                <div className='arrowTable'>
                                                                    <button onClick={() => sortingOne("asc")} className={sortOne === "asc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowUp} /></button>
                                                                    <button onClick={() => sortingOne("dsc")} className={sortOne === "dsc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowDown} /></button>
                                                                </div>
                                                            </> : ''}
                                                            {sortPropertyTwo && sortPropertyTwo === 'name' ? <>
                                                                <div className='markSecondActive'></div>
                                                                <div className='arrowTable'>
                                                                    <button onClick={() => sortingTwo("asc")} className={sortTwo === "asc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowUp} /></button>
                                                                    <button onClick={() => sortingTwo("dsc")} className={sortTwo === "dsc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowDown} /></button>
                                                                </div>
                                                            </> : ''}
                                                        </Dropdown>
                                                    </th>
                                                    <th className='tableSort'>
                                                        <Dropdown>
                                                            <Dropdown.Toggle>
                                                                Food
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => propertyOne("food", 1)} className={sortPropertyOne === "food" ? 'activePriority' : 'disablePriority'}>Sorting Food #1</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => propertyTwo("food", 2)} className={sortPropertyTwo === "food" ? 'activePriority' : 'disablePriority'}>Sorting Food #2</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                            {sortPropertyOne && sortPropertyOne === 'food' ? <>
                                                                <div className='markFirstActive'></div>
                                                                <div className='arrowTable'>
                                                                    <button onClick={() => sortingOne("asc")} className={sortOne === "asc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowUp} /></button>
                                                                    <button onClick={() => sortingOne("dsc")} className={sortOne === "dsc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowDown} /></button>
                                                                </div>
                                                            </> : ''}
                                                            {sortPropertyTwo && sortPropertyTwo === 'food' ? <>
                                                                <div className='markSecondActive'></div>
                                                                <div className='arrowTable'>
                                                                    <button onClick={() => sortingTwo("asc")} className={sortTwo === "asc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowUp} /></button>
                                                                    <button onClick={() => sortingTwo("dsc")} className={sortTwo === "dsc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowDown} /></button>
                                                                </div>
                                                            </> : ''}
                                                        </Dropdown>
                                                    </th>
                                                    <th className='tableSort'>
                                                        <Dropdown>
                                                            <Dropdown.Toggle>
                                                                Address
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => propertyOne("address", 1)} className={sortPropertyOne === "address" ? 'activePriority' : 'disablePriority'}>Sorting Address #1</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => propertyTwo("address", 2)} className={sortPropertyTwo === "address" ? 'activePriority' : 'disablePriority'}>Sorting Address #2</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                            {sortPropertyOne && sortPropertyOne === 'address' ? <>
                                                                <div className='markFirstActive'></div>
                                                                <div className='arrowTable'>
                                                                    <button onClick={() => sortingOne("asc")} className={sortOne === "asc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowUp} /></button>
                                                                    <button onClick={() => sortingOne("dsc")} className={sortOne === "dsc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowDown} /></button>
                                                                </div>
                                                            </> : ''}
                                                            {sortPropertyTwo && sortPropertyTwo === 'address' ? <>
                                                                <div className='markSecondActive'></div>
                                                                <div className='arrowTable'>
                                                                    <button onClick={() => sortingTwo("asc")} className={sortTwo === "asc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowUp} /></button>
                                                                    <button onClick={() => sortingTwo("dsc")} className={sortTwo === "dsc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowDown} /></button>
                                                                </div>
                                                            </> : ''}
                                                        </Dropdown>
                                                    </th>
                                                    <th className='tableSort'>
                                                        <Dropdown>
                                                            <Dropdown.Toggle>
                                                                Start time
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => propertyOne("startTime", 1)} className={sortPropertyOne === "startTime" ? 'activePriority' : 'disablePriority'}>Sorting Starttime #1</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => propertyTwo("startTime", 2)} className={sortPropertyTwo === "startTime" ? 'activePriority' : 'disablePriority'}>Sorting Starttime #2</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                            {sortPropertyOne && sortPropertyOne === 'startTime' ? <>
                                                                <div className='markFirstActive'></div>
                                                                <div className='arrowTable'>
                                                                    <button onClick={() => sortingOne("asc")} className={sortOne === "asc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowUp} /></button>
                                                                    <button onClick={() => sortingOne("dsc")} className={sortOne === "dsc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowDown} /></button>
                                                                </div>
                                                            </> : ''}
                                                            {sortPropertyTwo && sortPropertyTwo === 'startTime' ? <>
                                                                <div className='markSecondActive'></div>
                                                                <div className='arrowTable'>
                                                                    <button onClick={() => sortingTwo("asc")} className={sortTwo === "asc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowUp} /></button>
                                                                    <button onClick={() => sortingTwo("dsc")} className={sortTwo === "dsc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowDown} /></button>
                                                                </div>
                                                            </> : ''}
                                                        </Dropdown>
                                                    </th>
                                                    <th className='tableSort'>
                                                        <Dropdown>
                                                            <Dropdown.Toggle>
                                                                Delivery Date
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => propertyOne("deliveryDate", 1)} className={sortPropertyOne === "deliveryDate" ? 'activePriority' : 'disablePriority'}>Sorting Delivery Date #1</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => propertyTwo("deliveryDate", 2)} className={sortPropertyTwo === "deliveryDate" ? 'activePriority' : 'disablePriority'}>Sorting Delivery Date #2</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                            {sortPropertyOne && sortPropertyOne === 'deliveryDate' ? <>
                                                                <div className='markFirstActive'></div>
                                                                <div className='arrowTable'>
                                                                    <button onClick={() => sortingOne("asc")} className={sortOne === "asc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowUp} /></button>
                                                                    <button onClick={() => sortingOne("dsc")} className={sortOne === "dsc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowDown} /></button>
                                                                </div>
                                                            </> : ''}
                                                            {sortPropertyTwo && sortPropertyTwo === 'deliveryDate' ? <>
                                                                <div className='markSecondActive'></div>
                                                                <div className='arrowTable'>
                                                                    <button onClick={() => sortingTwo("asc")} className={sortTwo === "asc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowUp} /></button>
                                                                    <button onClick={() => sortingTwo("dsc")} className={sortTwo === "dsc" ? 'activeSort' : 'disableSort'}><FontAwesomeIcon icon={faArrowDown} /></button>
                                                                </div>
                                                            </> : ''}
                                                        </Dropdown>
                                                    </th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {orderList && orderList.map((data, i) => <tr className='foodListTable' key={`orderList` + i}>
                                                    <td>{i + 1}</td>
                                                    <td>{data.user.email}</td>
                                                    <td>{data.user.firstName} {data.user.lastName}</td>
                                                    <td>{data.cart.food.title}</td>
                                                    <td>{data.address}</td>
                                                    <td>{data.cart.startTime}-{data.cart.endTime}</td>
                                                    <td>{extractDateFromDateAndTime(data.cart.deliveryDate)}</td>
                                                    <td>
                                                        <div className="statusDropdown">
                                                            <Dropdown>
                                                                <Dropdown.Toggle>
                                                                    {data.cart.status}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {orderStatus
                                                                        .filter((status) => status !== data.status)
                                                                        .map((status) => (
                                                                            <Dropdown.Item onClick={() => orderStatusChange(data.orderId, data.cart.cartId, status)} key={`dropdownStatus${status}`}>{status}</Dropdown.Item>
                                                                        ))
                                                                    }
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </Col>
                                    <Col md={12} className='d-flex justify-content-between'>
                                        <div className='limitBtn'>
                                            <p>showing <b>{orderList && orderList.length}</b> of <b>{totalValue}</b></p>
                                        </div>
                                        <div className='pagination'>
                                            <nav aria-label="Page navigation example" className='bottomPagination'>
                                                <ul className="pagination">
                                                    <li className="page-item">
                                                        <button onClick={() => prevPage()} className={prevButton === true ? "page-link" : "page-link disablePag"} aria-label="Previous">
                                                            <span aria-hidden="true">Prev</span>
                                                        </button>
                                                    </li>
                                                    <li className="page-item"><button className="page-link"><b>{pageValue}</b></button></li>
                                                    <li className="page-item">
                                                        <button onClick={() => nextPage()} className={nextButton === true ? "page-link" : "page-link disablePag"} aria-label="Next">
                                                            <span aria-hidden="true">Next</span>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </Col>
                                </> : <><Loader /></>}
                        </>}


                    </Row>
                </Container>
            </section>
        </AdminLayout>
    )
}

export default OrderList