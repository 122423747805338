import React, { useRef, useState } from 'react';
import { addFood } from '../../../api/api';
import { showNotifications } from '../../../lib/toaster';
import AdminLayout from '../../Common/AdminLayout/AdminLayout';
import { Col, Container, Row, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import "./AddFood.css";
import { ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';


const AddFood: React.FC = () => {
    const navigate = useNavigate();
    const form = useRef(null);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleLayoutStateChange = (clickedInParent: boolean) => {
        setIsCollapsed(!clickedInParent);
    };

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState<any>([]);
    const [foodCategory, setFoodCategory] = useState<string>("");

    const handleSelectChange = (e: any) => {
        const selectedValue = e.target.value;
        if (!selectedOptions.includes(selectedValue)) {
            setSelectedOptions([...selectedOptions, selectedValue]);
        }
    };

    const handleCategoryChange = (e: any) => {
        setFoodCategory(e.target.value);
    };


    const [formData, setFormData] = useState({
        title: '',
        foodType: '',
        description: '',
        priceSmall: 0,
        priceLarge: 0,
        calories: 0,
        carbohydrates: 0,
        wightSmall: 0,
        weightLarge: 0,
        proteins: 0,
        fats: 0,
        vitamines: 0,
        minerals: 0,
        allergens: 0,
        tags: [],
        photo: null as File | null,
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (event.target.files && event.target.files.length > 0) {
            setFormData({
                ...formData,
                photo: event.target.files[0],
            });
        }
        if (file) {
            const imageUrl: any = URL.createObjectURL(file);
            setSelectedImage(imageUrl);
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        const formDataToSend = new FormData(form.current!);
        formDataToSend.append('tags', selectedOptions);
        formDataToSend.append('category', foodCategory);
        formDataToSend.forEach((value, key) => {
            if (value === '') {
                formDataToSend.delete(key);
            }
        });
        addFood(formDataToSend).then((data: any) => {
            if (data.statusCode !== 200 && data.statusCode !== 201) {
                return showNotifications('error', data.message);
            } else {
                return navigate(`/foodList`);
            }
        });
    };


    const tagOptions = [
        { value: "ANTIOXIDANTS", content: "Antioxidants" },
        { value: "N0_CRABS", content: "No crabs" },
        { value: "GLUTEN_FREE", content: "Gluten free" },
        { value: "WITHOUT_SUGAR", content: "Without sugar" },
        { value: "NO_FAT", content: "No fat" },
        { value: "NO_OXALATES", content: "No oxalates" },
        { value: "NO_CHOLESTEROL", content: "No cholesterol" },
        { value: "RICH_IN_COLLAGEN", content: "Rich in collagen" },
        { value: "VEGAN", content: "Vegan" },
        { value: "VEGETARIAN", content: "Vegetarian" },
        { value: "HIGH_PROTEIN", content: "High protein" },
        { value: "VITAMINS", content: "Vitamins" },
        { value: "CASEIN", content: "Casein" },
        { value: "WITH_HIGH_BLOOD_PRESSURE", content: "With high blood pressure" },
        { value: "WITH_GASTRITIS", content: "With gastritis" },
        { value: "IN_GOUT", content: "In gout" },
        { value: "RICH_IN_GOOD_FATS", content: "Rich In Good Fats" }
    ];

    function convertValuesToContent(values: any, options: any) {
        return values.map((value: any) => {
            const option = options.find((opt: any) => opt.value === value);
            return option
                ? {
                    value: value,
                    content: option.content,
                }
                : null;
        });
    }

    const deleteSelectItem = (selectItem: string) => {
        const updatedOptions = selectedOptions.filter((value: any) => value !== selectItem);
        setSelectedOptions(updatedOptions);
    };

    return (
        <AdminLayout onLayoutStateChange={handleLayoutStateChange}>
            <ToastContainer />
            <section className={`main-body ${isCollapsed ? '' : 'bodyCollapsed'}`}>
                <Container>
                    <Row className='d-flex justify-content-center'>
                        <Col md={8}>
                            <form ref={form} onSubmit={handleSubmit}>
                                <Row>
                                    <div className="foodHeading">
                                        <h1>Add Food Information</h1>
                                    </div>
                                    <Col md={12} className='d-flex justify-content-center'>
                                        <div className='createImage'>
                                            {selectedImage && (
                                                <img src={selectedImage} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                                            )}
                                            <label>Food image</label>
                                            <input type="file" name="photo" accept="image/*" onChange={handleFileChange} className='form-control' required />
                                        </div>
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Title</label>
                                        <input type="text" name="title" placeholder="Title" onChange={handleInputChange} className='form-control' required />
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Food type</label>
                                        <select className='form-control' name="foodType" onChange={handleInputChange}>
                                            <option value="" disabled selected>Select Food Type</option>
                                            <option value="VEG">VEG</option>
                                            <option value="NON_VEG">NON VEG</option>
                                        </select>
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Price small</label>
                                        <input step="0.01" type="number" name="priceSmall" placeholder="Price Small" onChange={handleInputChange} className='form-control' required />
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Price large</label>
                                        <input step="0.01" type="number" name="priceLarge" placeholder="Price Large" onChange={handleInputChange} className='form-control' />
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Wight small</label>
                                        <input step="0.01" type="number" name="wightSmall" placeholder="Wight small" onChange={handleInputChange} className='form-control' required />
                                    </Col>

                                    <Col md={6} className='mt-3'>
                                        <label>Weight large</label>
                                        <input step="0.01" type="number" name="weightLarge" placeholder="Weight large" onChange={handleInputChange} className='form-control' required />
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Small Discount</label>
                                        <input step="0.01" type="number" name="discount" placeholder="Discount" onChange={handleInputChange} className='form-control' />
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Big Discount</label>
                                        <input step="0.01" type="number" name="discountBig" placeholder="Big Discount" onChange={handleInputChange} className='form-control' />
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Calories (100grams)</label>
                                        <input type="number" name="calories" placeholder="Calories" onChange={handleInputChange} className='form-control' required />
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Carbohydrates (100grams)</label>
                                        <input type="number" name="carbohydrates" placeholder="Carbohydrates" onChange={handleInputChange} className='form-control' required />
                                    </Col>

                                    <Col md={6} className='mt-3'>
                                        <label>Proteins (100grams)</label>
                                        <input type="number" name="proteins" placeholder="Proteins" onChange={handleInputChange} className='form-control' required />
                                    </Col>

                                    <Col md={6} className='mt-3'>
                                        <label>Fats (100grams)</label>
                                        <input type="number" name="fats" placeholder="Fats" onChange={handleInputChange} className='form-control' required />
                                    </Col>

                                    <Col md={6} className='mt-3'>
                                        <label>Vitamines</label>
                                        <input type="text" name="vitamines" placeholder="Vitamines" onChange={handleInputChange} className='form-control' required />
                                    </Col>

                                    <Col md={6} className='mt-3'>
                                        <label>Minerals</label>
                                        <input type="text" name="minerals" placeholder="Minerals" onChange={handleInputChange} className='form-control' required />
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Allergens</label>
                                        <input type="text" name="allergens" placeholder="Allergens" onChange={handleInputChange} className='form-control' required />
                                    </Col>

                                    <Col md={6} className='mt-3'>
                                        <Form.Group controlId="selectOption">
                                            <label>Food Category:</label>
                                            <Form.Select onChange={handleCategoryChange}>
                                                <option value="">Select...</option>
                                                <option value="MENU">Menu</option>
                                                <option value="SALAD">Salad</option>
                                                <option value="MAIN_DISH">Main Dish</option>
                                                <option value="DESSERT">Dessert</option>
                                                <option value="SOUP">Soup</option>
                                                <option value="SNACK">Snack</option>
                                                <option value="DRINKS">Drinks</option>
                                                <option value="SANDWICHES">Sandwiches</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <Form.Group controlId="selectOption">
                                            <label>Select an tags:</label>
                                            <Form.Select onChange={handleSelectChange}>
                                                <option value="">Select...</option>
                                                <option value="ANTIOXIDANTS">Antioxidants</option>
                                                <option value="N0_CRABS">No crabs</option>
                                                <option value="GLUTEN_FREE">Gluten free</option>
                                                <option value="WITHOUT_SUGAR">Without sugar</option>
                                                <option value="NO_FAT">No fat</option>
                                                <option value="NO_OXALATES">No oxalates</option>
                                                <option value="NO_CHOLESTEROL">No cholesterol</option>
                                                <option value="RICH_IN_COLLAGEN">Rich in collagen</option>
                                                <option value="VEGAN">Vegan</option>
                                                <option value="VEGETARIAN">Vegetarian</option>
                                                <option value="HIGH_PROTEIN">High protein</option>
                                                <option value="VITAMINS">Vitamins</option>
                                                <option value="CASEIN">Casein</option>
                                                <option value="WITH_HIGH_BLOOD_PRESSURE">With high blood pressure</option>
                                                <option value="WITH_GASTRITIS">With gastritis</option>
                                                <option value="IN_GOUT">In gout</option>
                                                <option value="RICH_IN_GOOD_FATS">Rich In Good Fats</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        {selectedOptions.length ? <>
                                            <Col md={12}>
                                                <div className='selectItems mt-3'>{convertValuesToContent(selectedOptions, tagOptions).map((data: any) =>
                                                    <span>{data.content}<FontAwesomeIcon icon={faClose} onClick={() => deleteSelectItem(data.value)} /></span>
                                                )}</div>
                                            </Col>
                                        </> : <></>}

                                    </Col>



                                    <Col md={12} className='mt-3'>
                                        <label>Description</label>
                                        <textarea name="description" placeholder="Description" onChange={handleInputChange} className='form-control' required> </textarea>
                                    </Col>
                                    <Col md={12} className='mt-3 text-center submitBtn'>
                                        <button type="submit" className='btn'>Create Food</button>
                                    </Col>
                                </Row>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </section>
        </AdminLayout>

    );
};

export default AddFood;
