import React, { useState, useEffect } from 'react'
import AdminLayout from '../../Common/AdminLayout/AdminLayout'
import { Col, Container, Modal, Row, Table } from 'react-bootstrap';
import { changeRole, getUserList } from '../../../api/user';
import { UserInterface } from '../../../CommonInterface/CommonInterface';
import Loader from '../../Common/Loader/Loader';
import { showNotifications } from '../../../lib/toaster';
import { ToastContainer } from 'react-toastify';

const UserList = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [allUser, setAllUser] = useState<UserInterface[]>([]);
    const [limit, setLimit] = useState<number>(10);
    const [prevButton, setPrevButton] = useState<boolean>(false);
    const [nextButton, setNextButton] = useState<boolean>(false);
    const [pageValue, setPageValue] = useState<number>();
    const [totalValue, setTotalValue] = useState<number>(0);
    const [userRole, setUserRole] = useState<string>('');
    const [userId, setUserId] = useState<number>(0);
    const [page, setPage] = useState(1);
    const pageCount = Math.ceil(totalValue / 2);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleLayoutStateChange = (clickedInParent: boolean) => {
        setIsCollapsed(!clickedInParent);
    };


    //user list
    useEffect(() => {
        getUserList(page, limit).then((data) => {
            setAllUser(data.data.users);
            setPageValue(data.data.currentPage);
            setTotalValue(data.data.total);
        });
        if (pageCount > 1) {
            setPrevButton(true)
        }
        if (page === 1) {
            setPrevButton(false)
        }
        // next button
        if (pageCount > 1) {
            setNextButton(true)
        }
        if (pageCount === page) {
            setNextButton(false)
        }
    }, [page, pageCount, show]);

    // pagination
    const nextPage = () => {
        setPage(page + 1)
    }

    const prevPage = () => {
        setPage(page - 1)
    }

    const handleRoleShow = (userRole: string, userId: number) => {
        setShow(true);
        setUserRole(userRole);
        setUserId(userId);
    }


    const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserRole(event.target.value);
    };

    const roleChange = () => {
        let updateRole = {
            "userRole": userRole
        }
        changeRole(userId, updateRole).then((data) => {
            if (data.statusCode !== 200) {
                showNotifications('role', data.message);
                setShow(false);
            }
            else {
                showNotifications('success', data.message);
                setShow(false);
            }
        })
    }

    return (
        <AdminLayout onLayoutStateChange={handleLayoutStateChange}>
            <section className={`main-body ${isCollapsed ? '' : 'bodyCollapsed'}`}>
                <ToastContainer />
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="foodHeading">
                                <h1 className='text-center'>Food list</h1>
                            </div>

                        </Col>
                        {allUser && allUser.length ? <>
                            <Col ms={12} className='tableList'>
                                <Table responsive bordered hover className='align-items-center'>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>First name</th>
                                            <th>Last name</th>
                                            <th>Email</th>
                                            <th style={{width: '210px'}}>User role</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allUser && allUser.map((data, i) => <tr className='foodListTable' key={`user` + i}>
                                            <td>{i + 1}</td>
                                            <td>{data.firstName}</td>
                                            <td>{data.lastName}</td>
                                            <td>{data.email}</td>
                                            <td className="userRoleChange">{data.userRole}<span onClick={() => handleRoleShow(data.userRole, data.userId)}>Change role</span></td>
                                        </tr>)}
                                    </tbody>
                                </Table>
                                <Col md={12} className='d-flex justify-content-between'>
                                    <div className='limitBtn'>
                                        <p>Total show: <b>{allUser && allUser.length}</b></p>
                                    </div>
                                    <div className='pagination'>
                                        <nav aria-label="Page navigation example" className='bottomPagination'>
                                            <ul className="pagination">
                                                <li className="page-item">
                                                    <button onClick={() => prevPage()} className={prevButton === true ? "page-link" : "page-link disablePag"} aria-label="Previous">
                                                        <span aria-hidden="true">Prev</span>
                                                    </button>
                                                </li>
                                                <li className="page-item"><button className="page-link"><b>{pageValue}</b></button></li>
                                                <li className="page-item">
                                                    <button onClick={() => nextPage()} className={nextButton === true ? "page-link" : "page-link disablePag"} aria-label="Next">
                                                        <span aria-hidden="true">Next</span>
                                                    </button>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </Col>
                            </Col>

                        </> : <><Loader /></>}
                    </Row>
                </Container>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>User role</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h6 className='text-center'>Change user role</h6>
                        <div className="radio-button-container">
                            <label className="radio-label">
                                <input
                                    type="radio"
                                    value="ADMIN"
                                    checked={userRole === 'ADMIN'}
                                    onChange={handleRoleChange}
                                /><span className="custom-radio"></span>
                                Admin
                            </label>
                            <label className="radio-label">
                                <input
                                    type="radio"
                                    value="CLIENT"
                                    checked={userRole === 'CLIENT'}
                                    onChange={handleRoleChange}
                                /><span className="custom-radio"></span>
                                Client
                            </label>
                            <label className="radio-label">
                                <input
                                    type="radio"
                                    value="COOK"
                                    checked={userRole === 'COOK'}
                                    onChange={handleRoleChange}
                                /><span className="custom-radio"></span>
                                Cook
                            </label>
                            <label className="radio-label">
                                <input
                                    type="radio"
                                    value="DELIVERY_MAN"
                                    checked={userRole === 'DELIVERY_MAN'}
                                    onChange={handleRoleChange}
                                /><span className="custom-radio"></span>
                                Delivery Man
                            </label>
                        </div>
                        <div className='saveRole text-center'>
                            <button className='btn btn-info' onClick={roleChange}>Save</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </section>
        </AdminLayout>
    )
}

export default UserList