import React, { useEffect, useState } from 'react'
import { getSingleMenu } from '../../../api/menu'
import { useParams } from 'react-router'
import AdminLayout from '../../Common/AdminLayout/AdminLayout'
import { Col, Container, Row, Table } from 'react-bootstrap'

const MenuInfo = () => {
    const { id } = useParams();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [menuView, setMenuView] = useState<any>({});
    const handleLayoutStateChange = (clickedInParent: boolean) => {
        setIsCollapsed(!clickedInParent);
    };

    useEffect(() => {

        if (id) {
            getSingleMenu(id).then((data) => {
                if (data.statusCode !== 201) {
                    console.log('no data');
                }
                else {
                    setMenuView(data.data.menu)
                }
            })
        }


    }, [])
    return (
        <>
            <AdminLayout onLayoutStateChange={handleLayoutStateChange}>
                <section className={`main-body ${isCollapsed ? '' : 'bodyCollapsed'}`}>
                <Container>
                    <Row>
                        <Col md={12}>
                        <h1 className='adminHeading text-center'>Menu Information</h1>
                        </Col>
                        <Col md={4}>
                            <h5 className='menuHeading'><b>Menu Name:</b> {menuView && menuView.name}</h5>
                        </Col>
                        <Col md={4}>
                            <h5 className='menuHeading'><b>Charge:</b> {menuView && menuView.charge}</h5>
                        </Col>
                        <Col md={4}>
                            <h5 className='menuHeading'><b>Number of days:</b> {menuView && menuView.daysOfMenu}</h5>
                        </Col>
                        <Col md={12}>
                        <h5>Menu cart item list</h5>
                                <Table responsive bordered hover className='align-items-center'>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Title</th>
                                            <th>Food Type</th>
                                            <th>Food Category</th>
                                            <th>Price</th>
                                            <th>Quantity</th>
                                            <th>Day count</th>
                                            <th>Delivery Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {menuView && menuView.menuCartItem && menuView.menuCartItem.map((data:any, i:number) => <tr className='foodListTable' key={`menuList` + i}>
                                            <td>{i + 1}</td>
                                            <td>{data.food.title}</td>
                                            <td>{data.food.foodType}</td>
                                            <td>{data.food.category}</td>
                                            <td>{data.price}</td>
                                            <td>{data.quantity}</td>
                                            <td>{data.dayCount}</td>
                                            <td>{data.deliveryTime}</td>
                                        </tr>)}
                                    </tbody>
                                </Table>
                        </Col>
                    </Row>
                </Container>
                </section>
            </AdminLayout>
        </>
    )
}

export default MenuInfo