import React, { useState, useEffect } from 'react';
import "./OrderSidebar.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { convertTimeArrayFormat, decrementCount, deleteFoodItem, deliveryTimeAdd, incrementCount, splitTimeRange } from '../../../lib/commonFunction';
import { Link } from 'react-router-dom';
import { getDeliveryTime } from '../../../api/deliverytime';
import { DeliveryInterface } from '../../../CommonInterface/CommonInterface';
import { ToastContainer } from 'react-toastify';
import { showNotifications } from '../../../lib/toaster';
interface ChildProps {
  parentState?: number;
  orderList: any;
  setOrderList: any;
}
const OrderSidebar = ({ parentState, orderList, setOrderList }: ChildProps) => {
  // const [orderList, setOrderList] = useState<any>([]);
  const [count, setCount] = useState(0);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [lunchList, setLunchList] = useState<DeliveryInterface[]>([]);
  const [breakfastList, setBreakfastList] = useState<DeliveryInterface[]>([]);


  // Load data from local storage when the component mounts
  useEffect(() => {
    const storedOrderList = localStorage.getItem('orderList');
    if (storedOrderList) {
      setOrderList(JSON.parse(storedOrderList));
    }
    getDeliveryTime("LUNCH").then((data) => {
      if (data.statusCode === 200) {
        let arrayTime = convertTimeArrayFormat(data.data.deliveryTimes);
        setLunchList(arrayTime)
      }
    });
    getDeliveryTime("BREAKFAST").then((data) => {
      if (data.statusCode === 200) {
        let arrayTime = convertTimeArrayFormat(data.data.deliveryTimes);
        setBreakfastList(arrayTime)
      }
    });
  }, [count, parentState]);

  function convertArrayToObject(arr: any) {
    const result: any = {};
    const datesSet: Set<string> = new Set(); // To track unique delivery dates
    arr.forEach((item: any) => {
      const { deliveryDate, deliveryTime }: any = item;
      if (!datesSet.has(deliveryDate)) {
        // Initialize the delivery date object only if it's not already initialized
        result[deliveryDate] = {};
        datesSet.add(deliveryDate);
      }
      if (!result[deliveryDate][deliveryTime]) {
        result[deliveryDate][deliveryTime] = [];
      }
      result[deliveryDate][deliveryTime].push(item);
    });
    return result;
  }
  const dateWiseOrder = convertArrayToObject(orderList);

  const deleteItem = (foodId: number, deliveryDate: string, deliveryTime: string) => {
    deleteFoodItem(foodId, deliveryDate, deliveryTime);
    setCount(count + 1);
  }
  const increaseItem = (foodId: number, deliveryDate: string, deliveryTime: string) => {
    incrementCount(foodId, deliveryDate, deliveryTime);
    setCount(count + 1);
  }
  const decreaseItem = (foodId: number, deliveryDate: string, deliveryTime: string) => {
    decrementCount(foodId, deliveryDate, deliveryTime);
    setCount(count + 1);
  }


  const handleSelectChange = (event: any, foodId: number, deliveryDate: string, deliveryTime: string) => {
    setSelectedValue(event.target.value);
    const { startTime, endTime } = splitTimeRange(event.target.value);
    deliveryTimeAdd(foodId, deliveryDate, deliveryTime, startTime, endTime)
  };

  function getDeliveryTimeInfo(deliveryDate: string, deliveryTime: string) {
    // Filter orders based on deliveryDate and deliveryTime
    const filteredOrders = orderList.filter((order: any) => order.deliveryDate === deliveryDate && order.deliveryTime === deliveryTime);

    // Initialize startTime and endTime variables
    let startTime: any = "";
    let endTime: any = "";

    // Iterate through filtered orders to find startTime and endTime
    filteredOrders.forEach((order: any) => {
      if (order.startTime && order.endTime) {
        if (!startTime || order.startTime < startTime) {
          startTime = order.startTime;
        }
        if (!endTime || order.endTime > endTime) {
          endTime = order.endTime;
        }
      }
    });

    return `${startTime}${endTime}`;
  }

  return (
    <>

      <ToastContainer />
      {orderList && orderList.length > 0 ? <>
        <div>
          {Object.keys(dateWiseOrder).map((date) => {
            return (
              <div key={date} className="cart-element-group">
                <h5>{date}</h5>
                {/* Iterate over the keys (delivery times) of the nested object */}
                {Object.keys(dateWiseOrder[date]).map((deliveryTime) => {
                  return (
                    <>
                      <div key={deliveryTime} className="input-group my-1">
                        
                        <label className="input-group-text" htmlFor="inputGroupSelect01">{deliveryTime}</label>

                        <select className="form-select" id="inputGroupSelect01" onChange={(e) => handleSelectChange(e, 2, date, deliveryTime)}>
                          <option defaultValue="">{getDeliveryTimeInfo(date, deliveryTime) ? getDeliveryTimeInfo(date, deliveryTime) : "Choose Time"}</option>
                          {deliveryTime === "BREAKFAST" ? (
                            breakfastList.map((data, i) => (
                              <option key={`breakfast-${i}`} value={`${data.startTime}-${data.endTime}`}>
                                {data.startTime}-{data.endTime}
                              </option>
                            ))
                          ) : (
                            lunchList.map((data, i) => (
                              <option key={`lunch-${i}`} value={`${data.startTime}-${data.endTime}`}>
                                {data.startTime}-{data.endTime}
                              </option>
                            ))
                          )}
                        </select>
                      </div>
                      {dateWiseOrder[date][deliveryTime].map((orderItem: any, index: number) => {
                        return (
                          <div key={index} className="cart-element">
                            <div className="cart_heading d-flex justify-content-between align-items-center">
                              <p>{orderItem.foodTitle}</p>
                              <button type="button" onClick={() => deleteItem(orderItem.foodId, orderItem.deliveryDate, orderItem.deliveryTime)} className="btn btn-danger btn-sm float-end"><FontAwesomeIcon icon={faTrashCan} /></button>
                            </div>
                            <div className="cart_count d-flex justify-content-between align-items-center">
                              <span className='tableAddCart'>
                                <span className='cartBox'>
                                  <button className='btn' onClick={() => increaseItem(orderItem.foodId, orderItem.deliveryDate, orderItem.deliveryTime)}><FontAwesomeIcon icon={faPlus} /></button>
                                  <p>{orderItem.quantity}</p>
                                  <button className='btn' onClick={() => decreaseItem(orderItem.foodId, orderItem.deliveryDate, orderItem.deliveryTime)}><FontAwesomeIcon icon={faMinus} /></button>
                                </span>
                              </span>
                              <p>{(orderItem.quantity * orderItem.price).toFixed(2)}лв</p>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="viewOrder">
          <Link to="/cart">View Order</Link>
        </div>
      </> : <><img src="https://cdni.iconscout.com/illustration/premium/thumb/empty-cart-4344468-3613896.png" alt="no order" width="100%" /></>}
    </>
  )
}

export default OrderSidebar