import React, { useState } from 'react'
import { Modal, Table } from 'react-bootstrap';

interface ViewProps {
    menuItemShow: boolean;
    handleMenuClose: any;
}

const ViewMenu = ({ menuItemShow, handleMenuClose }: ViewProps) => {
    const menu = localStorage.getItem("menu") || '{}';
    const createMenu = JSON.parse(menu);
    const [currentPage, setCurrentPage] = useState(1);

    return (
        <>
            <Modal show={menuItemShow} onHide={handleMenuClose} size="lg">
                <div className="viewMenuProfile">
                    <h1>Menu Item</h1>

                    <div className='d-flex justify-content-between'>
                        <p><b>Name:</b> {createMenu.name}</p>
                        <p><b>Charge:</b> {createMenu.charge}</p>
                        <p><b>Days of Menu:</b> {createMenu.daysOfMenu}</p>
                    </div>

                    {createMenu && Array.from({ length: createMenu.daysOfMenu }, (_, index) =>
                        <button
                            key={index}
                            onClick={() => setCurrentPage(index + 1)}
                            className={currentPage === index + 1 ? 'daysBtn activeDays' : 'daysBtn'}
                        >
                            Day {index + 1}
                        </button>
                    )}
                    <div className="itemList mt-4">
                        <Table responsive bordered hover className='align-items-center'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Title</th>
                                    <th>Price</th>
                                    <th>Weight</th>
                                    <th>Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {createMenu.menuCart && createMenu.menuCart.filter((item: any) => currentPage === null || item.dayCount === currentPage)
                                    .map((item: any, index: any) => <tr>
                                        <td>{index+1}</td>
                                        <td>title</td>
                                        <td>{item.price}</td>
                                        <td>{item.weight}</td>
                                        <td>{item.quantity}</td>
                                    </tr>)}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ViewMenu