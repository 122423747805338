import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row, Form } from 'react-bootstrap';
import { FoodAllInterface } from '../../../CommonInterface/CommonInterface';
import AdminLayout from '../../Common/AdminLayout/AdminLayout';
import { useParams } from 'react-router';
import { getSingleFood, updateFood } from '../../../api/api';
import { useNavigate } from "react-router-dom";
import { Buffer } from 'buffer';
import { showNotifications } from '../../../lib/toaster';
import { ToastContainer } from 'react-toastify';
import { convertValuesToContent } from '../../../lib/commonFunction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../Common/Loader/Loader';

const EditFood = (() => {
    const { id }: any = useParams();
    const navigate = useNavigate();
    const form = useRef(null);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [foodInfo, setFoodInfo] = useState<FoodAllInterface | undefined>();
    const handleLayoutStateChange = (clickedInParent: boolean) => {
        setIsCollapsed(!clickedInParent);
    };
    const tagOptions = [
        { value: "ANTIOXIDANTS", content: "Antioxidants" },
        { value: "N0_CRABS", content: "No crabs" },
        { value: "GLUTEN_FREE", content: "Gluten free" },
        { value: "WITHOUT_SUGAR", content: "Without sugar" },
        { value: "NO_FAT", content: "No fat" },
        { value: "NO_OXALATES", content: "No oxalates" },
        { value: "NO_CHOLESTEROL", content: "No cholesterol" },
        { value: "RICH_IN_COLLAGEN", content: "Rich in collagen" },
        { value: "VEGAN", content: "Vegan" },
        { value: "VEGETARIAN", content: "Vegetarian" },
        { value: "HIGH_PROTEIN", content: "High protein" },
        { value: "VITAMINS", content: "Vitamins" },
        { value: "CASEIN", content: "Casein" },
        { value: "WITH_HIGH_BLOOD_PRESSURE", content: "With high blood pressure" },
        { value: "WITH_GASTRITIS", content: "With gastritis" },
        { value: "IN_GOUT", content: "In gout" },
        { value: "RICH_IN_GOOD_FATS", content: "Rich In Good Fats" }
    ];

    const [calories, setCalories] = useState("");
    const [carbohydrates, setCarbohydrates] = useState("");
    const [foodType, setFoodType] = useState<string>('');
    const [foodCategory, setFoodCategory] = useState<string>('');
    const [photo, setPhoto] = useState<any>(null);
    const [priceLarge, setPriceLarge] = useState("");
    const [priceSmall, setPriceSmall] = useState("");
    const [title, setTitle] = useState<string>('');
    const [wightSmall, setWightSmall] = useState("");
    const [weightLarge, setWightLarge] = useState("");
    const [proteins, setProteins] = useState("");
    const [fats, setFats] = useState("");
    const [vitamines, setVitamines] = useState("");
    const [minerals, setMinerals] = useState("");
    const [allergens, setAllergens] = useState("");
    const [discount, setDiscount] = useState("");
    const [bigDiscount, setBigDiscount] = useState("");
    const [tags, setTags] = useState([]);


    const [description, setDescription] = useState<string>('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [preview, setPreview] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<any>([]);
    // load data
    useEffect(() => {
        getSingleFood(id).then((data) => {
            setCalories(data.data.food.calories);
            setCarbohydrates(data.data.food.calories);
            setFoodType(data.data.food.foodType);
            setFoodCategory(data.data.food.category);
            setPriceLarge(data.data.food.priceLarge);
            setPriceSmall(data.data.food.priceSmall);
            setTitle(data.data.food.title);
            setPhoto(data.data.food.photo);
            setDescription(data.data.food.description);
            setDiscount(data.data.food.discount);
            setBigDiscount(data.data.food.discountBig);
            setWightSmall(data.data.food.wightSmall);
            setWightLarge(data.data.food.weightLarge);
            setProteins(data.data.food.proteins);
            setFats(data.data.food.fats);
            setVitamines(data.data.food.vitamines);
            setMinerals(data.data.food.minerals);
            setAllergens(data.data.food.allergens);
            setTags(data.data.food.tags);
            const restrictionsArray = data.data.food.tags.split(',');
            setSelectedOptions(restrictionsArray);
        })
    }, []);

    const handleFileChange = (event: any) => {
        const file = event.target.files && event.target.files[0];
        if (event.target.files && event.target.files.length > 0) {
            setSelectedImage(event.target.files[0]);
        }
        if (file) {
            const imageUrl: any = URL.createObjectURL(file);
            setImagePreview(imageUrl);
            setPreview(true);
        }
    };

    // update food
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        const formDataToSend = new FormData(form.current!);
        formDataToSend.append('tags', selectedOptions);
        updateFood(formDataToSend, id).then((data: any) => {
            return navigate(`/foodList`);
        });
    };

    const handleSelectChange = (e: any) => {
        const selectedValue = e.target.value;
        if (!selectedOptions.includes(selectedValue)) {
            setSelectedOptions([...selectedOptions, selectedValue]);
        }
    };

    const deleteSelectItem = (selectItem: string) => {
        const updatedOptions = selectedOptions.filter((value: any) => value !== selectItem);
        setSelectedOptions(updatedOptions);
    };
    return (
        <AdminLayout onLayoutStateChange={handleLayoutStateChange}>
            <ToastContainer />
            <section className={`main-body ${isCollapsed ? '' : 'bodyCollapsed'}`}>
                <Container>
                    {title && title ? <Row className='d-flex justify-content-center'>
                        <Col md={8}>
                            <form ref={form} onSubmit={handleSubmit}>
                                <Row>
                                    <div className="foodHeading">
                                        <h1>Update Food Information</h1>
                                    </div>
                                    <Col md={12} className='d-flex justify-content-center'>
                                        <div className='createImage'>
                                            {preview ? <>{imagePreview && (
                                                <img src={imagePreview} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                                            )}</> : <>
                                                {photo && (
                                                    <img src={`data:image/jpeg;base64,${Buffer.from(photo).toString('base64')}`} alt={title} width="200px" height="200px" />
                                                )}
                                            </>}
                                            <label>Food image</label>
                                            <input type="file" name="photo" accept="image/*" onChange={handleFileChange} className='form-control' />
                                        </div>
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Title</label>
                                        <input type="text" name="title" placeholder="Title" onChange={(e) => setTitle(e.target.value)} value={title} className='form-control' />
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Food type</label>
                                        <select className='form-control' name="foodType" onChange={(e) => setFoodType(e.target.value)}>
                                            <option value="" disabled selected>{foodType}</option>
                                            <option value="VEG">VEG</option>
                                            <option value="NON_VEG">NON VEG</option>
                                        </select>
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Price small</label>
                                        <input step="0.01" type="number" name="priceSmall" placeholder="Price Small" onChange={(e) => setPriceSmall(e.target.value)} value={priceSmall} className='form-control' />
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Price large</label>
                                        <input step="0.01" type="number" name="priceLarge" placeholder="Price Large" onChange={(e) => setPriceLarge(e.target.value)} value={priceLarge} className='form-control' />
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Wight small</label>
                                        <input step="0.01" type="number" name="wightSmall" placeholder="Carbohydrates" onChange={(e) => setWightSmall(e.target.value)} value={wightSmall} className='form-control' />
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Weight large</label>
                                        <input step="0.01" type="number" name="weightLarge" placeholder="Carbohydrates" onChange={(e) => setWightLarge(e.target.value)} value={weightLarge} className='form-control' />
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Small Discount</label>
                                        <input step="0.01" type="number" name="discount" placeholder="Discount" onChange={(e) => setDiscount(e.target.value)} value={discount} className='form-control' />
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Big Discount</label>
                                        <input step="0.01" type="number" name="discountBig" placeholder="Big Discount" onChange={(e) => setBigDiscount(e.target.value)} value={bigDiscount} className='form-control' />
                                    </Col>

                                    <Col md={6} className='mt-3'>
                                        <label>Calories (100grams)</label>
                                        <input type="number" name="calories" placeholder="Calories" onChange={(e) => setCalories(e.target.value)} value={calories} className='form-control' />
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Carbohydrates (100grams)</label>
                                        <input type="number" name="carbohydrates" placeholder="Carbohydrates" onChange={(e) => setCarbohydrates(e.target.value)} value={carbohydrates} className='form-control' />
                                    </Col>

                                    <Col md={6} className='mt-3'>
                                        <label>Proteins (100grams)</label>
                                        <input type="number" name="proteins" placeholder="proteins" onChange={(e) => setProteins(e.target.value)} value={proteins} className='form-control' />
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Fats (100grams)</label>
                                        <input type="number" name="fats" placeholder="proteins" onChange={(e) => setFats(e.target.value)} value={fats} className='form-control' />
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Vitamines</label>
                                        <input type="text" name="vitamines" placeholder="proteins" onChange={(e) => setVitamines(e.target.value)} value={vitamines} className='form-control' />
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Minerals</label>
                                        <input type="text" name="minerals" placeholder="proteins" onChange={(e) => setMinerals(e.target.value)} value={minerals} className='form-control' />
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Allergens</label>
                                        <input type="text" name="allergens" placeholder="allergens" onChange={(e) => setAllergens(e.target.value)} value={allergens} className='form-control' />
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <label>Food Category</label>
                                        <select className='form-control' name="category" onChange={(e) => setFoodCategory(e.target.value)}>
                                            <option value="" disabled selected>{foodCategory}</option>
                                            <option value="MENU">Menu</option>
                                            <option value="SALAD">Salad</option>
                                            <option value="MAIN_DISH">Main dish</option>
                                            <option value="DESSERT">Dessert</option>
                                            <option value="SOUP">Soup</option>
                                            <option value="SNACK">Snack</option>
                                        </select>
                                    </Col>
                                    <Col md={6} className='mt-3'>
                                        <Form.Group controlId="selectOption">
                                            <label>Select an tags:</label>
                                            <Form.Select onChange={handleSelectChange}>
                                                <option value="">Select...</option>
                                                <option value="ANTIOXIDANTS">Antioxidants</option>
                                                <option value="N0_CRABS">No crabs</option>
                                                <option value="GLUTEN_FREE">Gluten free</option>
                                                <option value="WITHOUT_SUGAR">Without sugar</option>
                                                <option value="NO_FAT">No fat</option>
                                                <option value="NO_OXALATES">No oxalates</option>
                                                <option value="NO_CHOLESTEROL">No cholesterol</option>
                                                <option value="RICH_IN_COLLAGEN">Rich in collagen</option>
                                                <option value="VEGAN">Vegan</option>
                                                <option value="VEGETARIAN">Vegetarian</option>
                                                <option value="HIGH_PROTEIN">High protein</option>
                                                <option value="VITAMINS">Vitamins</option>
                                                <option value="CASEIN">Casein</option>
                                                <option value="WITH_HIGH_BLOOD_PRESSURE">With high blood pressure</option>
                                                <option value="WITH_GASTRITIS">With gastritis</option>
                                                <option value="IN_GOUT">In gout</option>
                                                <option value="RICH_IN_GOOD_FATS">Rich In Good Fats</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>

                                    <Col md={12}>
                                        {selectedOptions.length ? <>
                                            <Col md={12}>
                                                <div className='selectItems mt-3'>{convertValuesToContent(selectedOptions, tagOptions).map((data: any) =>
                                                    <span>{data.content}<FontAwesomeIcon icon={faClose} onClick={() => deleteSelectItem(data.value)} /></span>
                                                )}</div>
                                            </Col>
                                        </> : <></>}

                                    </Col>
                                    <Col md={12} className='mt-3'>
                                        <label>Description</label>
                                        <textarea name="description" placeholder="Description" onChange={(e) => setDescription(e.target.value)} value={description} className='form-control' required> </textarea>
                                    </Col>
                                    <Col md={12} className='mt-3 text-center submitBtn'>
                                        <button type="submit" className='btn'>Update</button>
                                    </Col>
                                </Row>
                            </form>
                        </Col>
                    </Row> : <Loader />}

                </Container>
            </section>
        </AdminLayout>
    )
})

export default EditFood