import React from "react";
import { Container, Modal, Row, Col, Table } from "react-bootstrap";

interface DaysProps {
  menuDaysShow: boolean;
  handleDaysClose: any;
  menuCart: (index: number, deliveryTime: string) => void;
  currentDays: any;
  handleSelectChange: any;
}

const ChooseDays = ({
  menuDaysShow,
  handleDaysClose,
  menuCart,
  currentDays,
  handleSelectChange,
}: DaysProps) => {
  const menu = localStorage.getItem("menu") || "{}";
  const createMenu = JSON.parse(menu);
  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  function checkIfItemExists(dayCount: number, deliveryTime: string) {
    const menuObj = JSON.parse(menu);
    if (menuObj && menuObj.menuCart && Array.isArray(menuObj.menuCart)) {
      for (const item of menuObj.menuCart) {
        if (item.dayCount === dayCount && item.deliveryTime === deliveryTime) {
          return true;
        }
      }
    }
    return false;
  }

  function getTitle(dayCount: number, deliveryTime: string) {
    const menuObj = JSON.parse(menu);
    if (menuObj && menuObj.menuCart && Array.isArray(menuObj.menuCart)) {
      const matchingItems = menuObj.menuCart.filter((item: any) => item.dayCount === dayCount && item.deliveryTime === deliveryTime);
      if (matchingItems.length > 0) {
        return matchingItems.map((item: any) => item.title).join(", ");
      }
    }
    return [];
  }

  return (
    <>
      <Modal show={menuDaysShow} onHide={handleDaysClose} size="xl">
        <div className="chooseDays">
          <Container>
            <Row>
              <Col md={12} className="mb-2">
                <h2>Choose the days</h2>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      {days.map((day) => (
                        <th key={day}>{day}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {[...Array(Math.ceil(createMenu.daysOfMenu / 7))].map((_, rowIndex) => (
                      <tr key={rowIndex}>
                        {[...Array(7)].map((_, dayIndex) => {
                          const dayNumber = rowIndex * 7 + dayIndex + 1;
                          return (
                            <td
                              key={dayIndex}
                              className="daysBtn"
                            >
                              {dayNumber <= createMenu.daysOfMenu && (
                                <>
                                  Day: {dayNumber}
                                  <div className="menuFood">
                                    <button className={checkIfItemExists(dayNumber, "BREAKFAST") ? "daysBtn activeBreakfast" : "activeBreakfast"} onClick={() => menuCart(dayNumber-1, "BREAKFAST")}>Breakfast <span className="tooltiptext">{getTitle(dayNumber, 'BREAKFAST')}</span></button>
                                    <button className={checkIfItemExists(dayNumber, "LUNCH") ? "daysBtn activeBreakfast" : "activeBreakfast"} onClick={() => menuCart(dayNumber-1, "LUNCH")}>Lunch <span className="tooltiptext">{getTitle(dayNumber, 'BREAKFAST')}</span></button>
                                  </div>
                                </>
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </div>
        <Modal.Footer>
          <button className="btn btn-info" onClick={handleDaysClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChooseDays;
