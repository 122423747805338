import React, { useState, useEffect } from 'react';
import AdminLayout from '../../Common/AdminLayout/AdminLayout';
import { Col, Container, Row, Table, Modal } from 'react-bootstrap';
import { addDiscount, deleteDiscountItem, getDiscount, updateDiscountItem } from '../../../api/discount';
import "./Discount.css";
import { showNotifications } from '../../../lib/toaster';
import { ToastContainer } from 'react-toastify';
import { discountInterface } from '../../../CommonInterface/CommonInterface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import WarningModal from '../../Common/WarningModal/WarningModal';
import { managementCreate, managementList, managementUpdate } from '../../../api/management';

const Discount = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [weekly, setWeekly] = useState("");
    const [amount, setAmount] = useState("");
    const [count, setCount] = useState<number>(0);
    const [deliveryAmount, setDeliveryAmount] = useState<number>(0);
    const [deliveryCharge, setDeliveryCharge] = useState<number>(0);
    const [updateAmount, setUpdateAmount] = useState<number>();
    const [updateCharge, setUpdateCharge] = useState<number>(0);
    const [discountValue, setDiscountValue] = useState<number>(0);
    const [amountDelivery, setAmountDelivery] = useState<number>(0);
    const [chargeDelivery, setChargeDelivery] = useState<number>(0);
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const handleClose = () => setShowEdit(false);
    const [editAmount, setEditAmount] = useState<string>("");
    const [discountList, setDiscountList] = useState<discountInterface[]>();
    const [deliveryUpdate, setDeliveryUpdate] = useState(false);

    const handleLayoutStateChange = (clickedInParent: boolean) => {
        setIsCollapsed(!clickedInParent);
    };
    const handleCloseModal = () => {
        setShow(false);
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setWeekly(event.target.value);
    };

    useEffect(() => {
        getDiscount().then((data) => {
            setDiscountList(data.data)
        })
        managementList().then((data) => {
            if (data.data.adminManagement) {
                setAmountDelivery(data.data.adminManagement.freeDeliveryThreshold);
                setChargeDelivery(data.data.adminManagement.deliveryCharge);
            }
        })
    }, [count, deliveryUpdate])

    // discount add
    const discountAdd = () => {
        let discount = {
            "day": parseInt(weekly),
            "discount": parseInt(amount),
        }
        addDiscount(discount).then((data) => {
            if (data.statusCode !== 200 && data.statusCode !== 201) {
                return showNotifications('error', data.message);
            } else {
                setCount(count + 1);
                setWeekly("");
                setAmount("");
                return showNotifications('success', data.message);
            }
        })
    };
    // delete discount

    const deleteFood = (discountId: number) => {
        setShow(true);
        setDiscountValue(discountId);
    }
    const handleDeleteFood = () => {
        setShow(false);
        deleteDiscountItem(discountValue).then((data) => {
            setCount(count + 1)
            return showNotifications('success', data.message);
        })
    };

    // edit discount
    const editDiscount = (discountId: number) => {
        setShowEdit(true);
        setDiscountValue(discountId);
    }
    // edit discount

    const updateDiscount = (discountId: number) => {
        let discount = {
            "discount": editAmount
        }
        updateDiscountItem(discountId, discount).then((data) => {
            setShowEdit(false);
            setCount(count + 1)
            return showNotifications('success', data.message);
        })
    }

    // delivery add
    const deliveryAdd = () => {
        let deliveryInfo = {
            "freeDeliveryThreshold": deliveryAmount,
            "deliveryCharge": deliveryCharge,
        }
        managementCreate(deliveryInfo).then((data) => {
            if (data.statusCode !== 201) {
                showNotifications('error', data.message);
            }
            else {
                setCount(count + 1)
                showNotifications('success', data.message);
            }
        })
    }

    // delivery add
    const deliveryEdit = () => {
        let deliveryInfo = {
            "freeDeliveryThreshold": updateAmount,
            "deliveryCharge": updateCharge,
        }

        managementUpdate(deliveryInfo).then((data) => {
            if (data.statusCode !== 200) {
                showNotifications('error', data.message);
            }
            else {
                showNotifications('success', data.message);
                setDeliveryUpdate(false);
            }
        })
    }

    const editManagement = (amountDelivery: number, chargeDelivery: number) => {
        setDeliveryUpdate(true);
        setUpdateAmount(amountDelivery);
        setUpdateCharge(chargeDelivery);
    }

    return (
        <AdminLayout onLayoutStateChange={handleLayoutStateChange}>
            <ToastContainer />
            <section className={`main-body ${isCollapsed ? '' : 'bodyCollapsed'}`}>
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="discountBox">
                                <div className="foodHeading">
                                    <h1>Discount</h1>
                                </div>

                                <div className="discount">
                                    <div className='discountOption'>
                                        <label>Days</label>
                                        <select value={weekly} className='form-control' name="weekly" onChange={handleInputChange}>
                                            <option value="" disabled selected>Weekly Option</option>
                                            <option value="7">1 week</option>
                                            <option value="14">2 week</option>
                                            <option value="21">3 week</option>
                                            <option value="28">4 week</option>
                                            <option value="35">5 week</option>
                                            <option value="42">6 week</option>
                                            <option value="49">7 week</option>
                                            <option value="56">8 week</option>
                                        </select>
                                    </div>
                                    <div className='discountOption'>
                                        <label>Discount Percentage</label>
                                        <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} className='form-control' placeholder='Percentage' />
                                    </div>
                                    <div className='discountOption'>
                                        <button onClick={discountAdd}>Submit</button>
                                    </div>
                                </div>

                                {discountList && discountList.length > 0 ? <>
                                    <div className="discountList">
                                        <h1>Discount list</h1>
                                        <Table responsive striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Week</th>
                                                    <th>Percentage</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {discountList && discountList.map((data, i) =>
                                                    <tr key={`discount` + i}>
                                                        <td>{i + 1}</td>
                                                        <td>{data.day / 7}</td>
                                                        <td>{data.discount}%</td>
                                                        <td>
                                                            <button className='text-primary mr-2' onClick={() => editDiscount(data.discountId)}><FontAwesomeIcon icon={faPenToSquare} /></button>
                                                            <button className='text-danger' onClick={() => deleteFood(data.discountId)}><FontAwesomeIcon icon={faTrash} /></button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </> : <></>}
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className="deliveryBox">
                                <div className="foodHeading">
                                    <h1>Delivery Charge</h1>
                                </div>
                                {amountDelivery ? "" :
                                    <div className="deliveryOption">
                                        <div className='mr-2'>
                                            <label>Order Amount</label>
                                            <input step="0.01" type="number" value={deliveryAmount} onChange={(e) => setDeliveryAmount(parseFloat(e.target.value))} placeholder='Order amount' className='form-control' />
                                        </div>
                                        <div className='mr-2'>
                                            <label>Delivery Amount</label>
                                            <input type="number" value={deliveryCharge} onChange={(e) => setDeliveryCharge(parseInt(e.target.value))} placeholder='Delivery amount' className='form-control' />
                                        </div>
                                        <div className='discountOption'>
                                            <button className='btn btn-info' onClick={deliveryAdd}>Save</button>
                                        </div>
                                    </div>}

                                {deliveryUpdate ? <div className="deliveryOption">
                                    <div className='mr-2'>
                                        <label>Order Amount</label>
                                        <input step="0.01" type="number" value={updateAmount} onChange={(e) => setUpdateAmount(parseFloat(e.target.value))} placeholder='Order amount' className='form-control' />
                                    </div>
                                    <div className='mr-2'>
                                        <label>Delivery Charge</label>
                                        <input type="number" value={updateCharge} onChange={(e) => setUpdateCharge(parseInt(e.target.value))} placeholder='Delivery amount' className='form-control' />
                                    </div>
                                    <div className='discountOption'>
                                        <button className='btn btn-info' onClick={deliveryEdit}>Update</button>
                                    </div>
                                </div> : ""}
                                {amountDelivery ? <div className="deliveryList">
                                    <h1>Delivery Name</h1>
                                    <Table responsive striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Order Amount</th>
                                                <th>Delivery Charge</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{amountDelivery}</td>
                                                <td>{chargeDelivery}</td>
                                                <td><button className='text-primary' onClick={() => editManagement(amountDelivery, chargeDelivery)}><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div> : ""}

                            </div>
                        </Col>



                    </Row>
                </Container>
                <WarningModal show={show} onClose={handleCloseModal} deleteFunction={handleDeleteFood} />
                {/* edit modal */}
                <Modal show={showEdit} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit discount</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="discount">
                            <div className='discountOption'>
                                <label>Amount</label>
                                <input type="number" onChange={(e) => setEditAmount(e.target.value)} className='form-control' placeholder='Discount amount' />
                            </div>
                            <div className='discountOption'>
                                <button onClick={() => updateDiscount(discountValue)}>Update</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </section>
        </AdminLayout>
    )
}

export default Discount