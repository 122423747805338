import React, { useEffect, useState } from 'react';
import AdminLayout from '../../Common/AdminLayout/AdminLayout';
import { useNavigate } from "react-router-dom";
import { Col, Container, Row, Table } from 'react-bootstrap';
import { deleteFoodItem, updateFoodStatus } from '../../../api/api';
import "./FoodList.css";
import { FoodInterface } from '../../../CommonInterface/CommonInterface';
import { showNotifications } from '../../../lib/toaster';
import { ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../Common/Loader/Loader';
import WarningModal from '../../Common/WarningModal/WarningModal';
import { getFoodList } from '../../../api/foods';
import FoodImage from '../../Common/FoodImage/FoodImage';

const FoodList = () => {

    const navigate = useNavigate();
    const [itemList, setItemList] = useState<FoodInterface[] | undefined>([]);
    const [deleteItem, setDeleteItem] = useState("");
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [show, setShow] = useState(false);
    const [foodValue, setFoodValue] = useState<number>(0);
    const [listLoad, setListLoad] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const [prevButton, setPrevButton] = useState<boolean>(false);
    const [nextButton, setNextButton] = useState<boolean>(false);


    const [noFoods, setNoFoods] = useState<boolean>(false);
    const [pageValue, setPageValue] = useState<number>();
    const [totalValue, setTotalValue] = useState<number>(0);
    const [page, setPage] = useState(1);
    const pageCount = Math.ceil(totalValue / limit);


    const handleCloseModal = () => {
        setShow(false);
    };
    const handleLayoutStateChange = (clickedInParent: boolean) => {
        setIsCollapsed(!clickedInParent);
    };
    // load all data
    useEffect(() => {
        let body = { "tags": [], "featured": null }
        getFoodList(page, limit, body).then((data) => {
            if (data.data.foods.length === 0) {
                setNoFoods(true);
            }
            else {
                setNoFoods(false);
                setItemList(data.data.foods);
                setPageValue(data.data.currentPage);
                setTotalValue(data.data.total);
            }
        })
        if (pageCount > 1) {
            setPrevButton(true)
        }
        if (page === 1) {
            setPrevButton(false)
        }
        // next button
        if (pageCount > 1) {
            setNextButton(true)
        }
        if (pageCount === page) {
            setNextButton(false)
        }
    }, [deleteItem, page, pageCount, listLoad])
    // view food
    const viewFood = (foodId: number) => {
        return navigate(`/foodDetails/${foodId}`)
    }
    // delete food
    const deleteFood = (foodId: number) => {
        setShow(true);
        setFoodValue(foodId);
    }
    const handleDeleteFood = () => {
        setShow(false);
        deleteFoodItem(foodValue).then((data) => {
            setDeleteItem(data.message)
            return showNotifications('success', data.message);
        })
    };
    // edit food
    const editFood = (foodId: number) => {
        return navigate(`/editFood/${foodId}`)
    }
    // pagination

    const nextPage = () => {
        setPage(page + 1)
    }

    const prevPage = () => {
        setPage(page - 1)
    }
    // update food status
    const updateStatus = async (id: number, status: boolean) => {

        let foodActive = {
            "isActive": status
        }

        updateFoodStatus(foodActive, id).then((data: any) => {
            showNotifications('success', "Food status update successfully");
            setListLoad(listLoad + 1)
        });

    };


    return (
        <AdminLayout onLayoutStateChange={handleLayoutStateChange}>
            <section className={`main-body ${isCollapsed ? '' : 'bodyCollapsed'}`}>
                <Container>
                    <ToastContainer />
                    <Row>
                        {noFoods && noFoods === true ? <>
                            <Col md={12}>
                                <div className="foodHeading">
                                    <h1 className='text-center text-danger'>Food list empty</h1>
                                </div>
                            </Col>
                        </> : <>
                            {itemList && itemList.length ? <>
                                <Col ms={12} className='tableList'>
                                    <Table responsive bordered hover className='align-items-center'>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Image</th>
                                                <th>Title</th>
                                                <th>Food type</th>
                                                <th>Price large</th>
                                                <th>Price small</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {itemList && itemList.map((data, i) => {
                                                return (
                                                    <tr className='foodListTable' key={`food` + i}>
                                                        <td>{pageValue && pageValue === 1 ? i + 1 : i === 9 ? `${(pageValue && (pageValue))}${0}` : `${(pageValue && (pageValue - 1))}${(i + 1)}`}</td>
                                                        <td className='text-center'>
                                                            {data && data.foodId ? (
                                                                <FoodImage foodId={data.foodId} title={data.title} imageSize="shortImage" />
                                                            ) : (
                                                                <p>No photo</p>
                                                            )}
                                                        </td>
                                                        <td>{data.title}</td>
                                                        <td>{data && data.foodType === "VEG" ? "Vegetarian" : "Non-Vegetarian"}</td>
                                                        <td>{data.priceLarge}лв</td>
                                                        <td>{data.priceSmall}лв</td>
                                                        <td>
                                                            <div className="authToggle">
                                                                <p>OFF</p>
                                                                <label className="switch">
                                                                    <input type="checkbox" onChange={() => updateStatus(data.foodId, !data.isActive)} checked={data.isActive} />
                                                                    <span className="slider round"></span>
                                                                </label>
                                                                <p>ON</p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <button className='btn btn-primary' onClick={() => editFood(data.foodId)}><FontAwesomeIcon icon={faPenToSquare} /></button>
                                                            <button className='btn btn-danger' onClick={() => deleteFood(data.foodId)}><FontAwesomeIcon icon={faTrash} /></button>
                                                            <button className='btn btn-success' onClick={() => viewFood(data.foodId)}><FontAwesomeIcon icon={faEye} /></button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                    <Col md={12} className='d-flex justify-content-between'>
                                        <div className='limitBtn'>
                                            <p>Showing <b>{itemList && itemList.length}</b> of <b>{totalValue && totalValue}</b> Foods</p>
                                        </div>
                                        <div className='pagination'>
                                            <nav aria-label="Page navigation example" className='bottomPagination'>
                                                <ul className="pagination">
                                                    <li className="page-item">
                                                        <button onClick={() => prevPage()} className={prevButton === true ? "page-link" : "page-link disablePag"} aria-label="Previous">
                                                            <span aria-hidden="true">Prev</span>
                                                        </button>
                                                    </li>
                                                    <li className="page-item"><button className="page-link"><b>{pageValue}</b></button></li>
                                                    <li className="page-item">
                                                        <button onClick={() => nextPage()} className={nextButton === true ? "page-link" : "page-link disablePag"} aria-label="Next">
                                                            <span aria-hidden="true">Next</span>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </Col>
                                </Col>
                            </> : <Loader />}
                        </>}



                    </Row>
                </Container>
            </section>
            <WarningModal show={show} onClose={handleCloseModal} deleteFunction={handleDeleteFood} />
        </AdminLayout>
    )
}

export default FoodList