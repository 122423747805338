import React, { useState, useEffect } from 'react';
import AdminLayout from '../../Common/AdminLayout/AdminLayout';
import { getFoodList } from '../../../api/foods';
import { FoodInterface } from '../../../CommonInterface/CommonInterface';
import { Col, Container, Row } from 'react-bootstrap';
import Loader from '../../Common/Loader/Loader';
import "./FeatureCarousel.css"
import { toggleFeature } from '../../../api/foods';
import FoodImage from '../../Common/FoodImage/FoodImage';

const FeatureCarousel = () => {

    const [itemList, setItemList] = useState<FoodInterface[] | undefined>([]);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [limit, setLimit] = useState<number>(9);
    const [prevButton, setPrevButton] = useState<boolean>(false);
    const [nextButton, setNextButton] = useState<boolean>(false);
    const [noFoods, setNoFoods] = useState<boolean>(false);
    const [noCarousel, setNoCarousel] = useState<boolean>(false);
    const [pageValue, setPageValue] = useState<number>();
    const [totalValue, setTotalValue] = useState<number>(0);
    const [page, setPage] = useState(1);
    const pageCount = Math.ceil(totalValue / 9);
    const [featureFalse, setFeatureFalse] = useState<number>(0);
    const [featureList, setFeatureList] = useState<FoodInterface[] | undefined>([]);

    useEffect(() => {
        let body = { "tags": "", "featured": false }
        getFoodList(page, limit, body).then((data) => {
            if (data.data.foods.length === 0) {
                setNoFoods(true);
            }
            else {
                setNoFoods(false);
                setItemList(data.data.foods);
                setPageValue(data.data.currentPage);
                setTotalValue(data.data.total);
            }

        })
        let bodyFeature = { "tags": "", "featured": true }
        getFoodList(1, 5, bodyFeature).then((data) => {
            if (data.data.foods.length === 0) {
                setNoCarousel(true);
            }
            else {
                setFeatureList(data.data.foods);
            }

        })
        if (pageCount > 1) {
            setPrevButton(true)
        }
        if (page === 1) {
            setPrevButton(false)
        }
        // next button
        if (pageCount > 1) {
            setNextButton(true)
        }
        if (pageCount === page) {
            setNextButton(false)
        }
    }, [page, pageCount, featureFalse]);
    const nextPage = () => {
        setPage(page + 1)
    }

    const prevPage = () => {
        setPage(page - 1)
    }
    // view food
    const featureAdd = (foodId: number) => {
        toggleFeature(true, foodId).then((data) => {
            console.log(data);
            setFeatureFalse(featureFalse + 1);
        })
    }
    const featureRemove = (foodId: number) => {
        toggleFeature(false, foodId).then((data) => {
            console.log(data);
            setFeatureFalse(featureFalse + 1);
        })
    }
    // search item
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (e: any) => {
        setSearchQuery(e.target.value);
    };

    // Filter the featureList based on the search query
    const filteredFeatureList = itemList && itemList.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );


    return (
        <AdminLayout>
            <section className={`main-body ${isCollapsed ? '' : 'bodyCollapsed'}`}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="foodHeading">
                                <h1 className='text-center'>Feature list</h1>
                            </div>
                        </Col>

                        <Row>
                            <Col md={7}>
                                {noFoods && noFoods === true ? <>
                                    <Col md={12}>
                                        <div className="foodHeading">
                                            <h3 className='text-center text-danger'>No Product item for Feature</h3>
                                        </div>
                                    </Col>
                                </> : <>
                                    {/* item exist */}
                                    {itemList && itemList.length ? <>
                                        <Row>
                                            <Col md={12}><input type="text" className='searchInput form-control' placeholder="Search..." value={searchQuery} onChange={handleSearch} /></Col>
                                            {filteredFeatureList && filteredFeatureList.map((data, i) => <Col md={4}>
                                                <div className="smallCard">
                                                    {data && data.foodId ? (
                                                       <FoodImage foodId={data.foodId} title={data.title} imageSize="shortImage" />
                                                    ) : (
                                                        <p>No photo</p>
                                                    )}
                                                    <h1>{data.title}</h1>
                                                    <p>{data && data.foodType === "VEG" ? "Vegetarian" : "Non-Vegetarian"}</p>
                                                    <div className=''>
                                                        <p>Small: {data.priceSmall}лв/{data.wightSmall}g</p>
                                                        <p>Large: {data.priceLarge}лв/{data.weightLarge}g</p>
                                                    </div>
                                                    <button onClick={() => featureAdd(data.foodId)}>ADD</button>
                                                </div>
                                            </Col>)}
                                        </Row>
                                        <Col md={12} className='d-flex justify-content-between'>
                                            <div className='limitBtn'>
                                                <p>Total show: <b>{itemList && itemList.length}</b></p>
                                            </div>
                                            <div className='pagination'>
                                                <nav aria-label="Page navigation example" className='bottomPagination'>
                                                    <ul className="pagination">
                                                        <li className="page-item">
                                                            <button onClick={() => prevPage()} className={prevButton === true ? "page-link" : "page-link disablePag"} aria-label="Previous">
                                                                <span aria-hidden="true">Prev</span>
                                                            </button>
                                                        </li>
                                                        <li className="page-item"><button className="page-link"><b>{pageValue}</b></button></li>
                                                        <li className="page-item">
                                                            <button onClick={() => nextPage()} className={nextButton === true ? "page-link" : "page-link disablePag"} aria-label="Next">
                                                                <span aria-hidden="true">Next</span>
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </Col>
                                    </> : <><Loader /></>}

                                </>}

                            </Col>
                            <Col md={5} className='borderShadow'>
                                {noCarousel && noCarousel === true ? <>
                                    <div className="foodHeading">
                                        <h3 className='text-center text-danger'>No Product selected</h3>
                                    </div>
                                </> : <>
                                    {featureList && featureList.length > 0 ? <>
                                        <div className="foodHeading">
                                            <p className='text-center'>Maximum 5 item can selected only</p>
                                        </div>
                                        <div className="featureActive">
                                            {featureList && featureList.map((data, i) => <div className="featureSmallCard">
                                                {data && data.foodId ? (
                                                     <FoodImage foodId={data.foodId} title={data.title} imageSize="shortImage" />
                                                ) : (
                                                    <p>No photo</p>
                                                )}
                                                <h1>{data.title}</h1>
                                                <p>{data && data.foodType === "VEG" ? "Vegetarian" : "Non-Vegetarian"}</p>
                                                <div className=''>
                                                    <p>Small: {data.priceSmall}лв/{data.wightSmall}g</p>
                                                    <p>Large: {data.priceLarge}лв/{data.weightLarge}g</p>
                                                </div>
                                                <button className='remove' onClick={() => featureRemove(data.foodId)}>REMOVE</button>
                                            </div>)}
                                        </div>
                                    </> : <><Loader /></>}
                                </>}


                            </Col>
                        </Row>
                    </Row>
                </Container>
            </section>
        </AdminLayout>
    )
}

export default FeatureCarousel