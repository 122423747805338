import React from 'react';

const imagePaths: Record<string, string> = {
    NO_CHOLESTEROL: require("../../../assets/icon/bad-cholesterol.png"),
    ANTIOXIDANTS: require("../../../assets/icon/blueberry.png"),
    CASEIN: require("../../../assets/icon/casein.png"),
    RICH_IN_COLLAGEN: require("../../../assets/icon/collagen.png"),
    GLUTEN_FREE: require("../../../assets/icon/gluten-free.png"),
    IN_GOUT: require("../../../assets/icon/gout.png"),
    WITH_HIGH_BLOOD_PRESSURE: require("../../../assets/icon/hypertension.png"),
    NO_OXALATES: require("../../../assets/icon/kidney.png"),
    WITH_GASTRITIS: require("../../../assets/icon/medical.png"),
    N0_CRABS: require("../../../assets/icon/no-carbs.png"),
    NO_FAT: require("../../../assets/icon/no-fat.png"),
    HIGH_PROTEIN: require("../../../assets/icon/protein.png"),
    VEGETARIAN: require("../../../assets/icon/salad.png"),
    WITHOUT_SUGAR: require("../../../assets/icon/sugar-free.png"),
    VEGAN: require("../../../assets/icon/vegan.png"),
    VITAMINS: require("../../../assets/icon/vitamin.png"),
    RICH_IN_GOOD_FATS: require('../../../assets/icon/RICH_IN_GOOD_FATS.png')
};

interface ImageProps {
    tags: any;
}

const IconImage = ({ tags }: ImageProps) => {
    const tagsList = tags.split(',');
    return (
        <div className='Carouselicon'>
            {tagsList && tagsList.slice(0, 5).map((data: string, i: number) =>
                <img key={`tag${i}`} src={imagePaths[data]} alt="icon" width="30px" />
            )}
        </div>
    )
}

export default IconImage