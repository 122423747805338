import { API } from "../config";

export const getUserProfile = async () => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';

    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/users/profile`, {
            headers: headers
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

export const getUserList = async (page: number, limit: number) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';

    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/users?page=${page}&limit=${limit}`, {
            headers: headers
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

export const updateUser = async (body: any) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';

    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
        };
        const formData = new FormData();
        for (const key in body) {
            formData.append(key, body[key]);
        }
        const response = await fetch(`${API}/users`, {
            method: 'PATCH',
            headers: headers,
            body: formData
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

export const changeRole = async (userId: number, body = {}) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';

    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/admin-management/change-role/${userId}`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

export const addMenu = async (body = {}) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';

    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/users/menu/profile`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

export const getMenuProfile = async () => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';

    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/users/menu/profile`, {
            headers: headers
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

export const getProfileUpdate = async (body: {}) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/users/menu/profile`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};