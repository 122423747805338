import React, { useState, useRef } from 'react';
import Layout from '../Common/Layout/Layout';
import "./Auth.css";
import { Container, Row, Col } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
// font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faKey, faLocationArrow, faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { authenticate, loginUser, registerUser } from '../../api/auth';
import { LoginInterface, RegisterInterface } from '../../CommonInterface/CommonInterface';

import { ToastContainer } from 'react-toastify';
import { showNotifications } from '../../lib/toaster';

const Auth = () => {

    const [moveToRight, setMoveToRight] = useState(false);
    const [textHide, setTextHide] = useState(false);
    const [displayHide, setDisplayHide] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    // use form
    const form = useRef();
    const {
        register,
        handleSubmit,
        setValue,
    } = useForm();

    const handleClick = () => {
        setMoveToRight(!moveToRight);
        setTextHide(!textHide);
        setDisplayHide(!displayHide);
    };

    // register
    let onSubmit = (user: any) => {
        registerUser(user).then((data: any) => {
            if (data.statusCode !== 200 && data.statusCode !== 201) {
                return showNotifications('error', data.message);
            } else {
                handleClick()
                return showNotifications('success', data.message);
            }
        });
    }

    // login
    const userLogin = () => {
        const user = {
            email: email,
            password: password
        }
        loginUser(user).then((data: any) => {
            if (data.statusCode !== 200) {
                return showNotifications('error', data.message);
            } else {
                authenticate(data, () => {
                    showNotifications('success', data.message);
                    if (data.user.userRole === "CLIENT") {
                        navigate(`/`);
                    }else if(data.user.userRole === "ADMIN"){
                        navigate(`/add_food`);
                    } else {
                        navigate(`/client_order`);
                    }
                });
            }
        });
    }

    return (
        <Layout>
            <section className="login-page">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="signup-overlay">
                                <ToastContainer />
                                <div className='formBox'>
                                    <div className="con">
                                        <div className="signUp-form">
                                            {textHide ?
                                                <><h1 className='textFade'>Sign up</h1></> :
                                                <><h1 className='textFadeTwo'>Sign in</h1></>
                                            }
                                        </div>
                                        <br />
                                        <div className="field-set">
                                            <div style={{ position: 'relative', height: '100%' }}>

                                                <div className="moving-box-container">
                                                    <form onSubmit={handleSubmit(onSubmit)}>
                                                        <div className={`moving-box ${moveToRight ? 'move-right-one' : 'move-left-one'}`}>
                                                            <div style={{ position: 'relative', display: 'flex' }}>
                                                                <span className="input-item">
                                                                    <FontAwesomeIcon icon={faUser} />
                                                                </span>
                                                                <input {...register("firstName", { required: true })} className="form-input" type="text" placeholder="First name*" required />
                                                            </div>
                                                        </div>
                                                        <div className={`moving-box ${moveToRight ? 'move-right-one' : 'move-left-one'}`}>
                                                            <div style={{ position: 'relative', display: 'flex' }}>
                                                                <span className="input-item">
                                                                    <FontAwesomeIcon icon={faUser} />
                                                                </span>
                                                                <input  {...register("lastName", { required: true })} className="form-input" type="text" placeholder="Last name*" required />
                                                            </div>
                                                        </div>
                                                        <div className={`moving-box ${moveToRight ? 'move-right-one' : 'move-left-one'}`}>
                                                            <div style={{ position: 'relative', display: 'flex' }}>
                                                                <span className="input-item">
                                                                    <FontAwesomeIcon icon={faEnvelope} />
                                                                </span>
                                                                <input {...register("email", { required: true })} className="form-input" type="email" placeholder="Email*" required />
                                                            </div>
                                                        </div>
                                                        <div className={`moving-box ${moveToRight ? 'move-right-one' : 'move-left-one'}`}>
                                                            <div style={{ position: 'relative', display: 'flex' }}>
                                                                <span className="input-item">
                                                                    <FontAwesomeIcon icon={faKey} />
                                                                </span>
                                                                <input {...register("password", { required: true })} className="form-input" type="password" placeholder="password*" />
                                                            </div>
                                                        </div>
                                                        <div className={`moving-box ${moveToRight ? 'move-right-one' : 'move-left-one'}`}>
                                                            <div style={{ position: 'relative', display: 'flex' }}>
                                                                <span className="input-item">
                                                                    <FontAwesomeIcon icon={faLocationArrow} />
                                                                </span>
                                                                <input {...register("address", { required: true })} className="form-input" type="text" placeholder="Address*" required />
                                                            </div>
                                                        </div>
                                                        <div className={`moving-box ${moveToRight ? 'move-right-one' : 'move-left-one'}`}>
                                                            <button className="log-in">Registration</button>
                                                            <button type='button' onClick={handleClick} className="forget-pass">Already have a account?</button>
                                                        </div>
                                                    </form>
                                                </div>

                                            </div>

                                            <div style={{ position: 'relative', height: '100%', marginTop: '-25px', marginBottom: '37px' }}>
                                                <div className="moving-box-container">
                                                    <div className={`moving-box ${moveToRight ? 'move-right' : 'move-left'}`}>
                                                        <div style={{ position: 'relative', display: 'flex' }}>
                                                            <span className="input-item">
                                                                <FontAwesomeIcon icon={faEnvelope} />
                                                            </span>
                                                            <input onChange={(e) => setEmail(e.target.value)} value={email} className="form-input" type="email" placeholder="Email*" />
                                                        </div>
                                                    </div>
                                                    <div className={`moving-box ${moveToRight ? 'move-right' : 'move-left'}`}>
                                                        <div style={{ position: 'relative', display: 'flex' }}>
                                                            <span className="input-item">
                                                                <FontAwesomeIcon icon={faLock} />
                                                            </span>
                                                            <input onChange={(e) => setPassword(e.target.value)} className="form-input" type="password" placeholder="Password*" />
                                                        </div>
                                                    </div>
                                                    <div className={`moving-box ${moveToRight ? 'move-right' : 'move-left'}`}>
                                                        <button className="log-in" type='button' onClick={() => userLogin()}>Login</button>
                                                        <button type='button' onClick={handleClick} className="forget-pass">Create a account?</button>
                                                    </div>

                                                </div>

                                            </div>


                                            <div className='hideBox'>
                                                {textHide ?
                                                    <><button className="log-in">Registration</button></> :
                                                    <><button className="log-in" type='button' onClick={() => userLogin()}>Login</button></>
                                                }
                                                {/* <div className="other">
                                                    {textHide ?
                                                        <><button type='button' onClick={handleClick} className="forget-pass">Already have a account?</button></> :
                                                        <><button type='button' onClick={handleClick} className="forget-pass">Create a account?</button></>
                                                    }
                                                </div> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
    )
}

export default Auth;