import { DeliveryInterface, OrderInterface } from "../CommonInterface/CommonInterface";
export const CustomPrevArrow: React.FC<React.HTMLProps<HTMLDivElement>> = (props) => (
  <div {...props} style={{ ...props.style, display: 'block', background: 'transparent' }}>
    Previous
  </div>
);

export const CustomNextArrow: React.FC<React.HTMLProps<HTMLDivElement>> = (props) => (
  <div {...props} style={{ ...props.style, display: 'block', background: 'transparent' }}>
    Next
  </div>
);
export function deleteFoodItem(foodId: number | undefined, deliveryDate: string | undefined, deliveryTime: string | undefined) {
  const storedItems = localStorage.getItem('orderList');
  if (storedItems) {
    const items = JSON.parse(storedItems);

    // Filter out the item that matches the specified criteria
    const updatedItems = items.filter((item: any) => {
      return (
        item.foodId !== foodId ||
        item.deliveryDate !== deliveryDate ||
        item.deliveryTime !== deliveryTime
      );
    });
    localStorage.setItem('orderList', JSON.stringify(updatedItems));
  }
}
// quantity increase
export function incrementCount(foodId: number, deliveryDate: string, deliveryTime: string) {
  const storedItems = localStorage.getItem('orderList');
  if (storedItems) {
    const items = JSON.parse(storedItems);
    const updatedItems = items.map((item: any) => {
      if (
        item.foodId === foodId &&
        item.deliveryDate === deliveryDate &&
        item.deliveryTime === deliveryTime
      ) {
        return {
          ...item,
          quantity: item.quantity + 1,
        };
      }
      return item;
    });
    localStorage.setItem('orderList', JSON.stringify(updatedItems));
  }
}
export function splitTimeRange(timeRange: string) {
  const [startTime, endTime] = timeRange.split('-');
  return { startTime, endTime };
}
export function deliveryTimeAdd(foodId: number, deliveryDate: string, deliveryTime: string, startTime: string, endTime: string) {
  const storedItems = localStorage.getItem('orderList');
  if (storedItems) {
    const items = JSON.parse(storedItems);
    const updatedItems = items.map((item: any) => {
      if (item.deliveryDate === deliveryDate && item.deliveryTime === deliveryTime) {
        return {
          ...item,
          startTime: startTime,
          endTime: endTime,
        };
      }
      return item;
    });
    localStorage.setItem('orderList', JSON.stringify(updatedItems));
  }
}
// quantity decrease
export function decrementCount(foodId: number, deliveryDate: string, deliveryTime: string) {
  const storedItems = localStorage.getItem('orderList');

  if (storedItems) {
    let items = JSON.parse(storedItems);

    // Use map to create a new array
    items = items.map((item: any) => {
      if (
        item.foodId === foodId &&
        item.deliveryDate === deliveryDate &&
        item.deliveryTime === deliveryTime
      ) {
        const newQuantity = Math.max(0, item.quantity - 1);
        return { ...item, quantity: newQuantity };
      }
      return item;
    });
    items = items.filter((item: any) => item.quantity > 0);
    localStorage.setItem('orderList', JSON.stringify(items));

  }
}
// time set
export function extractDateFromDateAndTime(dateTimeString: string) {
  const parts = dateTimeString.split('T');
  if (parts.length === 2) {
    return parts[0];
  } else {
    return null;
  }
}
// array to json convert
export function convertArrayToObject(arr: any) {
  const result: any = {};
  arr.forEach((item: any) => {
    const { deliveryDate }: any = item;
    if (!result[deliveryDate]) {
      result[deliveryDate] = [];
    }
    result[deliveryDate].push(item);
  });
  return result;
}
// convertTimeFormat
export function convertTimeFormat(inputJson: DeliveryInterface) {
  const convertedJson = { ...inputJson };
  if (convertedJson.startTime) {
    const startTimeParts = convertedJson.startTime.split(':');
    if (startTimeParts.length === 3) {
      const [hours, minutes] = startTimeParts;
      convertedJson.startTime = `${hours}:${minutes}`;
    }
  }
  if (convertedJson.endTime) {
    const endTimeParts = convertedJson.endTime.split(':');
    if (endTimeParts.length === 3) {
      const [hours, minutes] = endTimeParts;
      convertedJson.endTime = `${hours}:${minutes}`;
    }
  }

  return convertedJson;
}

export function convertTimeArrayFormat(inputJsonArray: DeliveryInterface[]) {
  return inputJsonArray.map((item) => {
    const newItem = { ...item };
    if (newItem.startTime) {
      newItem.startTime = newItem.startTime.substring(0, 5);
    }
    if (newItem.endTime) {
      newItem.endTime = newItem.endTime.substring(0, 5);
    }
    return newItem;
  });
}

export function sortArrayByDeliveryDate(arr: OrderInterface[]) {
  return arr.sort((a, b) => {
    const dateA = new Date(a.deliveryDate);
    const dateB = new Date(b.deliveryDate);

    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  });
}

export function divideArrayIntoGroups(data: any) {
  const dividedGroups = [];
  for (let i = 0; i < data.length; i += 5) {
    const group = data.slice(i, i + 5);
    dividedGroups.push(group);
  }
  return dividedGroups;
}
export function getAllDatesFromSunday(input: any) {
  const firstDate = new Date(input[0].date);
  const previousSunday = new Date(firstDate);
  previousSunday.setDate(firstDate.getDate() - firstDate.getDay()+1);
  const lastDate = new Date(input[input.length - 1].date);
  const allDates = [];

  let currentDate = new Date(previousSunday);
  while (currentDate <= lastDate) {
    allDates.push(currentDate.toISOString().slice(0, 10));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return allDates;
}

export function groupDatesByWeek(input: any, generatedDates: any) {
  const newAarray: any = Array.from({ length: 7 }, () => []);
  let count = 0;
  let index = 0;

  for (const date of generatedDates) {
    const inputData = input.find((item: any) => item.date === date);
    if (inputData) {
      newAarray[index].push(inputData);
    }
    count++;
    if (count === 7) {
      count = 0;
      index++;
    }
  }
  return newAarray.filter((arr: any) => arr.length > 0);
}

export function groupFoodItemsByDate(foodItems: any) {
  const dateWiseArray: any = {};
  foodItems.forEach((item: any) => {
    const deliveryDate = item.deliveryDate;

    if (!dateWiseArray[deliveryDate]) {
      dateWiseArray[deliveryDate] = {
        date: deliveryDate,
        days: "",
        items: [],
        totalProteins: 0,
        totalCarbohydrates: 0,
        totalCalories: 0,
        totalFats: 0,
      };
    }

    const dateItem: any = dateWiseArray[deliveryDate];
    dateItem.items.push(item);
    dateItem.days = item.days;
    dateItem.totalProteins += item.proteins * item.weight * item.quantity;
    dateItem.totalCarbohydrates += item.carbohydrates * item.weight * item.quantity;
    dateItem.totalCalories += item.calories * item.weight * item.quantity;
    dateItem.totalFats += item.fats * item.weight * item.quantity;
  });

  const dateWiseResult = Object.values(dateWiseArray).map((r: any) => ({ ...r, isChecked: true }));
  return dateWiseResult;
}

export function convertDayName(dateString: any) {
  const dayAbbreviations = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const fullDayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  const parts = dateString.split(", ");
  if (parts.length !== 2) {
    return dateString;
  }
  const dayAbbreviation = parts[0];
  const date = parts[1];
  const index = dayAbbreviations.indexOf(dayAbbreviation);
  if (index === -1) {
    return dateString;
  }
  const fullDayName = fullDayNames[index];
  return fullDayName;
}

export function removeFoodTitleAndDays(data: any) {
  return data.map((item: any) => {
    const { foodTitle, days, ...rest } = item;
    return rest;
  });
}

export function jsonCheck(items: any) {
  return items.every((item: any) => item.startTime && item.endTime);
}

export const countSerialDates = (dates: any): number => {
  if (dates.length === 0) {
    return 0;
  }
  dates.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());
  let currentCount = 1;
  let maxCount = 1;
  for (let i = 1; i < dates.length; i++) {
    const currentDate: any = new Date(dates[i].date);
    const prevDate: any = new Date(dates[i - 1].date);
    if (currentDate - prevDate === 24 * 60 * 60 * 1000) {
      currentCount++;
      maxCount = Math.max(maxCount, currentCount);
    } else {
      currentCount = 1;
    }
  }
  return maxCount;
};

export function getCheckedItems(orders: any) {
  const flattenedOrders = orders.flat();
  const checkedOrders = flattenedOrders.filter((order: any) => order.isChecked);
  const checkedItems = checkedOrders.flatMap((order: any) =>
    order.items.map((item: any) => ({
      price: item.price,
      quantity: item.quantity,
      foodId: item.foodId,
      weight: item.weight,
      deliveryDate: item.deliveryDate,
      deliveryTime: item.deliveryTime,
      startTime: item.startTime,
      endTime: item.endTime
    }))
  );

  return checkedItems;
}


export function convertValuesToContent(values: any, options: any) {
  return values.map((value: any) => {
    const option = options.find((opt: any) => opt.value === value);
    return option
      ? {
        value: value,
        content: option.content,
      }
      : null;
  });
}

export async function addActiveStatusToDeliveries(selectedDeliveries: any, foodItems: any) {
  for (let i = 0; i < selectedDeliveries.length; i++) {
    const delivery = selectedDeliveries[i];
    const deliveryDate = delivery.isoFormattedDate;

    const breakfastItems = foodItems.filter(
      (foodItem: any) =>
        foodItem.deliveryDate === deliveryDate &&
        foodItem.deliveryTime === "BREAKFAST"
    );

    const lunchItems = foodItems.filter(
      (foodItem: any) =>
        foodItem.deliveryDate === deliveryDate &&
        foodItem.deliveryTime === "LUNCH"
    );

    const breakfastFoodTitles = breakfastItems.map((item: any) => item.foodTitle);
    const lunchFoodTitles = lunchItems.map((item: any) => item.foodTitle);

    selectedDeliveries[i] = {
      ...delivery,
      breakfastActive: breakfastItems.length > 0,
      lunchActive: lunchItems.length > 0,
      breakfastIds: breakfastItems.map((item: any) => item.foodId),
      lunchIds: lunchItems.map((item: any) => item.foodId),
      breakfastFoodTitles,
      lunchFoodTitles,
    };
  }

  return selectedDeliveries;
}
// calculate total weekly protein
export const calculateTotalSum = (propertyName: string, orderList: any, currentPage: number) => {
  if (orderList && orderList[currentPage] && Array.isArray(orderList[currentPage])) {
    return Number(
      orderList[currentPage].reduce((sum: any, productItem: any) => sum + productItem[propertyName], 0).toFixed(2)
    );
  } else {
    return 0;
  }
};

// stick slider
export const generateSettings = (itemCount: number) => {
  return {
    dots: false,
    infinite: true,
    autoplaySpeed: 2000,
    autoplay: true,
    slidesToShow: itemCount,
    slidesToScroll: 1,
    // prevArrow: null,
    // nextArrow: null,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: false,
          prevArrow: <CustomPrevArrow />,
          nextArrow: <CustomNextArrow />,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: false,
          prevArrow: false,
          nextArrow: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          prevArrow: false,
          nextArrow: false,
          infinite: true,
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          prevArrow: false,
          nextArrow: false,
          infinite: true,
        },
      },
    ],
  }
}

// max days count in discount
export function discountDaysCount(deliveryData: any) {
  let consecutiveDaysCount = 1;
  let maxConsecutiveDaysCount = 1;

  // Sort the deliveryData array based on delivery dates
  deliveryData.sort((a: any, b: any) => new Date(a.deliveryDate).getTime() - new Date(b.deliveryDate).getTime());

  for (let i = 1; i < deliveryData.length; i++) {
    const currentDate = new Date(deliveryData[i].deliveryDate);
    const previousDate = new Date(deliveryData[i - 1].deliveryDate);
    const diffInTime = currentDate.getTime() - previousDate.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);

    // Check if the current date is consecutive to the previous one
    if (diffInDays === 1 && deliveryData[i].deliveryDate !== deliveryData[i - 1].deliveryDate) {
      consecutiveDaysCount++;
    } else if (diffInDays > 1) {
      // If there's a gap between dates, reset the consecutiveDaysCount
      maxConsecutiveDaysCount = Math.max(maxConsecutiveDaysCount, consecutiveDaysCount);
      consecutiveDaysCount = 1;
    }
  }

  // Check if the last counted sequence is the longest
  maxConsecutiveDaysCount = Math.max(maxConsecutiveDaysCount, consecutiveDaysCount);

  return maxConsecutiveDaysCount;
}

// check date is checked false
export function getUncheckedDates(data: any) {
  const uncheckedDates: string[] = [];
  data.forEach((item: any) => {
    if (!item.isChecked) {
      uncheckedDates.push(item.date);
    }
  });
  return uncheckedDates;
}

export function countConsecutivePeriods(dates: string[]): number {
  function parseDate(dateStr: string): Date {
    const [year, month, day] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day); // months are 0-indexed in JavaScript Date
  }

  const periodLength = 5;

  if (dates.length < periodLength) {
    return 0;
  }

  // First, sort the dates to ensure they are in chronological order
  const sortedDates = dates.map(parseDate).sort((a, b) => a.getTime() - b.getTime());

  let consecutiveCount = 0;

  for (let i = 0; i <= sortedDates.length - periodLength; i++) {
    let isConsecutive = true;

    for (let j = 0; j < periodLength - 1; j++) {
      const current = sortedDates[i + j];
      const next = sortedDates[i + j + 1];

      const difference = (next.getTime() - current.getTime()) / (1000 * 60 * 60 * 24);

      if (difference !== 1) {
        isConsecutive = false;
        break;
      }
    }

    if (isConsecutive) {
      consecutiveCount++;
      // Move the starting point past this period to avoid overlapping
      i += periodLength - 2; 
    }
  }

  return consecutiveCount*7;
}


export const countActiveFoods = (itemList: any) => {
  const activeFoodsCount = itemList?.reduce((count: number, food: any) => {
    if (food.isActive === true) {
      return count + 1;
    } else {
      return count;
    }
  }, 0);

  return activeFoodsCount;
};

export function convertToDateOnly(timestamp: string): string {
  const date = new Date(timestamp);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}