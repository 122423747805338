import React from 'react';
import "./UserOrder.css";
import { Col, Container, Row, Table } from 'react-bootstrap';
import { UserOrderInterface } from '../../../CommonInterface/CommonInterface';
import { Link } from 'react-router-dom';
import { extractDateFromDateAndTime } from '../../../lib/commonFunction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faEye } from '@fortawesome/free-solid-svg-icons';

interface OrderProps {
  orderList: UserOrderInterface[];
  page: number;
  pageValue: number | null | undefined;
  setPage: any;
  prevButton: boolean;
  nextButton: boolean;
}
const UserOrder = ({ orderList, page, setPage, prevButton, pageValue, nextButton }: OrderProps) => {

  const nextPage = () => {
    setPage(page + 1)
  }

  const prevPage = () => {
    setPage(page - 1)
  }
  return (
    <>
      <div className="orderCard">
        <Container>
          <Row>
            <Col md={12}>
              <Table responsive bordered hover className='align-items-center'>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>E-mail</th>
                    <th>Address</th>
                    <th>Order date</th>
                    <th>Status</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  {orderList && orderList.map((data, i) => <tr className='foodListTable' key={`orderList` + i}>
                    <td className='text-center'>{i + 1}</td>
                    <td>{data.user.firstName} {data.user.lastName}</td>
                    <td>{data.user.email}</td>
                    <td>{data.address}</td>
                    <td>{extractDateFromDateAndTime(data.created_at)}</td>
                    <td>{data.status === "PENDING" ? <>Pending</> : <></>}</td>
                    <td className='text-center'><Link to={`/order/${data.orderId}`}><FontAwesomeIcon icon={faEye} /></Link></td>
                  </tr>)}
                </tbody>
              </Table>
            </Col>
            <Col md={12} className='d-flex justify-content-between'>
              <div className='limitBtn'>
                <p>Total show: <b>{orderList && orderList.length}</b></p>
              </div>
              <div className='pagination'>
                <nav aria-label="Page navigation example" className='bottomPagination'>
                  <ul className="pagination">
                    <li className="page-item">
                      <button onClick={() => prevPage()} className={prevButton === true ? "page-link" : "page-link disablePag"} aria-label="Previous">
                        <span aria-hidden="true">Prev</span>
                      </button>
                    </li>
                    <li className="page-item"><button className="page-link"><b>{pageValue}</b></button></li>
                    <li className="page-item">
                      <button onClick={() => nextPage()} className={nextButton === true ? "page-link" : "page-link disablePag"} aria-label="Next">
                        <span aria-hidden="true">Next</span>
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default UserOrder