import React, { useState, useEffect } from 'react';
import "./MenuRequest.css";
import AdminLayout from '../../Common/AdminLayout/AdminLayout';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { getMenuRequestList } from '../../../api/menu';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../Common/Loader/Loader';

const MenuRequest = () => {


    const [requestList, setRequestList] = useState<any>([]);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleLayoutStateChange = (clickedInParent: boolean) => {
        setIsCollapsed(!clickedInParent);
    };

    useEffect(() => {
        getMenuRequestList().then((data) => {
            if (data.statusCode !== 200) {
                console.log('No Menu Request');
            }
            else {
                setRequestList(data.requests);
            }
        })
    }, [])
    
    return (
        <>
            <AdminLayout onLayoutStateChange={handleLayoutStateChange}>
                <section className={`main-body ${isCollapsed ? '' : 'bodyCollapsed'}`}>
                    <Container>
                        {requestList && requestList.length ? <>
                            {requestList && requestList.length ?
                                <Row>
                                    <Col md={12}>
                                        <h1 className='adminHeading text-center'>Menu Request</h1>
                                    </Col>
                                    <Col md={12} className='mt-3'>
                                        <Table responsive bordered hover className='align-items-center'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>E-mail</th>
                                                    <th>Name</th>
                                                    <th>Number of days</th>
                                                    <th>Client Response</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {requestList && requestList.map((data: any, i: number) => <tr className='foodListTable' key={`menuList` + i}>
                                                    <td>{i + 1}</td>
                                                    <td>{data.user.email}</td>
                                                    <td>{data.user.firstName} {data.user.lastName}</td>
                                                    <td>{data.daysOfMenu}</td>
                                                    <td>{data.acceptMenu ? "Accept" : (data.note?.length ? "Reject" : "PENDING")}</td>
                                                    <td>{data.menuCartItem.length ? "Complete" : "Pending"}</td>
                                                    <td><Link className='btn btn-success text-white' to={`/view-request/${data.menuRequestId}`}><FontAwesomeIcon icon={faEye} /></Link></td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                : <Loader />}
                        </> : <Row>
                            <Col md={12}>
                                <h1 className='adminHeading text-center'>No data found</h1>
                            </Col>
                        </Row>}

                    </Container>
                </section>
            </AdminLayout>
        </>
    )
}

export default MenuRequest