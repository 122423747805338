import React, { ReactNode, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";


interface Props {
    children?: ReactNode,
    onLayoutStateChange?: (clickedInParent: boolean) => void;
}
const AdminLayout = ({ children,onLayoutStateChange, ...props }: Props) => {
    const [clickedInParent, setClickedInParent] = useState(false);

    const handleChildClick = (isClicked: boolean) => {
        setClickedInParent(isClicked);
        if (onLayoutStateChange) {
            onLayoutStateChange(isClicked); // Call the callback when state changes
        }
    };
    return (
        <>
            <Sidebar onChildClick={handleChildClick} />
            <div{...props}>{children}</div>

        </>
    )
}

export default AdminLayout