import React, { useEffect, useState } from 'react';
import { getSingleOrder } from '../../api/order';
import { useParams } from 'react-router-dom';
import Layout from '../Common/Layout/Layout';
import { Container, Table, Col } from 'react-bootstrap';
import { Buffer } from 'buffer';
import { extractDateFromDateAndTime } from '../../lib/commonFunction';
import Loader from '../Common/Loader/Loader';

const OrderDetails = () => {
    const [orderInfo, setOrderInfo] = useState<any>();
    const [cartItem, setCartItem] = useState<any>([]);
    const { id }: any = useParams();
    useEffect(() => {
        getSingleOrder(id).then((data) => {

            if (data.statusCode !== 201) {

            }
            else {
                setOrderInfo(data.data.order);
                setCartItem(data.data.order.cartItem);
            }
        })
    }, []);

    const totalPrice = cartItem.reduce((accumulator: any, currentItem: any) => {
        return accumulator + currentItem.price * currentItem.quantity;
    }, 0);


    return (
        <Layout>
            <section className="checkoutSection">
                <Container>
                {cartItem && cartItem.length > 0 ? <>
                    <div className="orderDetailsHeading">
                        <h2>Order Details asasa</h2>
                    </div>
                    <div className="row g-5">
                        <Col md={9} className='checkoutCart'>
                                <Table responsive bordered hover className='align-items-center text-center'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Image</th>
                                        <th>Title</th>
                                        <th>Food type</th>
                                        <th>Delivery Date</th>
                                        <th>Time</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cartItem && cartItem.map((data: any, i: number) => <tr className='foodListTable' key={`food` + i}>
                                        <td>{i + 1}</td>
                                        <td>
                                            {data.food && data.food.photo ? (
                                                <img src={`data:image/jpeg;base64,${Buffer.from(data.food.photo).toString('base64')}`} alt={data.title} width="50px" height="50px" />
                                            ) : (
                                                <p>No photo</p>
                                            )}
                                        </td>
                                        <td>{data.food && data.food.title}</td>
                                        <td>{data && data.foodType === "VEG" ? "Vegetarian" : "Non-Vegetarian"}</td>
                                        <td>{extractDateFromDateAndTime(data.deliveryDate)}</td>
                                        <td>{data.deliveryTime}</td>
                                        <td>{data.quantity}</td>
                                        <td>{data.price}</td>
                                        <td>{data.quantity * data.price}лв</td>

                                    </tr>)}
                                    <tr className='totalValue'>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th>Total Price</th>
                                        <th><p>${totalPrice ? totalPrice.toFixed(2) + 'лв' : ''}</p></th>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col md={3} className='checkoutAddress'>
                            <h4 className="mb-3">Billing address</h4>
                            <form className="needs-validation">
                                <div className="row g-3">
                                    <div className="col-sm-6">
                                        <label htmlFor="firstName" className="form-label">First name</label>
                                        <input type="text" className="form-control" id="firstName" value={orderInfo && orderInfo.user.firstName} />
                                    </div>

                                    <div className="col-sm-6">
                                        <label htmlFor="lastName" className="form-label">Last name</label>
                                        <input type="text" className="form-control" id="lastName" value={orderInfo && orderInfo.user.lastName} />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="email" className="form-label">Email <span className="text-muted"></span></label>
                                        <input type="email" className="form-control" id="email" value={orderInfo && orderInfo.user.email} />
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="address" className="form-label">Address</label>
                                        <textarea className="form-control" id="address" value={orderInfo && orderInfo.user.address} />
                                    </div>
                                    {orderInfo && orderInfo.user.note ? <>
                                        <div className="col-12">
                                        <label htmlFor="address" className="form-label">Note</label>
                                        <textarea className="form-control" id="address" value={orderInfo && orderInfo.user.note} />
                                    </div>
                                    </>:""}
                                    
                                </div>
                            </form>
                        </Col>
                    </div>
                    </>:<><Loader/></>}
                </Container>
            </section>
        </Layout>
    )
}

export default OrderDetails