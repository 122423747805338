import React from 'react'
import { Modal } from 'react-bootstrap';
import NO_CHOLESTEROL from "../../assets/icon/bad-cholesterol.png";
import ANTIOXIDANTS from "../../assets/icon/blueberry.png";
import CASEIN from "../../assets/icon/casein.png";
import RICH_IN_COLLAGEN from "../../assets/icon/collagen.png";
import GLUTEN_FREE from "../../assets/icon/gluten-free.png";
import IN_GOUT from "../../assets/icon/gout.png";
import WITH_HIGH_BLOOD_PRESSURE from "../../assets/icon/hypertension.png";
import NO_OXALATES from "../../assets/icon/kidney.png";
import WITH_GASTRITIS from "../../assets/icon/medical.png";
import N0_CRABS from "../../assets/icon/no-carbs.png";
import NO_FAT from "../../assets/icon/no-fat.png";
import HIGH_PROTEIN from "../../assets/icon/protein.png";
import VEGETARIAN from "../../assets/icon/salad.png";
import WITHOUT_SUGAR from "../../assets/icon/sugar-free.png";
import VEGAN from "../../assets/icon/vegan.png";
import VITAMINS from "../../assets/icon/vitamin.png";


interface TagInfoProps {
    show: boolean;
    setShow: (show: boolean) => void;
}
const TagInfo = ({ show, setShow }: TagInfoProps) => {
    const handleClose = () => setShow(false);
    return (
        <Modal show={show} onHide={handleClose} scrollable size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <img className="float-start" src={ANTIOXIDANTS} alt="" />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tristique, quam a iaculis vulputate, nisi justo euismod dolor, ut auctor dolor est in lorem.</p>
                    </li>
                    <li className="list-group-item">
                        <img className="float-start" src={N0_CRABS} alt="" />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tristique, quam a iaculis vulputate, nisi justo euismod dolor, ut auctor dolor est in lorem.</p>
                    </li>
                    <li className="list-group-item">
                        <img className="float-start" src={GLUTEN_FREE} alt="" />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tristique, quam a iaculis vulputate, nisi justo euismod dolor, ut auctor dolor est in lorem.</p>
                    </li>
                    <li className="list-group-item">
                        <img className="float-start" src={WITHOUT_SUGAR} alt="" />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tristique, quam a iaculis vulputate, nisi justo euismod dolor, ut auctor dolor est in lorem.</p>
                    </li>
                    <li className="list-group-item">
                        <img className="float-start" src={NO_FAT} alt="" />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tristique, quam a iaculis vulputate, nisi justo euismod dolor, ut auctor dolor est in lorem.</p>
                    </li>
                    <li className="list-group-item">
                        <img className="float-start" src={NO_OXALATES} alt="" />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tristique, quam a iaculis vulputate, nisi justo euismod dolor, ut auctor dolor est in lorem.</p>
                    </li>
                    <li className="list-group-item">
                        <img className="float-start" src={NO_CHOLESTEROL} alt="" />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tristique, quam a iaculis vulputate, nisi justo euismod dolor, ut auctor dolor est in lorem.</p>
                    </li>
                    <li className="list-group-item">
                        <img className="float-start" src={RICH_IN_COLLAGEN} alt="" />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tristique, quam a iaculis vulputate, nisi justo euismod dolor, ut auctor dolor est in lorem.</p>
                    </li>
                    <li className="list-group-item">
                        <img className="float-start" src={VEGAN} alt="" />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tristique, quam a iaculis vulputate, nisi justo euismod dolor, ut auctor dolor est in lorem.</p>
                    </li>
                    <li className="list-group-item">
                        <img className="float-start" src={VEGETARIAN} alt="" />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tristique, quam a iaculis vulputate, nisi justo euismod dolor, ut auctor dolor est in lorem.</p>
                    </li>
                    <li className="list-group-item">
                        <img className="float-start" src={HIGH_PROTEIN} alt="" />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tristique, quam a iaculis vulputate, nisi justo euismod dolor, ut auctor dolor est in lorem.</p>
                    </li>
                    <li className="list-group-item">
                        <img className="float-start" src={VITAMINS} alt="" />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tristique, quam a iaculis vulputate, nisi justo euismod dolor, ut auctor dolor est in lorem.</p>
                    </li>
                    <li className="list-group-item">
                        <img className="float-start" src={CASEIN} alt="" />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tristique, quam a iaculis vulputate, nisi justo euismod dolor, ut auctor dolor est in lorem.</p>
                    </li>
                    <li className="list-group-item">
                        <img className="float-start" src={WITH_HIGH_BLOOD_PRESSURE} alt="" />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tristique, quam a iaculis vulputate, nisi justo euismod dolor, ut auctor dolor est in lorem.</p>
                    </li>
                    <li className="list-group-item">
                        <img className="float-start" src={WITH_GASTRITIS} alt="" />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tristique, quam a iaculis vulputate, nisi justo euismod dolor, ut auctor dolor est in lorem.</p>
                    </li>
                    <li className="list-group-item">
                        <img className="float-start" src={IN_GOUT} alt="" />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tristique, quam a iaculis vulputate, nisi justo euismod dolor, ut auctor dolor est in lorem.</p>
                    </li>
                </ul>
            </Modal.Body>
        </Modal>
    )
}

export default TagInfo