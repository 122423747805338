import { API } from "../config";

export const menuCreate = async (body: {}) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';

    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/menu/create`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

export const getMenuList = async (page: number, limit: number, body = {}) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/menu?page=${page}&limit=${limit}`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

export const menuRequest = async (body = {}) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/menu/request-menu`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

export const getMenuRequestList = async () => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/menu/request-menu`, {
            headers: headers
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

export const getSingleRequest = async (id: string) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/menu/request-menu/${id}`, {
            headers: headers
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

export const assignMenuUser = async (body: {}) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/menu/request-menu`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

export const getSingleMenu = async (id: string) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/menu/${id}`, {
            headers: headers
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};


export const menuReject = async (body: {},id: string) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';

    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/menu/remove-item/${id}`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};
// menu accept or reject
export const menuOption = async (body: {},id: string) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';

    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/menu/request-menu/accept-reject/${id}`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};


export const getMenuUpdate = async (id:string,body: {}) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/menu/request-menu/add-item/${id}`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};