import React, { useState, useEffect } from 'react'
import AdminLayout from '../../Common/AdminLayout/AdminLayout'
import { Col, Container, Row, Modal } from 'react-bootstrap';
import { CreateDeliveryTime, deleteDeliveryTime, getDeliveryTime, getSingleDeliveryTime, updateDeliveryTimeApi } from '../../../api/deliverytime';
import { DeliveryInterface } from '../../../CommonInterface/CommonInterface';
import WarningModal from './../../Common/WarningModal/WarningModal';
import { showNotifications } from '../../../lib/toaster';
import { ToastContainer } from 'react-toastify';
import TimeTable from '../../Common/TimeTable/TimeTable';
import "./DeliveryTime.css";
import { convertTimeFormat } from '../../../lib/commonFunction';
import Loader from '../../Common/Loader/Loader';

const DeliveryTime = () => {

    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleLayoutStateChange = (clickedInParent: boolean) => {
        setIsCollapsed(!clickedInParent);
    };
    const [show, setShow] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const closeModal = () => setEditModal(false);
    const [count, setCount] = useState<number>(0);
    const [deliveryId, setDeliveryId] = useState<number>(0);
    const [updateId, setUpdateId] = useState<number>(0);
    const [deliveryTime, setDeliveryTime] = useState<string>("BREAKFAST");
    const [startHour, setStartHour] = useState('01');
    const [startMinute, setStartMinute] = useState('00');
    const [endHour, setEndHour] = useState('01');
    const [endMinute, setEndMinute] = useState('00');
    const [startTime, setStartTime] = useState<string>(`${startHour}:${startMinute}`);
    const [endTime, setEndTime] = useState<string>(`${endHour}:${endMinute}`);
    const [editDeliveryTime, setEditDeliveryTime] = useState<string>("");
    const [editStartTime, setEditStartTime] = useState<string>("");
    const [editEndTime, setEditEndTime] = useState<string>("");
    const [lunchList, setLunchList] = useState<DeliveryInterface[]>([]);
    const [breakfastList, setBreakfastList] = useState<DeliveryInterface[]>([]);






    useEffect(() => {
        getDeliveryTime("LUNCH").then((data) => {
            setLunchList(data.data.deliveryTimes)
        });
        getDeliveryTime("BREAKFAST").then((data) => {
            setBreakfastList(data.data.deliveryTimes)
        });
        setStartTime(`${startHour}:${startMinute}`);
        setEndTime(`${endHour}:${endMinute}`);
    }, [count, startHour, startMinute, endHour, endMinute])

    const deliveryTimeAdd = () => {
        let delivery = {
            "deliveryTime": deliveryTime,
            "startTime": startTime,
            "endTime": endTime
        }
        CreateDeliveryTime(delivery).then((data) => {
            if (data.statusCode !== 201) {
                setCount(count + 1);
                return showNotifications('error', data.message);
            }
            else {
                setCount(count + 1);
                setStartHour('01');
                setStartMinute('00');
                setEndHour('01');
                setEndMinute('00');
                return showNotifications('success', data.message);
            }

        })
    }



    // delete food
    const handleCloseModal = () => {
        setShow(false);
    }
    const deleteFood = (deliveryTimesID: number) => {
        setShow(true);
        setDeliveryId(deliveryTimesID);
    }
    const handleDeleteFood = () => {
        setShow(false);
        deleteDeliveryTime(deliveryId).then((data) => {
            setCount(count + 1);
            return showNotifications('success', data.message);
        })
    };
    // edit food
    const editFood = (foodId: number) => {
        setEditModal(true);
        setUpdateId(foodId);
        getSingleDeliveryTime(foodId).then((data) => {
            const convertDeliveryTime = convertTimeFormat(data.data.deliveryTime);
            setEditDeliveryTime(convertDeliveryTime.deliveryTime);
            setEditStartTime(convertDeliveryTime.startTime);
            setEditEndTime(convertDeliveryTime.endTime);
        })
    }
    const updateDeliveryTime = () => {
        let delivery = {
            "deliveryTime": editDeliveryTime,
            "startTime": editStartTime,
            "endTime": editEndTime
        }
        updateDeliveryTimeApi(delivery, updateId).then((data) => {
            if (data.statusCode !== 200) {
                setCount(count + 1);
                return showNotifications('error', data.message);
            }
            else {
                setCount(count + 1);
                return showNotifications('success', data.message);
            }
        })
        setEditModal(false)
    }


    // Generate options for hours (00 to 23)
    const hourOptions = Array.from({ length: 24 }, (_, i) => {
        const hour = (i + 1).toString().padStart(2, '0'); // Zero-padding
        return <option key={hour} value={hour}>{hour}</option>;
    });

    // Generate options for minutes (00 to 59)
    const minuteOptions = Array.from({ length: 12 }, (_, i) => {
        const minute = (i * 5).toString().padStart(2, '0'); // Zero-padding
        return <option key={minute} value={minute}>{minute}</option>;
    });
    // Handle hour selection change
    const handleHourChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setStartHour(e.target.value);
    };

    // Handle minute selection change
    const handleMinuteChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setStartMinute(e.target.value);
    };
    return (
        <AdminLayout onLayoutStateChange={handleLayoutStateChange}>
            <section className={`main-body ${isCollapsed ? '' : 'bodyCollapsed'}`}>
                <ToastContainer />
                <Container>
                    <Row>
                        <Col md={3}>
                            <label htmlFor="exampleSelect">Select Delivery Time</label>
                            <select
                                className="form-select"
                                name="deliveryTime"
                                id="deliveryTime"
                                value={deliveryTime}
                                onChange={(e) => setDeliveryTime(e.target.value)}
                            >
                                <option value="BREAKFAST">Breakfast</option>
                                <option value="LUNCH">Lunch</option>
                            </select>
                        </Col>
                        <Col md={3}>
                            <label htmlFor="exampleSelect">Start Time</label>
                            <div className='d-flex align-items-center'>
                                <select className="form-select" value={startHour} onChange={handleHourChange}>
                                    {hourOptions}
                                </select>
                                :
                                <select className="form-select" value={startMinute} onChange={handleMinuteChange}>
                                    {minuteOptions}
                                </select>
                            </div>
                        </Col>
                        <Col md={3}>
                            <label htmlFor="exampleSelect">End Time</label>
                            <div className='d-flex align-items-center'>
                                <select className="form-select" value={endHour} onChange={(e) => setEndHour(e.target.value)}>
                                    {hourOptions}
                                </select>
                                :
                                <select className="form-select" value={endMinute} onChange={(e) => setEndMinute(e.target.value)}>
                                    {minuteOptions}
                                </select>
                            </div>
                        </Col>
                        <Col md={3} className='d-flex align-items-end'>
                            <button type='submit' onClick={deliveryTimeAdd} className='btn btn-primary'>Submit</button>
                        </Col>

                        {lunchList && lunchList.length === 0 ? <Loader></Loader> :
                            <>
                                <Col md={6} className='mt-5'>
                                    <h1 className='deliveryHeading'>Lunch</h1>
                                    <TimeTable listTable={lunchList} editTime={editFood} deleteTime={deleteFood} />
                                </Col>
                            </>}

                        {breakfastList && breakfastList.length === 0 ? <Loader></Loader> :
                            <>
                                <Col md={6} className='mt-5'>
                                <h1 className='deliveryHeading'>Breakfast</h1>
                                    <TimeTable listTable={breakfastList} editTime={editFood} deleteTime={deleteFood} />
                                </Col>
                            </>}


                    </Row>
                </Container>
            </section>
            <WarningModal show={show} onClose={handleCloseModal} deleteFunction={handleDeleteFood} />
            <Modal show={editModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Update information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col md={4}>
                                <label htmlFor="exampleSelect">Delivery Time</label>
                                <select
                                    className="form-select"
                                    name="deliveryTime"
                                    id="deliveryTime"
                                    value={editDeliveryTime}
                                    onChange={(e) => setEditDeliveryTime(e.target.value)}
                                >
                                    <option value="BREAKFAST">Breakfast</option>
                                    <option value="LUNCH">Lunch</option>
                                </select>
                            </Col>
                            <Col md={4}>
                                <label htmlFor="exampleSelect">Start Time</label>
                                <select
                                    className="form-select"
                                    name="deliveryTime"
                                    id="deliveryTime"
                                    value={editStartTime}
                                    onChange={(e) => setEditStartTime(e.target.value)}
                                >
                                    <option value="07:30">07:30</option>
                                    <option value="08:30">08:30</option>
                                    <option value="09:30">09:30</option>
                                </select>
                            </Col>
                            <Col md={4}>
                                <label htmlFor="exampleSelect">End Time</label>
                                <select
                                    className="form-select"
                                    name="deliveryTime"
                                    id="deliveryTime"
                                    value={editEndTime}
                                    onChange={(e) => setEditEndTime(e.target.value)}
                                >
                                    <option value="07:30">07:30</option>
                                    <option value="08:30">08:30</option>
                                    <option value="09:30">09:30</option>
                                </select>
                            </Col>
                            <Col md={12} className='d-flex align-items-center justify-content-center mt-3'>
                                <button type='submit' onClick={updateDeliveryTime} className='btn btn-success'>Update</button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </AdminLayout>
    )
}

export default DeliveryTime