import React, { ReactNode, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

interface Props {
    children?: ReactNode
}
const Layout = ({ children, ...props }: Props) => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Smooth scrolling animation (optional)
          });
      }, []);
    return (
        <>
           <Header/>
            <div {...props}>{children}</div>
            <Footer/>
        </>
    )
}

export default Layout