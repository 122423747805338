import { API } from "../config";

// all food list
export const getFoodList = async (page: number, limit: number, body = {}, categoryWiseData: boolean = false) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/foods?page=${page}&limit=${limit}&categoryWiseData=${categoryWiseData}`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

// single food info
export const getSingleFood = async (foodId: number) => {
    try {
        const response = await fetch(`${API}/foods/${foodId}`);
        return await response.json();
    } catch (error) {
        return error;
    }
};

// single food image
export const getFoodImage = async (foodId: number) => {
    try {
        const response = await fetch(`${API}/foods/image/${foodId}`);
        return await response.json();
    } catch (error) {
        return error;
    }
};

// Toggle Featured Food
export const toggleFeature = async (featured: boolean, foodId: number) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/foods/feature-food/${foodId}`, {
            method: 'PATCH',
            headers: headers,
            body: JSON.stringify({ "featured": featured })
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};