import { API } from "../config";

// single food details
export const getSingleFood = async (foodId: number) => {
    try {
        const response = await fetch(`${API}/foods/${foodId}`);
        const data = await response.json();
        return data;
    } catch (error) {
        return error;
    }
};

// delete food item
export const deleteFoodItem = async (foodId: number) => {
    try {
        const response = await fetch(`${API}/foods/${foodId}`, {
            method: 'DELETE'
        });
        const data = await response.json();
        return data;
    } catch (error) {
        return error;
    }
};

// add food
export const addFood = async (body: any) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    
    try {
        const response = await fetch(`${API}/foods/create`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
            },
            body: body,
        });
        const data = await response.json();
        return data;
    } catch (error) {
        return error;
    }
};

// update food
export const updateFood = async (body: any, foodId: number) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    
    try {
        const response = await fetch(`${API}/foods/${foodId}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
            },
            body: body,
        });
        const data = await response.json();
        return data;
    } catch (error) {
        return error;
    }
};

// update food status
export const updateFoodStatus = async (body: any, foodId: number) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    
    try {
        const response = await fetch(`${API}/foods/${foodId}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
        const data = await response.json();
        return data;
    } catch (error) {
        return error;
    }
};