import React, { useState, useEffect } from 'react'
import AdminLayout from '../../Common/AdminLayout/AdminLayout'
import { Col, Container, Row, Table } from 'react-bootstrap';
import { DeliveryInterface, FoodInterface } from '../../../CommonInterface/CommonInterface';
import "./MenuSet.css"
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import { getFoodList } from '../../../api/foods';
import { getMenuList } from '../../../api/menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../Common/Loader/Loader';
import { showNotifications } from '../../../lib/toaster';
import { ToastContainer } from 'react-toastify';


const MenuSet = () => {
    const navigate = useNavigate();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleLayoutStateChange = (clickedInParent: boolean) => {
        setIsCollapsed(!clickedInParent);
    };

    const [itemList, setItemList] = useState<FoodInterface[] | undefined>([]);
    const [limit, setLimit] = useState<number>(10);
    const [prevButton, setPrevButton] = useState<boolean>(false);
    const [nextButton, setNextButton] = useState<boolean>(false);
    const [noFoods, setNoFoods] = useState<boolean>(false);
    const [pageValue, setPageValue] = useState<number>();
    const [totalValue, setTotalValue] = useState<number>(0);
    const [page, setPage] = useState(1);
    const pageCount = Math.ceil(totalValue / 10);
    const [name, setName] = useState("");
    const [charge, setCharge] = useState("");
    const [foodTitle, setFoodTitle] = useState<string>("");
    const [menuList, setMenuList] = useState<any[] | undefined>(undefined);
    const [days, setDays] = useState("");


    const menuCreate = () => {

        if(name.length && charge.length && days.length  ){
            let creteMenu = {
                "name": name,
                "charge": charge,
                "daysOfMenu": days,
            }
            const updatedDataString = JSON.stringify(creteMenu);
            localStorage.setItem('menu', updatedDataString);
            return navigate(`/create-menu`);
        }else{
            showNotifications("error", "Please select all. name, charge and days");
        }

    };



    useEffect(() => {
        let body = { "tags": [""], "featured": null, "title": foodTitle }
        getFoodList(page, limit, body).then((data) => {
            if (data.data.foods.length === 0) {
                setNoFoods(true);
            }
            else {
                setNoFoods(false);
                setItemList(data.data.foods);
                setPageValue(data.data.currentPage);
                setTotalValue(data.data.total);
            }

        })
        if (pageCount > 1) {
            setPrevButton(true)
        }
        if (page === 1) {
            setPrevButton(false)
        }
        // next button
        if (pageCount > 1) {
            setNextButton(true)
        }
        if (pageCount === page) {
            setNextButton(false)
        }

    }, [foodTitle])


    useEffect(() => {
        let body = { "name": "" }
        getMenuList(1, 20, body).then((data) => {
            if (data.statusCode !== 200) {
                console.log('no data');
            }
            else {
                setMenuList(data.data.menus)
            }
        })
    }, [])




    const menuView = (id: string) => {
        return navigate(`/menu/${id}`);
    };


    return (
        <>
            <AdminLayout onLayoutStateChange={handleLayoutStateChange}>
                <section className={`main-body ${isCollapsed ? '' : 'bodyCollapsed'}`}>
                    <Container>
                    <ToastContainer />
                        <Row className='mt-2'>
                            <Col md={12}>
                                <h1 className='adminHeading text-center'>Create Menu</h1>
                            </Col>
                            <Col md={12} className='createForm'>
                                <div>
                                    <label>Name</label>
                                    <input type='text' onChange={(e) => setName(e.target.value)} className='form-control' placeholder='Name' />
                                </div>
                                <div>
                                    <label>Charge</label>
                                    <input type='number' onChange={(e) => setCharge(e.target.value)} className='form-control' placeholder='Charge' />
                                </div>
                                <div>
                                    <label>Number of days</label>
                                    <input type='number' onChange={(e) => setDays(e.target.value)} className='form-control' placeholder='Days' />
                                </div>
                                <button className='menu btn' onClick={menuCreate}>Create Menu</button>
                            </Col>
                            {menuList && menuList.length >= 0 ?
                                <Col md={12} className='mt-3'>
                                    <h5>Menu List</h5>
                                    <Table responsive bordered hover className='align-items-center'>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Charge</th>
                                                <th>Number of days</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {menuList && menuList.map((data, i) => <tr className='foodListTable' key={`menuList` + i}>
                                                <td>{i + 1}</td>
                                                <td>{data.name}</td>
                                                <td>{data.charge}</td>
                                                <td>{data.daysOfMenu}</td>
                                                <td><button className='btn btn-success' onClick={() => menuView(data.menuId)}><FontAwesomeIcon icon={faEye} /></button></td>
                                            </tr>)}
                                        </tbody>
                                    </Table>
                                </Col>
                                : <Loader />}

                        </Row>
                    </Container>
                </section>
                {/* calender */}

            </AdminLayout>
        </>
    )
}

export default MenuSet