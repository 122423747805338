import React from 'react';
import { Modal } from 'react-bootstrap';
import "./WarningModal.css"

interface ModalProps {
  show: boolean;
  onClose: () => void;
  deleteFunction: () => void;
}
const WarningModal = ({ show, onClose,deleteFunction }: ModalProps) => {

  return (
    <>
      <Modal show={show} onHide={onClose}>
        <Modal.Body>
          <div className="warningHeading">
            <h1>Are you sure?</h1>
          </div>
          <div className='modalAction'>
            <button className='btn noBtn' onClick={onClose}>
              No
            </button>
            <button className='btn deleteBtn' onClick={deleteFunction}>
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default WarningModal