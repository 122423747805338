import React, { useState, useEffect } from 'react';
import { Container, Navbar, Nav, Button } from 'react-bootstrap';
import logo from "../../../assets/image/logo.png";
import "./Header.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBagShopping, faPhone, faUserAlt, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { isAuthenticate, logout } from '../../../api/auth';

const Header = () => {
  const navigate = useNavigate();
  const [loginNavbar, setLoginNavbar] = useState<boolean>(false);
  const { jwt } = isAuthenticate();
  
  useEffect(() => {
    if (jwt) {
      setLoginNavbar(true);
    }
  }, [])


  const logoutUser = () => {
    localStorage.removeItem("foodjwt");
    setLoginNavbar(false);
    logout();
    return navigate(`/`);
  }

  return (
    <>
      <Navbar collapseOnSelect expand="lg" sticky="top" className="headerNavbar">
         <Container> 
          <Link to="/" className='navbar-brand'>
            <img src={logo} alt="logo" />
          </Link>
          <Nav.Link  className="d-lg-none" href="tel:0888301811"><FontAwesomeIcon icon={faPhone} /> Call</Nav.Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
            </Nav>
            <Nav>
              <NavLink to="/menu" className={({ isActive }) => isActive ? 'nav-link activeNavbar' : 'nav-link'}><FontAwesomeIcon icon={faUtensils} /> Menu</NavLink>
              <NavLink to="/cart" className={({ isActive }) => isActive ? 'nav-link activeNavbar' : 'nav-link'}><FontAwesomeIcon icon={faBagShopping} /> Cart</NavLink>
              {loginNavbar && loginNavbar === true ? <>
                <NavLink to="/profile" className={({ isActive }) => isActive ? 'nav-link activeNavbar' : 'nav-link'}><FontAwesomeIcon icon={faUserAlt} /> Profile</NavLink>
                <Button className="button-white-outline nav-link" onClick={() => logoutUser()}>Logout</Button>
              </> : <>
                <Button className="button-white-outline"><Link to="/auth" className='nav-link'>Login</Link></Button>
              </>}
              <Nav.Link href="tel:0888301811"><FontAwesomeIcon icon={faPhone} /> 0888301811</Nav.Link>
            </Nav>
          </Navbar.Collapse>

         </Container> 
      </Navbar>
    </>
  )
}

export default Header