import React, { useEffect, useState } from 'react';
import Layout from '../Common/Layout/Layout';
import { Container } from 'react-bootstrap';
import "./Checkout.css";
import { getSingleOrder } from '../../api/order';
import { useParams } from 'react-router-dom';

const Checkout = () => {
    const [orderInfo, setOrderInfo] = useState<any>();
    const { id }: any = useParams();
    useEffect(() => {
        getSingleOrder(id).then((data) => {
            if (data.statusCode !== 201) {

            }
            else {
                setOrderInfo(data.data.order);
            }
        })
    }, []);
    return (
        <Layout>
            <section className="checkoutSection">
                <Container>
                    <div className="py-5 text-center">
                        <h2>Checkout form</h2>
                    </div>
                    <div className="row g-5">
                        <div className="col-md-5 col-lg-4 order-md-last checkoutCart">
                            <h4 className="d-flex justify-content-between align-items-center mb-3">
                                <span className="text-cart">Your cart</span>
                                <span className="badge bg-badge rounded-pill">{orderInfo && orderInfo.cartItem.length}</span>
                            </h4>
                            <ul className="list-group mb-3">
                                {orderInfo && orderInfo.cartItem.map((data: any, i: number) => <li key={'cart' + i} className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">{data.food.title}</h6>
                                        <small className="text-muted">{data.deliveryTime}</small>
                                    </div>
                                    <span className="text-muted">{data.price}</span>
                                </li>)}
                            </ul>

                            <form className="card p-2">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Promo code" />
                                    <button type="submit" className="btn btn-danger">Redeem</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-7 col-lg-8">
                            <h4 className="mb-3">Billing address</h4>
                            <form className="needs-validation">
                                <div className="row g-3">
                                    <div className="col-sm-6">
                                        <label htmlFor="firstName" className="form-label">First name</label>
                                        <input type="text" className="form-control" id="firstName" value={orderInfo && orderInfo.user.firstName} />
                                        <div className="invalid-feedback">
                                            Valid first name is required.
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <label htmlFor="lastName" className="form-label">Last name</label>
                                        <input type="text" className="form-control" id="lastName" value={orderInfo && orderInfo.user.lastName} />
                                        <div className="invalid-feedback">
                                            Valid last name is required.
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="email" className="form-label">Email <span className="text-muted">(Optional)</span></label>
                                        <input type="email" className="form-control" id="email" value={orderInfo && orderInfo.user.email} />
                                        <div className="invalid-feedback">
                                            Please enter a valid email address for shipping updates.
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="address" className="form-label">Address</label>
                                        <textarea className="form-control" id="address" value={orderInfo && orderInfo.user.address} />
                                    </div>
                                </div>

                                <h4 className="mb-3">Payment</h4>
                                <div className="my-3 d-flex paymentOptions">
                                    <div className="form-check">
                                        <input id="credit" name="paymentMethod" type="radio" className="form-check-input" required />
                                        <label className="form-check-label" htmlFor="credit">Credit card</label>
                                    </div>
                                    <div className="form-check">
                                        <input id="debit" name="paymentMethod" type="radio" className="form-check-input" required />
                                        <label className="form-check-label" htmlFor="debit">Debit card</label>
                                    </div>
                                    <div className="form-check">
                                        <input id="paypal" name="paymentMethod" type="radio" className="form-check-input" required />
                                        <label className="form-check-label" htmlFor="paypal">Paytm</label>
                                    </div>
                                    <div className="form-check">
                                        <input id="paypal" name="paymentMethod" type="radio" className="form-check-input" required />
                                        <label className="form-check-label" htmlFor="paypal">Phonepe</label>
                                    </div>
                                </div>

                                <div className="row gy-3">
                                    <div className="col-md-6">
                                        <label htmlFor="cc-name" className="form-label">Name on card</label>
                                        <input type="text" className="form-control" id="cc-name" placeholder="" required />
                                        <small className="text-muted">Full name as displayed on card</small>
                                        <div className="invalid-feedback">
                                            Name on card is required
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="cc-number" className="form-label">Credit card number</label>
                                        <input type="text" className="form-control" id="cc-number" placeholder="" required />
                                        <div className="invalid-feedback">
                                            Credit card number is required
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <label htmlFor="cc-expiration" className="form-label">Expiration</label>
                                        <input type="text" className="form-control" id="cc-expiration" placeholder="" required />
                                        <div className="invalid-feedback">
                                            Expiration date required
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <label htmlFor="cc-cvv" className="form-label">CVV</label>
                                        <input type="text" className="form-control" id="cc-cvv" placeholder="" required />
                                        <div className="invalid-feedback">
                                            Security code required
                                        </div>
                                    </div>
                                </div>

                                <button className="w-100 btn btn-danger btn-lg mt-3" type="submit">Continue to checkout</button>
                            </form>
                        </div>
                    </div>
                </Container>
            </section>
        </Layout>
    )
}

export default Checkout