import React, { useState, useEffect } from 'react';
import AdminLayout from '../../Common/AdminLayout/AdminLayout';
import { Col, Container, Row } from 'react-bootstrap';
import { UserInterface } from '../../../CommonInterface/CommonInterface';
import { getUserProfile, updateUser } from '../../../api/user';
import "./AdminProfile.css";
import UpdateProfileModal from '../../Common/UpdateProfileModal/UpdateProfileModal';

const AdminProfile = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [userInfo, setUserInfo] = useState<UserInterface>();
    const handleLayoutStateChange = (clickedInParent: boolean) => {
        setIsCollapsed(!clickedInParent);
    };
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [address, setAddress] = useState("");
    // profile
    useEffect(() => {
        getUserProfile().then((data) => {
            setUserInfo(data.user);
            setFirstName(data.user.firstName);
            setLastName(data.user.lastName);
            setAddress(data.user.address);
        })
    }, []);

    // edit profile
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('firstName', firstName);
        formDataToSend.append('lastName', lastName);
        formDataToSend.append('address', address);
        updateUser(formDataToSend).then((data) => {
            
        })
    }
    return (
        <AdminLayout onLayoutStateChange={handleLayoutStateChange}>
            <section className={`main-body ${isCollapsed ? '' : 'bodyCollapsed'}`}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className='profileBox'>
                                <div className="card">
                                    <div className="card-header"></div>
                                    <div className="card-body">
                                        <div className='cardRole'><p>{userInfo && userInfo.userRole}</p></div>
                                        <div className="inner">
                                            <div className='userName'>{userInfo && userInfo.firstName} {userInfo && userInfo.lastName}</div>
                                            <div className="color__gray" style={{ fontSize: '19px', letterSpacing: '.5px' }}>{userInfo && userInfo.email}</div>
                                            <div className="color__gray" style={{ fontSize: '17px', letterSpacing: '.5px' }}>{userInfo && userInfo.address}</div>
                                            <div><button onClick={handleShow} className='btn btn-info mt-2'>Edit profile</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
          <UpdateProfileModal firstName={firstName} setFirstName={setFirstName} lastName={lastName} setLastName={setLastName} address={address} setAddress={setAddress} show={show} handleClose={handleClose} handleSubmit={handleSubmit} />
        </AdminLayout>
    )
}

export default AdminProfile