import React, { useEffect, useState } from 'react';
import "./Card.css";
import { FoodAllInterface } from '../../../CommonInterface/CommonInterface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faInfo } from '@fortawesome/free-solid-svg-icons';
import { Table, Modal } from 'react-bootstrap';
import IconImage from './IconImage';
import { addActiveStatusToDeliveries } from '../../../lib/commonFunction';
import FoodImage from '../FoodImage/FoodImage';

interface FoodProps {
    data: FoodAllInterface;
    setParentState?: any;
    parentState?: number;
    styleClass: string;
}

const Card = ({ data, setParentState, parentState, styleClass }: FoodProps) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [desCollapsed, setDesCollapsed] = useState(true);
    const [foodId, setFoodId] = useState<number>();
    const [quantity, setQuantity] = useState<number>();
    const [price, setPrice] = useState<number>();
    const [weight, setWeight] = useState<number>();
    const [foodTitle, setFoodTitle] = useState<string>("");
    const [orderList, setOrderList] = useState<any>([]);
    const [updateArray, setUpdateArray] = useState<any>([]);
    const [count, setCount] = useState<number>(0);
    const [itemsPerPage, setItemsPerPage] = useState<number>(21);
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState<number>(window.innerHeight);
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Update screen size when the window is resized
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
        setScreenHeight(window.innerHeight);
    };

    const handleShow = (foodTitle: string, foodId: number, quantity: number, price: number, weight: number) => {
        setFoodTitle(foodTitle);
        setFoodId(foodId);
        setQuantity(quantity);
        setPrice(price);
        setWeight(weight);
        setShow(true);
        setCount(count + 1);
    }
    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    const descriptionCollapse = () => {
        setDesCollapsed(!desCollapsed);
    };
    // 60 days function
    function getmonthDays() {
        const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const startOfWeek = new Date(today);
    const diff = today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1);
    startOfWeek.setDate(diff);
    
    // Check if tomorrow is Monday
    if (tomorrow.getDay() === 1) {
        startOfWeek.setDate(startOfWeek.getDate() + 7); // Skip the first week
    }
    
    const dates = [];
    const options:any = { weekday: 'short', month: 'short', day: 'numeric' };
    for (let i = 0; i < 63; i++) {
        const nextDay = new Date(startOfWeek); // Start from the beginning of the week
        nextDay.setDate(startOfWeek.getDate() + i);
        const formattedDate = nextDay.toLocaleDateString('en-US', options);
        const year = nextDay.getFullYear();
        const month = String(nextDay.getMonth() + 1).padStart(2, '0');
        const day = String(nextDay.getDate()).padStart(2, '0');
        const isoFormattedDate = `${year}-${month}-${day}`;
        dates.push({
            formattedDate: formattedDate,
            isoFormattedDate: isoFormattedDate,
            breakfast: "BREAKFAST",
            lunch: "LUNCH"
        });
    }
    return dates;
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                const datesWithFormats = getmonthDays();
                const storedItems = localStorage.getItem('orderList');
                if (storedItems) {
                    const jsonArray = JSON.parse(storedItems);
                    const resultDeliveries = await addActiveStatusToDeliveries(datesWithFormats, jsonArray);
                    setUpdateArray(resultDeliveries);
                } else {
                    const resultDeliveries = await addActiveStatusToDeliveries(datesWithFormats, []);
                    setUpdateArray(resultDeliveries);
                }
            } catch (error) {
                // Handle errors if necessary
            }
        };
        fetchData();
        if (screenWidth < 778) {
            setItemsPerPage(7)
        }
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [count])


    // add order
    const addOrder = (addPrice: number | undefined, addQuantity: number | undefined, addFoodId: number | undefined, addWeight: number | undefined, time: string, foodItem: string, days: string) => {
        setCount(count + 1);
        setParentState((parentState: any) => parentState + 1);
        let newOrder = {
            "foodTitle": foodTitle,
            "price": addPrice,
            "quantity": addQuantity,
            "foodId": addFoodId,
            "weight": addWeight,
            "deliveryDate": time,
            "days": days,
            "deliveryTime": foodItem
        }
        const storedItems = localStorage.getItem('orderList');
        if (storedItems !== null) {
            const jsonArray = JSON.parse(storedItems);
            localStorage.setItem('orderList', JSON.stringify([...jsonArray, newOrder]));
            setOrderList((orderList: any) => [...orderList, newOrder]);
        } else {
            setOrderList((orderList: any) => [...orderList, newOrder]);
            localStorage.setItem('orderList', JSON.stringify([...orderList, newOrder]));
        }
    }


    const daysList = Array.from({ length: 60 }, (_, index) => `Day ${index + 1}`);
    // Function to handle previous page
    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Function to handle next page
    const handleNextPage = () => {
        if (endIndex < updateArray.length) {
            setCurrentPage(currentPage + 1);
        }
    };



    return (
        <>

            <div className={`${styleClass}`}>
                {!isCollapsed && (
                    <div className="box-content">
                        <h1 className='heading'>{data && data.title}</h1>
                        <p className='subHeading'>{data && data.foodType === "VEG" ? "Vegetarian" : "Non-Vegetarian"}</p>
                        <div className="foodHeath">
                            <Table responsive borderless>
                                <thead>
                                    <tr>
                                        <th>Allergens</th>
                                        <th>{data && data.allergens}</th>
                                    </tr>
                                    <tr>
                                        <th>Fats</th>
                                        <th>{data && data.fats}</th>
                                    </tr>
                                    <tr>
                                        <th>Calories</th>
                                        <th>{data && data.calories}</th>
                                    </tr>
                                    <tr>
                                        <th>Proteins</th>
                                        <th>{data && data.proteins}</th>
                                    </tr>
                                    <tr>
                                        <th>Carbohydrates</th>
                                        <th>{data && data.carbohydrates}</th>
                                    </tr>
                                    <tr>
                                        <th>Vitamines</th>
                                        <th>{data && data.vitamines}</th>
                                    </tr>
                                    <tr>
                                        <th>Minerals</th>
                                        <th>{data && data.minerals}</th>
                                    </tr>
                                </thead>
                            </Table>
                        </div>
                        <IconImage tags={data.tags} />
                        <div className="closeTopBox">
                            <button onClick={toggleCollapse}><FontAwesomeIcon icon={faClose} /></button>
                        </div>
                    </div>
                )}
                {!desCollapsed && (
                    <div className="box-content">
                        <p className='subHeading'>{data && data.description}</p>
                        <div className="closeTopBox">
                            <button onClick={descriptionCollapse}><FontAwesomeIcon icon={faClose} /></button>
                        </div>
                    </div>
                )}
                <div className="menu_item_img">
                    {data && data.foodId ? (
                        <FoodImage foodId={data.foodId} title={data.title} imageSize="w-100" />
                    ) : (
                        <p>No photo</p>
                    )}
                </div>
                <div className="menu_item_text">
                    <div className="iButton">
                        <button onClick={toggleCollapse}><FontAwesomeIcon icon={faInfo} /></button>
                    </div>
                    <h1 className="title">{data && data.title.length > 35 ? data.title.slice(0, 35) + "..." : data.title}</h1>
                    <p>
                        {data && data.description && data.description.length > 40 ? (
                            <>
                                {data.description.slice(0, 40)}...
                                <span onClick={descriptionCollapse}>
                                    view more
                                </span>
                            </>
                        ) : (
                            data.description
                        )}
                    </p>
                </div>
                <IconImage tags={data.tags} />
                <div className="priceList">
                    {data && parseFloat(String(data.priceSmall)) === 0.00 ? "" :
                        <button onClick={() => handleShow(data.title, data.foodId, 1, data && data.discount > 0 ? data.priceSmall - data.discount : data.priceSmall, data.wightSmall)}>
                            {data && data.discount > 0 ? <>
                                <span className='text-danger'>
                                    <del style={{ fontSize: '14px' }}>{data && data.priceSmall}</del> </span>
                                <span className='discountPrice'>{data && parseFloat((data.priceSmall - data.discount).toFixed(2))}</span></>
                                : <>{data && data.priceSmall}</>}<span className='moneyIcon'>лв</span>
                            <div className="dividedLine"></div>
                            {data && data.wightSmall}<span className='moneyIcon'>g</span></button>
                    }
                    {data && parseFloat(String(data.priceLarge)) === 0.00 ? "" :
                        <button onClick={() => handleShow(data.title, data.foodId, 1, data && data.discountBig > 0 ? data.priceLarge - data.discountBig : data.priceLarge, data.weightLarge)}>
                            {data && data.discountBig > 0 ? <>
                                <span className='text-danger'>
                                    <del style={{ fontSize: '14px' }}>{data && data.priceLarge}</del> </span>
                                <span className='discountPrice'>{data && parseFloat((data.priceLarge - data.discountBig).toFixed(2))}</span></>
                                : <>{data && data.priceLarge}</>}<span className='moneyIcon'>лв</span>
                            <div className="dividedLine"></div>
                            {data && data.weightLarge}<span className='moneyIcon'>g</span></button>
                    }
                </div>
            </div>
            {/* calender */}

            <Modal show={show} onHide={handleClose} centered scrollable size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Schedule food</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="calenderSchedule">
                        {updateArray.slice(startIndex, endIndex).map((dateInfo: any, index: number) =>
                            <div className="timeBox" key={`cardTime` + index}>
                                <div className="scheduleDays">{dateInfo.formattedDate}</div>
                                <div className="scheduleFood">
                                    <button className={dateInfo.breakfastActive === true ? 'activeBreakfast' : 'disableBtn'} onClick={() => addOrder(price, quantity, foodId, weight, dateInfo.isoFormattedDate, "BREAKFAST", dateInfo.formattedDate)}>{dateInfo.breakfast} {dateInfo.breakfastActive === true ? <><span className="tooltiptext">{dateInfo.breakfastFoodTitles && dateInfo.breakfastFoodTitles.join(', ')}</span></> : <></>}</button>
                                    <button className={dateInfo.lunchActive === true ? 'activeBreakfast' : 'disableBtn'} onClick={() => addOrder(price, quantity, foodId, weight, dateInfo.isoFormattedDate, "LUNCH", dateInfo.formattedDate)}>{dateInfo.lunch} {dateInfo.lunchActive === true ? <><span className="tooltiptext">{dateInfo.lunchFoodTitles && dateInfo.lunchFoodTitles.join(', ')}</span></> : <></>}</button>
                                </div>
                            </div>
                        )}
                        <div className='paginationBtn' style={{ display: "block", width: "100%", textAlign: "center" }}>
                            <button onClick={handlePrevPage} disabled={currentPage === 1}>
                                Previous
                            </button>
                            <span>Page {currentPage}</span>
                            <button onClick={handleNextPage} disabled={endIndex >= daysList.length}>
                                Next
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Card