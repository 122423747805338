import React from 'react'
import { Container, Row, Col, Modal } from 'react-bootstrap'
interface ModalProps {
    show: boolean;
    handleSubmit: (event: React.FormEvent) => Promise<void>;
    handleClose: () => void;
    setFirstName: (value: string) => void;
    setLastName: (value: string) => void;
    setAddress: (value: string) => void;
    firstName: string;
    lastName: string;
    address: string;
}
const UpdateProfileModal = ({ setFirstName, setLastName, setAddress, firstName, lastName, address, show, handleSubmit, handleClose }: ModalProps) => {
    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Update profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={6}>
                                    <label>First name</label>
                                    <input className='form-control' onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                                </Col>
                                <Col md={6}>
                                    <label>Last name</label>
                                    <input className='form-control' onChange={(e) => setLastName(e.target.value)} value={lastName} />
                                </Col>
                                <Col md={12}>
                                    <label>Address</label>
                                    <input className='form-control' onChange={(e) => setAddress(e.target.value)} value={address} />
                                </Col>
                                <Col md={12} className='text-center mt-3 mb-2'>
                                    <button type='submit' className='btn btn-danger'>Submit</button>
                                </Col>
                            </Row>
                        </form>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-info' onClick={handleClose}>
                        Close
                    </button>
                    <button className='btn btn-info' onClick={handleClose}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default UpdateProfileModal