import React, { useEffect, useState } from 'react';
import Layout from '../Common/Layout/Layout';
import { Col, Container, Row } from 'react-bootstrap';
import "./FoodView.css";
import { useParams } from 'react-router'
import { getSingleFood } from '../../api/api';
import { FoodAllInterface } from '../../CommonInterface/CommonInterface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Buffer } from 'buffer';

const FoodView = () => {
  const { id }: any = useParams();

  const [foodInfo, setFoodInfo] = useState<FoodAllInterface | undefined>();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  useEffect(() => {
    getSingleFood(id).then((data) => {
      setFoodInfo(data.data.food);
    })
  }, []);
  // add to cart

  let [count, setCount] = useState(0);
  function incrementCount() {
    count = count + 1;
    setCount(count);
  }
  function decrementCount() {
    if (count > 0) {
      setCount(count - 1);
    }
  }


  return (
    <Layout>
      <section className='foodViewSection'>
        <Container>
          <Row className='d-flex justify-content-center align-items-center'>
            <Col md={8}>
              <Row className='foodView'>
                <Col md={5}>
                  <div className="leftImage">
                    {foodInfo && foodInfo.photo ? (
                      <img src={`data:image/jpeg;base64,${Buffer.from(foodInfo.photo).toString('base64')}`} alt={foodInfo.title} width="100%" height="auto" />
                    ) : (
                      <p>No photo available</p>
                    )}
                  </div>
                </Col>
                <Col md={7}>
                  <div className="rightFoodContent">
                    <div className='foodType'>{foodInfo && foodInfo.foodType === "VEG" ? "Vegetarian" : "Non-Vegetarian"}</div>
                    <h1>{foodInfo && foodInfo.title}</h1>

                      <p>{foodInfo && foodInfo.description}</p>
                      <div className='addToCart'>
                        <div className='valueIncrease'>
                          <button className='btn plusBtn' onClick={incrementCount}><FontAwesomeIcon icon={faPlus} /></button>
                          <p>{count}</p>
                          <button className='btn minusBtn' onClick={decrementCount}><FontAwesomeIcon icon={faMinus} /></button>
                        </div>
                        <button className='btn addBtn'>Add to Cart</button>
                      </div>
                      <p><b>Calories:</b> {foodInfo && foodInfo.calories} (kcal)</p>
                      <p><b>Carbohydrates:</b> {foodInfo && foodInfo.carbohydrates} (g)</p>
                    </div>
                </Col>
              </Row>
            </Col>

          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default FoodView