import React, { useEffect, useState } from "react";
import Layout from "../Common/Layout/Layout";
import { Col, Container, Dropdown, Form, Row } from "react-bootstrap";
import { addMenu, getMenuProfile, getUserProfile } from "../../api/user";
import "./UserProfile.css";
import {
  DeliveryInterface,
  MenuInterface,
  UserInterface,
  UserOrderInterface,
} from "./../../CommonInterface/CommonInterface";
import { getMyOrder } from "../../api/order";
import UserOrder from "../Common/UserOrder/UserOrder";
import Loader from "../Common/Loader/Loader";
import userImage from "../../assets/image/Userprofile.png";
import { useForm } from "react-hook-form";
import { showNotifications } from "../../lib/toaster";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { splitTimeRange } from "../../lib/commonFunction";
import { getDeliveryTime } from "../../api/deliverytime";
import { addDays, startOfWeek } from "date-fns";
// icon image
import ANTIOXIDANTS from "../../assets/icon/blueberry.png";
import CASEIN from "../../assets/icon/casein.png";
import RICH_IN_COLLAGEN from "../../assets/icon/collagen.png";
import GLUTEN_FREE from "../../assets/icon/gluten-free.png";
import IN_GOUT from "../../assets/icon/gout.png";
import WITH_HIGH_BLOOD_PRESSURE from "../../assets/icon/hypertension.png";
import NO_OXALATES from "../../assets/icon/kidney.png";
import WITH_GASTRITIS from "../../assets/icon/medical.png";
import N0_CRABS from "../../assets/icon/no-carbs.png";
import NO_FAT from "../../assets/icon/no-fat.png";
import HIGH_PROTEIN from "../../assets/icon/protein.png";
import VEGETARIAN from "../../assets/icon/salad.png";
import WITHOUT_SUGAR from "../../assets/icon/sugar-free.png";
import VEGAN from "../../assets/icon/vegan.png";
import VITAMINS from "../../assets/icon/vitamin.png";
import RICH_IN_GOOD_FATS from "../../assets/icon/RICH_IN_GOOD_FATS.png";
import { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB";
import EditProfile from "./EditProfile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const UserProfile = () => {
  const options = [
    { value: "ANTIOXIDANTS", label: "Antioxidants", icon: ANTIOXIDANTS },
    { value: "N0_CRABS", label: "No crabs", icon: N0_CRABS },
    { value: "GLUTEN_FREE", label: "Gluten Free", icon: GLUTEN_FREE },
    { value: "WITHOUT_SUGAR", label: "Without Sugar", icon: WITHOUT_SUGAR },
    { value: "NO_FAT", label: "No Fat", icon: NO_FAT },
    { value: "NO_OXALATES", label: "No Oxalates", icon: NO_OXALATES },
    { value: "NO_CHOLESTEROL", label: "No Cholesterol", icon: VEGETARIAN },
    { value: "GOOD_FAT", label: "Good Fat", icon: RICH_IN_GOOD_FATS },
    {
      value: "RICH_IN_COLLAGEN",
      label: "Rich in Collagen",
      icon: RICH_IN_COLLAGEN,
    },
    { value: "VEGAN", label: "Vegan", icon: VEGAN },
    { value: "VEGETARIAN", label: "Vegetarian", icon: VEGETARIAN },
    { value: "HIGH_PROTEIN", label: "High Protein", icon: HIGH_PROTEIN },
    { value: "VITAMINS", label: "Vitamins", icon: VITAMINS },
    { value: "CASEIN", label: "Casein", icon: CASEIN },
    {
      value: "WITH_HIGH_BLOOD_PRESSURE",
      label: "With High Blood Pressure",
      icon: WITH_HIGH_BLOOD_PRESSURE,
    },
    { value: "WITH_GASTRITIS", label: "With Gastritis", icon: WITH_GASTRITIS },
    { value: "IN_GOUT", label: "In Gout", icon: IN_GOUT },
  ];
  const [userInfo, setUserInfo] = useState<UserInterface>();
  const [menuInfo, setMenuInfo] = useState<any>();
  const [orderList, setOrderList] = useState<UserOrderInterface[]>([]);
  const [menuCart, setMenuCart] = useState<any>();
  const [breakStartTime, setBreakStartTime] = useState<string>("");
  const [breakEndTime, setBreakEndTime] = useState<string>("");
  const [lunchStartTime, setLunchStartTime] = useState<string>("");
  const [lunchEndTime, setLunchEndTime] = useState<string>("");
  const [prevButton, setPrevButton] = useState<boolean>(false);
  const [nextButton, setNextButton] = useState<boolean>(false);
  const [pageValue, setPageValue] = useState<number | null>();
  const [totalValue, setTotalValue] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const pageCount = Math.ceil(totalValue / 2);
  const [noFoods, setNoFoods] = useState<boolean>(false);
  const [menuShow, setMenuShow] = useState<boolean>(false);
  const [disLike, setDisLike] = useState<boolean>(false);
  const [updateProfile, setUpdateProfile] = useState<boolean>(false);
  const [lunchList, setLunchList] = useState<DeliveryInterface[]>([]);
  const [breakfastList, setBreakfastList] = useState<DeliveryInterface[]>([]);
  const { register, handleSubmit, setValue } = useForm();

  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [profileDate, setProfileDate] = useState<any>(null);
  const [allergens, setAllergens] = useState<string>("");
  const [purpose, setPurpose] = useState<string>("");
  const [medicalConditions, setMedicalConditions] = useState<string>("");
  const [disLikedProduct, setDisLikedProduct] = useState<string>("");
  const [consumeMeat, setConsumeMeat] = useState<string>("");
  const [importantTags, setImportantTags] = useState<any>([]);
  const [medicalCondition, setMedicalCondition] = useState<boolean>(false);
  const [allergy, setAllergy] = useState<boolean>(false);
  const [medical, setMedical] = useState<boolean>(false);
  const [selectedTime, setSelectedTime] = useState("");

  const tagOptions = [
    { value: "ANTIOXIDANTS", content: "Antioxidants" },
    { value: "N0_CRABS", content: "No crabs" },
    { value: "GLUTEN_FREE", content: "Gluten free" },
    { value: "WITHOUT_SUGAR", content: "Without sugar" },
    { value: "NO_FAT", content: "No fat" },
    { value: "NO_OXALATES", content: "No oxalates" },
    { value: "NO_CHOLESTEROL", content: "No cholesterol" },
    { value: "RICH_IN_COLLAGEN", content: "Rich in collagen" },
    { value: "VEGAN", content: "Vegan" },
    { value: "VEGETARIAN", content: "Vegetarian" },
    { value: "HIGH_PROTEIN", content: "High protein" },
    { value: "VITAMINS", content: "Vitamins" },
    { value: "CASEIN", content: "Casein" },
    { value: "WITH_HIGH_BLOOD_PRESSURE", content: "With high blood pressure" },
    { value: "WITH_GASTRITIS", content: "With gastritis" },
    { value: "IN_GOUT", content: "In gout" },
    { value: "RICH_IN_GOOD_FATS", content: "Rich In Good Fats" },
    { value: "GOOD_FAT", content: "Good Fat" },
  ];


  function formatDate(date: any) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  const handleSelect = (date: Date) => {
    const currentDate = new Date();
    const minAllowedDate = new Date();
    minAllowedDate.setDate(currentDate.getDate() + 4);
    if (date > minAllowedDate) {
      const formattedDate = formatDate(date);
      setSelectedDate(formattedDate);
    } else {
      showNotifications("error", "Please select a future date.");
    }
  };

  useEffect(() => {
    getUserProfile().then((data) => {
      setUserInfo(data.user);
    });

    getMenuProfile().then((data) => {
      if (data.statusCode !== 200) {
      } else {
        setMenuInfo(data.data.profile);
        const startDate = new Date(data.data.profile.startDate);
        setProfileDate(startDate);
        setMenuCart(data.data.menu.requestMenu);
      }
    });

    // getMyOrder(page, limit).then((data) => {
    getMyOrder().then((data) => {
      if (data.data.orders.length === 0) {
        setNoFoods(true);
      } else {
        setNoFoods(false);
        setOrderList(data.data.orders);
        setPageValue(data.data.currentPage);
        setTotalValue(data.data.total);
      }
    });
    if (pageCount > 1) {
      setPrevButton(true);
    }
    if (page === 1) {
      setPrevButton(false);
    }
    // next button
    if (pageCount > 1) {
      setNextButton(true);
    }
    if (pageCount === page) {
      setNextButton(false);
    }
    getDeliveryTime("LUNCH").then((data) => {
      setLunchList(data.data.deliveryTimes);
    });
    getDeliveryTime("BREAKFAST").then((data) => {
      setBreakfastList(data.data.deliveryTimes);
    });
  }, [menuShow, updateProfile]);

  let onSubmit = (user: any) => {
    const importantTagsString = importantTags.join(',');
    const transformedData = {
      ...user,
      daysOfMenu: parseInt(user.daysOfMenu),
      priceRange: user.priceRange,
      startDate: selectedDate,
      allergens: allergens,
      purpose: purpose,
      medicalConditions: medicalConditions,
      disLikedProduct: disLikedProduct,
      consumeMeat: consumeMeat,
      importantTags: importantTagsString,
      healthIssues: "health",
    };
    if (breakStartTime.trim() !== "") {
      transformedData.breakfastStartTime = breakStartTime;
    }
    if (breakEndTime.trim() !== "") {
      transformedData.breakfastEndTime = breakEndTime;
    }
    if (lunchStartTime.trim() !== "") {
      transformedData.lunchStartTime = lunchStartTime;
    }
    if (lunchEndTime.trim() !== "") {
      transformedData.lunchEndTime = lunchEndTime;
    }

    if (selectedDate) {
      addMenu(transformedData).then((data: any) => {
        if (data.statusCode !== 200 && data.statusCode !== 201) {
          return showNotifications("error", data.message);
        } else {
          setMenuShow(true);
          return showNotifications("success", data.message);
        }
      });
    }
  };

  const handleBreakfastChange = (event: any) => {
    const { startTime, endTime } = splitTimeRange(event.target.value);
    setBreakStartTime(startTime);
    setBreakEndTime(endTime);
  };
  const handleLunchChange = (event: any) => {
    const { startTime, endTime } = splitTimeRange(event.target.value);
    setLunchStartTime(startTime);
    setLunchEndTime(endTime);
  };

  function handleRadioChange(event: any) {
    if (event.target.value === "medical_condition") {
      setMedicalCondition(true);
    } else {
      setMedicalCondition(false);
      setPurpose(event.target.value);
    }
  }

  function handleAllergyChange(event: any) {
    if (event.target.value === "yes") {
      setAllergy(true);
    } else {
      setAllergy(false);
      setAllergens(event.target.value);
    }
  }

  function handleMedicalChange(event: any) {
    if (event.target.value === "yes") {
      setMedical(true);
    } else {
      setMedical(false);
      setMedicalConditions(event.target.value);
    }
  }

  function handleDislikeChange(event: any) {
    if (event.target.value === "yes") {
      setDisLike(true);
    } else {
      setDisLike(false);
      setDisLikedProduct(event.target.value);
    }
  }
  function handleMeatChange(event: any) {
    setConsumeMeat(event.target.value);
  }

  const handleSelectChange = (selectedValue: any) => {
    if (!importantTags.includes(selectedValue)) {
      if (importantTags.length < 5) {
        setImportantTags([...importantTags, selectedValue]);
      } else {
        showNotifications("error", "You can't select more than 5 items.");
      }
    }
  };

  registerLocale("en-gb", enGB);



  function convertValuesToContent(values: any, options: any) {
    return values.map((value: any) => {
      const option = options.find((opt: any) => opt.value === value);
      return option
        ? {
          value: value,
          content: option.content,
        }
        : null;
    });
  }

  const deleteSelectItem = (selectItem: string) => {
    const updatedOptions = importantTags.filter((value: any) => value !== selectItem);
    setImportantTags(updatedOptions);
  };

  const handleTimeChange = (event: any) => {
    setSelectedTime(event.target.value);
  };

  return (
    <Layout>
      <ToastContainer />
      <section className="userProfileSection">
        <Container>
          <Row>
            <Col md={4} className="profileBox">
              <div className="card">
                <div className="card-header"></div>
                <div className="card-body">
                  <div className="cardRole">
                    <img src={userImage} width="100%" alt="" />
                  </div>
                  <div className="inner">
                    <div className="userName">
                      {userInfo && userInfo.firstName}{" "}
                      {userInfo && userInfo.lastName}
                    </div>
                    <div
                      className="color__gray"
                      style={{ fontSize: "19px", letterSpacing: ".5px" }}
                    >
                      {userInfo && userInfo.email}
                    </div>
                    <div
                      className="color__gray"
                      style={{ fontSize: "17px", letterSpacing: ".5px" }}
                    >
                      {userInfo && userInfo.address}
                    </div>
                    <div>
                      {menuCart && menuCart ? (
                        <>
                          <Link
                            to="/menu-cart"
                            className="btn btn-success mt-2"
                          >
                            Your Menu Is Ready !!
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={8}>
              <div className="MenuProfile">
                {updateProfile ? (
                  <EditProfile setUpdateProfile={setUpdateProfile} />
                ) : (
                  <>
                    {menuInfo && menuInfo ? (
                      <>
                        <h1 className="text-center">User Menu Profile</h1>
                        <Row>
                          <Col md={6}>
                            <div className="menuInput">
                              <label className="inputHeading">Gender</label>
                              <input
                                type="text"
                                value={menuInfo && menuInfo.gender}
                                readOnly
                                className="form-control"
                              />
                            </div>
                            <div className="menuInput">
                              <label className="inputHeading">Height</label>
                              <input
                                type="text"
                                value={menuInfo && menuInfo.height}
                                readOnly
                                className="form-control"
                              />
                            </div>
                            <div className="menuInput">
                              <label className="inputHeading">
                                When are you free from work so you can eat?
                              </label>
                              <input
                                type="text"
                                value={
                                  menuInfo && menuInfo.deliveryTime === "BOTH"
                                    ? "both"
                                    : menuInfo.deliveryTime === "BREAKFAST"
                                      ? "Breakfast"
                                      : "Lunch"
                                }
                                readOnly
                                className="form-control"
                              />
                            </div>
                            {menuInfo && menuInfo.breakfastStartTime ? (
                              <div className="menuInput">
                                <label className="inputHeading">
                                  In what time do you want to delivery
                                  breakfast?
                                </label>
                                <input
                                  type="text"
                                  value={
                                    menuInfo && menuInfo.breakfastStartTime
                                      ? `${menuInfo.breakfastStartTime} : ${menuInfo.breakfastEndTime}`
                                      : ""
                                  }
                                  readOnly
                                  className="form-control"
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="menuInput">
                              <label className="inputHeading">
                                Importance tags
                              </label>
                              <div className="selectItems mt-2">
                                {menuInfo && menuInfo.importantTags.split(',').map((tag: any, index: number) => {
                                  const option = options.find(opt => opt.value === tag.trim());
                                  if (option) {
                                    return <span><img key={index} src={option.icon} alt={option.label} style={{ width: '20px', height: '20px', borderRadius: '20px', marginRight: '8px' }} /> {option.label}</span>
                                  }
                                  return null;
                                })}
                              </div>

                            </div>
                            <div className="menuInput">
                              <label className="inputHeading">
                                Price Range
                              </label>
                              <input
                                type="text"
                                value={menuInfo && menuInfo.priceRange}
                                readOnly
                                className="form-control"
                              />
                            </div>
                            <div className="menuInput">
                              <label className="inputHeading">Purpose</label>
                              <input
                                type="text"
                                value={menuInfo && menuInfo.purpose}
                                readOnly
                                className="form-control"
                              />
                            </div>
                            <div className="menuInput">
                              <label className="inputHeading">
                                Medical conditions
                              </label>
                              <input
                                type="text"
                                value={menuInfo && menuInfo.medicalConditions}
                                readOnly
                                className="form-control"
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="menuInput">
                              <label className="inputHeading">Weight</label>
                              <input
                                type="text"
                                value={menuInfo && menuInfo.weight}
                                readOnly
                                className="form-control"
                              />
                            </div>
                            <div className="menuInput">
                              <label className="inputHeading">Age</label>
                              <input
                                type="text"
                                value={menuInfo && menuInfo.age}
                                readOnly
                                className="form-control"
                              />
                            </div>
                            <div className="menuInput">
                              <label className="inputHeading">
                                Menu Start Date(minimum 5 days after ordering)
                              </label>
                              <DatePicker
                                className="form-control"
                                selected={profileDate}
                                onChange={() => ({})}
                                dateFormat="yyyy/MM/dd"
                              />
                            </div>
                            {menuInfo && menuInfo.lunchStartTime ? (
                              <div className="menuInput">
                                <label className="inputHeading">
                                  In what time do you want to delivery
                                  breakfast?
                                </label>
                                <input
                                  type="text"
                                  value={
                                    menuInfo && menuInfo.lunchStartTime
                                      ? `${menuInfo.lunchStartTime} : ${menuInfo.lunchEndTime}`
                                      : ""
                                  }
                                  readOnly
                                  className="form-control"
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="menuInput">
                              <label className="inputHeading">
                                Medications
                              </label>
                              <input
                                type="text"
                                value={menuInfo && menuInfo.medications}
                                readOnly
                                className="form-control"
                              />
                            </div>
                            <div className="menuInput">
                              <label className="inputHeading">
                                For how many days you want your menu?
                              </label>
                              <input
                                type="text"
                                value={menuInfo && menuInfo.daysOfMenu === 7 ? "20 for 1 week" : menuInfo.daysOfMenu === 14 ? "35 for 2 weeks" : "50 for whole month"}
                                readOnly
                                className="form-control"
                              />
                            </div>
                            <div className="menuInput">
                              <label className="inputHeading">
                                DisLiked Product
                              </label>
                              <input
                                type="text"
                                value={menuInfo && menuInfo.disLikedProduct}
                                readOnly
                                className="form-control"
                              />
                            </div>
                            <div className="menuInput">
                              <label className="inputHeading">Allergens</label>
                              <input
                                type="text"
                                value={menuInfo && menuInfo.allergens}
                                readOnly
                                className="form-control"
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="menuInput">
                              <label className="inputHeading">
                                Consume Meat
                              </label>
                              <input
                                type="text"
                                value={menuInfo && menuInfo.consumeMeat}
                                readOnly
                                className="form-control"
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="menuInput">
                              <label className="inputHeading">note</label>
                              <textarea
                                value={menuInfo && menuInfo.note}
                                placeholder="Note"
                                className="form-control"
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="menuInput">
                              <label className="inputHeading">
                                Delivery Address
                              </label>
                              <textarea
                                value={menuInfo && menuInfo.deliveryAddress}
                                placeholder="Note"
                                className="form-control"
                              />
                            </div>
                          </Col>
                          <div className="text-center mt-5">
                            <button
                              type="submit"
                              className="btn btn-info"
                              onClick={() => setUpdateProfile(true)}
                            >
                              Edit Profile
                            </button>
                          </div>
                        </Row>
                      </>
                    ) : (
                      <>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <h1 className="text-center">Create Menu Profile</h1>
                          <Row>
                            <Col md={6}>
                              <div className="menuInput">
                                <label className="inputHeading">Gender</label>
                                <select {...register("gender", { required: true })} className="form-control" >
                                  <option value="">Select Gender</option>
                                  <option value="MALE">Male</option>
                                  <option value="FEMALE">Female</option>
                                </select>
                              </div>
                              <div className="menuInput">
                                <label className="inputHeading">Height (feet) </label>
                                <input type="text" {...register("height", { required: true })} placeholder="Ex: 6 feet" className="form-control" />
                              </div>
                              <div className="menuInput">
                                <label className="inputHeading">When are you free from work so you can eat? </label>
                                <select
                                  {...register("deliveryTime", {
                                    required: true,
                                    onChange: handleTimeChange
                                  })} className="form-control" >
                                  <option value="">Select Time</option>
                                  <option value="BREAKFAST">Breakfast</option>
                                  <option value="LUNCH">Lunch</option>
                                  <option value="BOTH">Both</option>
                                </select>
                              </div>
                              <div className="menuInput">
                                <label className="inputHeading">
                                  In what time do you want to delivery
                                  breakfast?
                                </label>
                                <select
                                  className="form-select"
                                  id="inputGroupSelect01"
                                  onChange={(e) => handleBreakfastChange(e)}
                                  disabled={selectedTime !== "BREAKFAST" && selectedTime !== "BOTH"}
                                >
                                  <option value="">Choose Time</option>
                                  {breakfastList.map((option, index) => {
                                    const value = `${option.startTime}-${option.endTime}`;
                                    return (
                                      <option
                                        key={`lunch${index}`}
                                        value={value}
                                      >
                                        {option.startTime}-{option.endTime}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="menuInput">
                                <label className="inputHeading">
                                  Select an tags:
                                </label>
                                <Dropdown className="form-control tagsDrop">
                                  <Dropdown.Toggle
                                    className="tagsBox"
                                    id="dropdown-basic"
                                  >
                                    Select the tags
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {options.map((option) => (
                                      <Dropdown.Item
                                        className="tagsBox"
                                        key={option.value}
                                        onClick={() =>
                                          handleSelectChange(option.value)
                                        }
                                      >
                                        <img
                                          className="icon"
                                          src={option.icon}
                                          alt=""
                                        />
                                        {option.label}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                                {importantTags.length ? <>
                                  <Col md={12}>
                                    <div className='selectItems mt-3'>
                                      {convertValuesToContent(importantTags, tagOptions).map((data: any) => {
                                        const option = options.find(opt => opt.value === data.value);
                                        return option ? (
                                          <span key={data.value}>
                                            <img src={option.icon} alt={data.content} style={{ width: '20px', height: '20px', borderRadius: '20px', marginRight: '8px' }} />
                                            {data.content}
                                            <FontAwesomeIcon icon={faClose} onClick={() => deleteSelectItem(data.value)} />
                                          </span>
                                        ) : null;
                                      })}
                                    </div>
                                  </Col>
                                </> : <></>}
                              </div>
                              <div className="menuInput">
                                <label className="inputHeading">
                                  What is your range?
                                </label>
                                <select
                                  {...register("priceRange", {
                                    required: true,
                                  })}
                                  className="form-control"
                                >
                                  <option value="">Select Price Range</option>
                                  <option value="10-15">10-15$</option>
                                  <option value="15-20">15-20$</option>
                                  <option value="I want best menu for me">
                                    I want best menu for me
                                  </option>
                                </select>
                              </div>

                              <div className="menuInput">
                                <label className="inputHeading">
                                  Purpose of diet{" "}
                                </label>
                                <Form.Check
                                  inline
                                  type="radio"
                                  label="Cut weight"
                                  name="purposeDiet"
                                  id="inlineRadio1"
                                  value="cut_weight"
                                  onChange={handleRadioChange}
                                />
                                <Form.Check
                                  inline
                                  type="radio"
                                  label="Gain muscles"
                                  name="purposeDiet"
                                  id="inlineRadio2"
                                  value="gain_muscles"
                                  onChange={handleRadioChange}
                                />
                                <Form.Check
                                  inline
                                  type="radio"
                                  label="Medical condition"
                                  name="purposeDiet"
                                  id="inlineRadio3"
                                  value="medical_condition"
                                  onChange={handleRadioChange}
                                />

                                <Form.Check
                                  inline
                                  type="radio"
                                  label="Other"
                                  name="inlineRadioOptions"
                                  id="inlineRadio4"
                                  value="other"
                                  onChange={handleRadioChange}
                                />
                                {medicalCondition ? (
                                  <div className="extraText">
                                    <textarea
                                      className="form-control"
                                      onChange={(e) =>
                                        setPurpose(e.target.value)
                                      }
                                    ></textarea>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="menuInput">
                                <label className="inputHeading">
                                  Medical conditions
                                </label>
                                <Form.Check inline type="radio" label="Yes , same as purpose of diet" name="medicalCondition" id="inlineRadio8" value="yes_diet" onChange={handleMedicalChange} />
                                <br />
                                <Form.Check inline type="radio" label="YES" name="medicalCondition" id="inlineRadio9" value="yes" onChange={handleMedicalChange} />
                                <Form.Check inline type="radio" label="NO" name="medicalCondition" id="inlineRadio11" value="no" onChange={handleMedicalChange} />
                                {medical ? (
                                  <div className="extraText">
                                    <textarea
                                      className="form-control"
                                      onChange={(e) =>
                                        setMedicalConditions(e.target.value)
                                      }
                                    ></textarea>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="menuInput">
                                <label className="inputHeading">Weight (kg) </label>
                                <input type="text" {...register("weight", { required: true })} placeholder="Ex: 50 kg" className="form-control" />
                              </div>
                              <div className="menuInput">
                                <label className="inputHeading">Age</label>
                                <input
                                  type="number"
                                  {...register("age", { required: true })}
                                  placeholder="Age"
                                  className="form-control"
                                />
                              </div>

                              <div className="menuInput">
                                <label className="inputHeading">
                                  Menu Start Date(minimum 5 days after ordering)
                                </label>
                                <DatePicker locale="en-gb" className="form-control" selected={selectedDate} onChange={handleSelect} placeholderText="Select a date" dateFormat="yyyy/MM/dd" />
                              </div>
                              <div className="menuInput">
                                <label className="inputHeading">
                                  In what time do you want to delivery lunch?
                                </label>
                                <select
                                  className="form-select"
                                  id="inputGroupSelect01"
                                  onChange={(e) => handleLunchChange(e)}
                                  disabled={selectedTime !== "LUNCH" && selectedTime !== "BOTH"}
                                >
                                  <option value="">Choose Time</option>
                                  {lunchList.map((option, index) => {
                                    const value = `${option.startTime}-${option.endTime}`;
                                    return (
                                      <option
                                        key={`lunch${index}`}
                                        value={value}
                                      >
                                        {option.startTime}-{option.endTime}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="menuInput">
                                <label className="inputHeading">
                                  Medications
                                </label>
                                <input
                                  type="text"
                                  {...register("medications", {
                                    required: true,
                                  })}
                                  placeholder="Medications"
                                  className="form-control"
                                />
                              </div>

                              <div className="menuInput">
                                <label className="inputHeading">
                                  For how many days you want your menu?
                                </label>
                                <select
                                  {...register("daysOfMenu", {
                                    required: true
                                  })} className="form-control" >
                                  <option value="">Select Time</option>
                                  <option value="7">1 week/20лв</option>
                                  <option value="14">2 weeks/35лв</option>
                                  <option value="30">1 month/50лв</option>
                                </select>
                              </div>

                              <div className="menuInput">
                                <label className="inputHeading">
                                  Is there product that you don’t like of our
                                  menu?
                                </label>
                                <Form.Check
                                  inline
                                  type="radio"
                                  label="YES"
                                  name="disLike"
                                  id="disLike01"
                                  value="yes"
                                  onChange={handleDislikeChange}
                                />
                                <Form.Check inline type="radio" label="NO" name="disLike" id="disLike02" value="no" onChange={handleDislikeChange} />
                                {disLike ? (
                                  <div className="extraText">
                                    <textarea
                                      className="form-control"
                                      onChange={(e) =>
                                        setDisLikedProduct(e.target.value)
                                      }></textarea>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="menuInput">
                                <label className="inputHeading">
                                  Do you have any allergies ?
                                </label>
                                <Form.Check inline type="radio" label="YES" name="inlineRadioOptions" id="inlineRadio6" value="yes" onChange={handleAllergyChange} />
                                <Form.Check inline type="radio" label="NO" name="inlineRadioOptions" id="inlineRadio7" value="no" onChange={handleAllergyChange} />
                                {allergy ? (
                                  <div className="extraText">
                                    <textarea
                                      className="form-control"
                                      onChange={(e) =>
                                        setAllergens(e.target.value)
                                      }
                                    ></textarea>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="menuInput">
                                <label className="inputHeading">
                                  Do you consume meat or animal products?
                                </label>
                                <Form.Check inline type="radio" label="Yes, everyday" name="consumeMeat" id="meat01" value="Yes, everyday" onChange={handleMeatChange} />
                                <Form.Check inline type="radio" label="Yes, few times a week" name="consumeMeat" id="meat02" value="Yes, few times a week" onChange={handleMeatChange} />
                                <Form.Check inline type="radio" label="No, I am vegan" name="consumeMeat" id="meat03" value="No, I am vegan" onChange={handleMeatChange} />
                                <Form.Check inline type="radio" label="No, I am vegetarian" name="inlineRadioOptions" id="meat04" value="No, I am vegetarian" onChange={handleMeatChange} />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="menuInput">
                                <label className="inputHeading">Note</label>
                                <textarea
                                  {...register("note", { required: true })}
                                  placeholder="Note"
                                  className="form-control"
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="menuInput">
                                <label className="inputHeading">
                                  Delivery Address
                                </label>
                                <textarea
                                  {...register("deliveryAddress", {
                                    required: true,
                                  })}
                                  placeholder="Delivery Address"
                                  className="form-control"
                                />
                              </div>
                            </Col>
                          </Row>
                          <div className="text-center mt-5">
                            <button type="submit" className="btn btn-info">Save</button>
                          </div>
                        </form>
                      </>
                    )}
                  </>
                )}
              </div>
            </Col>
            <Col md={12} className="profileOrder mt-5">
              {noFoods && noFoods === true ? (
                <h1 className="text-center text-danger">No Order</h1>
              ) : (
                <>
                  {orderList && orderList.length > 0 ? (
                    <>
                      <h1 className="text-center">Order list</h1>
                      <UserOrder
                        page={page}
                        setPage={setPage}
                        nextButton={nextButton}
                        pageValue={pageValue}
                        prevButton={prevButton}
                        orderList={orderList}
                      />
                    </>
                  ) : (
                    <>
                      <Loader></Loader>
                    </>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default UserProfile;
