import React, { useState, useEffect } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker';
import { MenuInterface } from '../../CommonInterface/CommonInterface';
import { getMenuProfile } from '../../api/user';
import { menuRequest } from '../../api/menu';
import { showNotifications } from '../../lib/toaster';

interface MenuInfoProps {
    daysMenu: number;
    menuShow: boolean;
    setMenuShow: (menuShow: boolean) => void;
    handleMenuClose: () => void;
}

const MenuModal = ({ daysMenu, menuShow, setMenuShow, handleMenuClose }: MenuInfoProps) => {
    const [menuInfo, setMenuInfo] = useState<MenuInterface>();
    const [profileDate, setProfileDate] = useState<any>(null);
    const [selectedDate, setSelectedDate] = useState<any>(null);
    const handleSelect = (date: Date) => {
        const currentDate = new Date();

    };
    useEffect(() => {
        getMenuProfile().then((data) => {
            if (data.statusCode !== 200) {

            }
            else {
                setMenuInfo(data.data.profile);
                const startDate = new Date(data.data.profile.startDate);
                setProfileDate(startDate);
                //   setMenuCart(data.data.menu.requestMenu);
            }

        })
    }, [menuShow]);

    const requestApi = () => {
        let menuDays = {
            "daysOfMenu": daysMenu
        }
        menuRequest(menuDays).then((data) => {
            showNotifications('success', data.message);
            setMenuShow(false)
        })
    }

    return (
        <>
            <Modal show={menuShow} onHide={handleMenuClose} size="lg">
                <section className="menuModal">
                    <Container>
                        <Modal.Header closeButton>
                            <h1 className='text-center'>Menu Profile</h1>
                        </Modal.Header>
                        <Row>
                            <Col md={6}>
                                <div className="menuInput">
                                    <label>Gender</label>
                                    <input type="text" value={menuInfo && menuInfo.gender} readOnly className='form-control' />
                                </div>
                                <div className="menuInput">
                                    <label>Purpose</label>
                                    <input type="text" value={menuInfo && menuInfo.purpose} readOnly className='form-control' />
                                </div>
                                <div className="menuInput">
                                    <label>Allergens</label>
                                    <input type="text" value={menuInfo && menuInfo.allergens} readOnly className='form-control' />
                                </div>
                                <div className="menuInput">
                                    <label>DisLiked Product</label>
                                    <input type="text" value={menuInfo && menuInfo.disLikedProduct} readOnly className='form-control' />
                                </div>
                                <div className="menuInput">
                                    <label>Food Type</label>
                                    <input type="text" value={menuInfo && menuInfo.veg === true ? "Vegetarian" : "Non-Vegetarian"} readOnly className='form-control' />
                                </div>
                                <div className="menuInput">
                                    <label>Delivery Time</label>
                                    <input type="text" value={menuInfo && menuInfo.deliveryTime === "BOTH" ? "both" : menuInfo && menuInfo.deliveryTime === "BREAKFAST" ? "Breakfast" : "Lunch"} readOnly className='form-control' />
                                </div>
                                {menuInfo && menuInfo.breakfastStartTime ? <div className="menuInput">
                                    <label>Breakfast Time</label>
                                    <input type="text" value={menuInfo && menuInfo.breakfastStartTime ? `${menuInfo.breakfastStartTime} : ${menuInfo.breakfastEndTime}` : ""} readOnly className='form-control' />
                                </div> : ""}
                            </Col>
                            <Col md={6}>
                                <div className="menuInput">
                                    <label>Choose Date</label>
                                    <DatePicker className='form-control' selected={profileDate} onChange={() => ({})} dateFormat="yyyy/MM/dd" />
                                </div>
                                <div className="menuInput">
                                    <label>Health Issues</label>
                                    <input type="text" value={menuInfo && menuInfo.healthIssues} readOnly className='form-control' />
                                </div>
                                <div className="menuInput">
                                    <label>Medications</label>
                                    <input type="text" value={menuInfo && menuInfo.medications} readOnly className='form-control' />
                                </div>
                                <div className="menuInput">
                                    <label>Important Tags</label>
                                    <input type="text" value={menuInfo && menuInfo.importantTags} readOnly className='form-control' />
                                </div>
                                <div className="menuInput">
                                    <label>Days Of Menu</label>
                                    <input
                                        type="text"
                                        value={menuInfo && menuInfo.daysOfMenu === 7 ? "7 days/40лв" : menuInfo && menuInfo.daysOfMenu === 14 ? "14 days/45лв" : "30 days/50лв"}
                                        readOnly
                                        className="form-control"
                                    />

                                </div>
                                <div className="menuInput">
                                    <label>Price Range</label>
                                    <input type="text" value={menuInfo && menuInfo.priceRange} readOnly className='form-control' />
                                </div>
                                {menuInfo && menuInfo.lunchStartTime ? <div className="menuInput">
                                    <label>Breakfast Time</label>
                                    <input type="text" value={menuInfo && menuInfo.lunchStartTime ? `${menuInfo.lunchStartTime} : ${menuInfo.lunchEndTime}` : ""} readOnly className='form-control' />
                                </div> : ""}
                            </Col>
                            <Col md={6}>
                                <div className="menuInput">
                                    <label>Note</label>
                                    <textarea value={menuInfo && menuInfo.note} placeholder="Note" className='form-control' />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="menuInput">
                                    <label>Delivery Address</label>
                                    <textarea value={menuInfo && menuInfo.deliveryAddress} placeholder="Note" className='form-control' />
                                </div>
                            </Col>
                        </Row>
                        <div className='text-center mt-2'>
                            <button type='submit' className='requestBtn' onClick={requestApi}>{menuInfo && menuInfo.daysOfMenu === 7 ? "7 days/40лв" : menuInfo && menuInfo.daysOfMenu === 14 ? "14 days/45лв" : "30 days/50лв"} Pay</button>
                        </div>
                    </Container>
                </section>
            </Modal>
        </>
    )
}

export default MenuModal