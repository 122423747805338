import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBellConcierge, faBookmark, faClock, faFile, faList, faSackDollar, faUsers, faUtensils } from "@fortawesome/free-solid-svg-icons";
import { Container, Nav, Navbar } from "react-bootstrap";
import "./Sidebar.css";
import { isAuthenticate, logout } from './../../../api/auth';
interface ChildProps {
    onChildClick: (isClicked: boolean) => void;
}
const Sidebar = (props: ChildProps) => {
    const navigate = useNavigate();
    const [isCollapsed, setIsCollapsed] = useState(true);
    const { user } = isAuthenticate();

    const handleSidebarToggle = () => {
        setIsCollapsed(!isCollapsed);
        props.onChildClick(!isCollapsed);
    };

    const logoutFunction = () => {
        logout()
        return navigate(`/`);
    };
    return (
        <>
            <Navbar sticky="top" expand="lg" className="topNav">
                <Container fluid>
                    <Navbar id="navbarScroll" className="navItems">
                        <li className="navHeading">
                            <Link to="/user_list" className="text-center">Digale Foodplan</Link>
                            <button className="sideBtn" onClick={handleSidebarToggle}>
                                <FontAwesomeIcon icon={faBars} />
                            </button>
                        </li>
                        <Nav className="my-lg-0" style={{ maxHeight: "100px" }} navbarScroll>
                            <li>
                                <button onClick={logoutFunction} className="btn logoutBtn">Logout</button>
                            </li>
                        </Nav>
                    </Navbar>
                </Container>
            </Navbar>
            <div
                // class="main-sidebar sidebar-style-2 sidebar-show1 sidebar-gone"
                className={`sidebar ${isCollapsed ? "" : "collapsedSidebar"}`}
            // style="overflow: hidden; outline: none;"
            >
                <aside className="sidebar-content" id="sidebar-wrapper">

                    <ul className="sidebar-menu">
                        {user && user.userRole === "ADMIN" ? <>
                            <li className="dropdown">
                                <Link to="/add_food"><FontAwesomeIcon icon={faFile} />Crete food</Link>
                            </li>
                            <li className="dropdown">
                                <Link to="/foodList"><FontAwesomeIcon icon={faList} />Food list</Link>
                            </li>
                            <li className="dropdown">
                                <Link to="/delivery_time"><FontAwesomeIcon icon={faClock} />Delivery time</Link>
                            </li>
                            <li className="dropdown">
                                <Link to="/user_list"><FontAwesomeIcon icon={faUsers} />User list</Link>
                            </li>
                            <li className="dropdown">
                                <Link to="/feature"><FontAwesomeIcon icon={faBookmark} />Feature</Link>
                            </li>
                            <li className="dropdown">
                                <Link to="/order_list"><FontAwesomeIcon icon={faList} />Order list</Link>
                            </li>
                            <li className="dropdown">
                                <Link to="/menu-request"><FontAwesomeIcon icon={faBellConcierge} />Menu Request</Link>
                            </li>
                            <li className="dropdown">
                                <Link to="/menu-set"><FontAwesomeIcon icon={faUtensils} />Menu set</Link>
                            </li>
                            <li className="dropdown">
                                <Link to="/discount"><FontAwesomeIcon icon={faSackDollar} />Discount</Link>
                            </li>
                        </> :
                            <>
                                <li className="dropdown">
                                    <Link to="/client_order"><FontAwesomeIcon icon={faList} />Order list</Link>
                                </li>
                            </>}

                    </ul>
                </aside>

            </div>
        </>
    )
}

export default Sidebar