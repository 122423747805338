import React from 'react'
import { Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DeliveryInterface } from '../../../CommonInterface/CommonInterface';
interface TimeProps {
    listTable: DeliveryInterface[];
    deleteTime: (deliveryTimesID: number) => void;
    editTime: (deliveryTimesID: number) => void;
}
const TimeTable = ({ listTable, deleteTime, editTime }: TimeProps) => {
    return (
        <>
            {listTable &&
                <Table responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Delivery ime</th>
                            <th>Start time</th>
                            <th>End time</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listTable.map((data, i) => <tr key={`time` + i}>
                            <td>{i + 1}</td>
                            <td>{data.deliveryTime}</td>
                            <td>{data.startTime}</td>
                            <td>{data.endTime}</td>
                            <td className='deliveryBtn'>
                                <button className='text-primary' onClick={() => editTime(data.deliveryTimesID)}><FontAwesomeIcon icon={faPenToSquare} /></button>
                                <button className='text-danger' onClick={() => deleteTime(data.deliveryTimesID)}><FontAwesomeIcon icon={faTrash} /></button>
                            </td>
                        </tr>)}
                    </tbody>
                </Table>
            }
        </>
    )
}

export default TimeTable