import React, { useEffect, useState } from 'react';
import Layout from '../Common/Layout/Layout';
import { Col, Container, Row, Accordion } from 'react-bootstrap';
import "./CartPage.css"
import { DeliveryInterface } from '../../CommonInterface/CommonInterface';
import { getSingleFood } from '../../api/api';
import Loader from '../Common/Loader/Loader';
import { CreateOrder } from '../../api/order';
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { showNotifications } from '../../lib/toaster';
import { convertDayName, convertTimeArrayFormat, countConsecutivePeriods, decrementCount, discountDaysCount, getAllDatesFromSunday, getCheckedItems, getUncheckedDates, groupDatesByWeek, groupFoodItemsByDate, incrementCount, jsonCheck, sortArrayByDeliveryDate } from '../../lib/commonFunction';
import CartCard from '../Common/CartCard/CartCard';
import { getDeliveryTime } from '../../api/deliverytime';
import { validateDiscount } from '../../api/discount';
import Slider from 'react-slick';
import { generateSettings } from './../../lib/commonFunction';
import { managementList } from '../../api/management';


const CartPage = () => {
  const navigate = useNavigate();
  const [orderList, setOrderList] = useState<any[]>([]);
  const [allOrderList, setAllOrderList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [address, setAddress] = useState("");
  const [extraInfo, setExtraInfo] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [lunchList, setLunchList] = useState<DeliveryInterface[]>([]);
  const [breakfastList, setBreakfastList] = useState<DeliveryInterface[]>([]);
  const [callCount, setCallCount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [amountDelivery, setAmountDelivery] = useState<number>(0);
  const [chargeDelivery, setChargeDelivery] = useState<number>(0);
  const [chargePerDay, setChargePerDay] = useState<number>(0);
  const [deliveryDays, setDeliveryDays] = useState<number>(0);
  let [count, setCount] = useState<number>(0);
  const maxWords = 100;
  const handleTextareaChange = (e: any) => {
    const inputValue = e.target.value;
    const words = inputValue.split(/\s+/);
    const truncatedWords = words.slice(0, maxWords);
    const truncatedText = truncatedWords.join(' ');
    setExtraInfo(truncatedText);
  };

  useEffect(() => {
    const storedOrderList = localStorage.getItem('orderList');
    if (!storedOrderList) {
      setIsLoading(false);
      return;
    }
    const orderList = JSON.parse(storedOrderList);

    const serialDeliveryDate = sortArrayByDeliveryDate(orderList);

    if (serialDeliveryDate.length === 0) {
      setIsLoading(false);
      return;
    }
    Promise.all(serialDeliveryDate.map(orderItem => getSingleFood(orderItem.foodId)
      .catch(error => {
        showNotifications('error', "Please connect to the backend server.!!");
      })))
      .then(foodResponses => {
        const newFoodList = foodResponses.map(data => data.data.food);
        const mergedList = serialDeliveryDate.map((orderItem, index) => ({
          ...orderItem,
          ...newFoodList[index],
        }));
        const totalPrice = mergedList.reduce((accumulator, currentItem) => {
          return accumulator + currentItem.price * currentItem.quantity;
        }, 0);
        setTotalPrice(totalPrice);
        const dateWiseFoodItems = groupFoodItemsByDate(mergedList);
        setAllOrderList(dateWiseFoodItems);
        const generatedDates = getAllDatesFromSunday(dateWiseFoodItems);
        const weeklyGroups = groupDatesByWeek(dateWiseFoodItems, generatedDates);
        setOrderList(weeklyGroups);
        setIsLoading(false);
      });



  }, [count]);

  useEffect(() => {
    // time set
    getDeliveryTime("LUNCH").then((data) => {
      if (data.statusCode === 200) {
        let arrayTime = convertTimeArrayFormat(data.data.deliveryTimes);
        setLunchList(arrayTime)
      }
    });
    getDeliveryTime("BREAKFAST").then((data) => {
      if (data.statusCode === 200) {
        let arrayTime = convertTimeArrayFormat(data.data.deliveryTimes);
        setBreakfastList(arrayTime)
      }
    });

  }, [])


  const increaseItem = (foodId: number, deliveryDate: string, deliveryTime: string) => {
    incrementCount(foodId, deliveryDate, deliveryTime);
    setCount(count + 1);
  }

  const decreaseItem = (foodId: number, deliveryDate: string, deliveryTime: string) => {
    decrementCount(foodId, deliveryDate, deliveryTime);
    if (currentPage === orderList.length) {
      setCurrentPage(1);
      decrementCount(foodId, deliveryDate, deliveryTime);
      setCount(count + 1);
    }
    const lastElement = orderList[orderList.length - 1];
    if (orderList.length - 1 && currentPage + 1) {
      if (lastElement.length === 1) {
        setCurrentPage(0);
      }
    }
    if (orderList.length === 1 && orderList[0]?.length === 1) {
      setOrderList([]);
    }
    setCount(count + 1);
  }

  function findFirstAndLastDelivery(data: any) {
    if (data) {
      if (data.length === 1) {
        const { date, days } = data[0];
        const firstDate = date;
        const firstDay = days;
        const lastDate = "";
        const lastDay = "";
        return { firstDelivery: { firstDate, firstDay }, lastDelivery: { lastDate, lastDay } };
      }
      else if (data.length > 1) {
        data.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());
        const firstDelivery: any = data[0];
        const lastDelivery: any = data[data.length - 1];
        const firstDate = firstDelivery.date;
        const firstDay = firstDelivery.days;
        const lastDate = lastDelivery.date;
        const lastDay = lastDelivery.days;
        return { firstDelivery: { firstDate, firstDay }, lastDelivery: { lastDate, lastDay } };
      }
    }
  }

  const result = findFirstAndLastDelivery(orderList && orderList[currentPage]);


  const confirmCart = () => {
    const checkedItems = getCheckedItems(orderList);
    if (checkedItems) {
      var jsonResult = jsonCheck(checkedItems);
      const orderCart: any = {
        "address": address,
        "cart": checkedItems
      }
      if (extraInfo) {
        orderCart.note = extraInfo;
      }

      if (jsonResult === true) {
        CreateOrder(orderCart).then((data) => {
          if (data.statusCode !== 201) {
            showNotifications('error', data.message);
          }
          else {
            localStorage.removeItem('orderList');
            showNotifications('success', data.message);
            return navigate(`/checkout/${data.data.orderID}`);
          }
        })
      }
      else {
        showNotifications('error', "Please Choose time");
      }
    }
  }

  const ChooseCart = (date: any) => {
    let tempObj = [...orderList];
    for (let i = 0; i < tempObj.length; i++) {
      for (let j = 0; j < tempObj[i].length; j++) {
        if (tempObj[i][j].date === date) {
          tempObj[i][j].isChecked = !tempObj[i][j].isChecked
          break;
        }
      }
    }
    setOrderList(tempObj);
    setCallCount(callCount + 1);
  }

  const allSelect = () => {
    const updatedOrderList = orderList.map(subArray => {
      return subArray.map((item: any) => ({ ...item, isChecked: true }));
    });
    setOrderList(updatedOrderList);
  }

  const allDeselect = () => {
    const updatedOrderList = orderList.map(subArray => {
      return subArray.map((item: any) => ({ ...item, isChecked: false }));
    });
    setOrderList(updatedOrderList);
  }

  const allDelete = () => {
    const storedOrderList = localStorage.getItem('orderList');
    if (!storedOrderList) {
      setIsLoading(false);
      return;
    }
    const orderStoreList = JSON.parse(storedOrderList);
    const checkedList = getUncheckedDates(allOrderList);
    const updatedDataArray = orderStoreList.filter((item: any) => checkedList.includes(item.deliveryDate));
    const updatedDataString = JSON.stringify(updatedDataArray);
    localStorage.setItem('orderList', updatedDataString);
    setCount(count + 1);
    if (updatedDataArray.length === 0) {
      setOrderList([]);
      setCount(count + 1);
    }
    if (orderList.length === 1) {
      setOrderList([]);
      setCount(count + 1);
    }
    const lastElement = orderList[orderList.length - 1];
    if (lastElement.length === 1) {
      setCurrentPage(0);
      setCount(count + 1);
    }
  }

  const updateDataWithDefaultTimes = (deliveryTime: string, startTime: string, endTime: string) => {
    const storedOrderList = localStorage.getItem('orderList');
    if (storedOrderList) {
      const orderList = JSON.parse(storedOrderList);
      for (let i = 0; i < orderList.length; i++) {
        const item = orderList[i];
        if (item.deliveryTime === deliveryTime) {
          item.startTime = startTime;
          item.endTime = endTime;
        }
      }
      const updatedDataString = JSON.stringify(orderList);
      localStorage.setItem('orderList', updatedDataString);
    }
    setCount(count + 1);
  };

  const calculateTotalSum = (propertyName: string) => {
    if (orderList && orderList[currentPage] && Array.isArray(orderList[currentPage])) {
      return Number(
        orderList[currentPage].reduce((sum: any, productItem: any) => sum + productItem[propertyName], 0).toFixed(2)
      );
    } else {
      return 0;
    }
  };

  useEffect(() => {
    const storedOrderList = localStorage.getItem('orderList');
    if (!storedOrderList) {
      setIsLoading(false);
      return;
    }
    const checkArray = allOrderList.filter(item => item.isChecked !== false);
    const dateArray = checkArray.map((item: any) => item.date);
    const countDate= countConsecutivePeriods(dateArray);
    validateDiscount(countDate).then((data) => {
      setDiscountAmount(data.discountAmount)
    });
  }, [orderList, currentPage, callCount]);

  function calculateDayWiseAmount(money: number) {
    const checkArray = allOrderList.filter(item => item.isChecked !== false);
    const amounts = checkArray.map((day: any) => {
      let totalAmount = day.items.reduce((sum: any, item: any) => {
        return sum + (parseFloat(item.price) * item.quantity);
      }, 0);
      return parseFloat(totalAmount.toFixed(2));
    });
    const countGreaterThanMoney = amounts.filter((amount: any) => amount < money).length;
    return countGreaterThanMoney;
  }

  useEffect(() => {
    managementList().then((data) => {
      if (data.statusCode === 201) {
        if (data.data.adminManagement) {
          const daysDiscount = calculateDayWiseAmount(data.data.adminManagement.freeDeliveryThreshold);
          setAmountDelivery(data.data.adminManagement.freeDeliveryThreshold);
          setDeliveryDays(daysDiscount);
          setChargePerDay(data.data.adminManagement.deliveryCharge);
          setChargeDelivery(data.data.adminManagement.deliveryCharge * daysDiscount);
        }
      }
    })
  }, [allOrderList, callCount])


  function sumPricesForCheckedItems() {
    let sum = 0;
    allOrderList.forEach((obj: any) => {
      if (obj.isChecked) {
        obj.items.forEach((item: any) => {
          const price = parseFloat(item.price);
          const quantity = parseFloat(item.quantity);
          if (!isNaN(price)) {
            sum += price * quantity;
          }
        });
      }
    });
    return sum;
  }



  return (
    <Layout>
      <section className="cartSection">
        <ToastContainer />
        <Container fluid>
          <Row>
            {orderList && orderList.length ? <>
              <Col md={12}>
                <div className="cartHeading">
                  <h1>Cart</h1>
                </div>
              </Col>
              <Col md={8} lg={9}>
                {isLoading ? <Loader /> : <>
                  <div className="weeklyLine">
                    <p className='text-center'>Weekly {calculateTotalSum('totalProteins')}g Protein | {calculateTotalSum('totalFats')}g Fat | {calculateTotalSum('totalCarbohydrates')}g Carbo | {calculateTotalSum('totalCalories')}g Calories</p>

                    {orderList && Array.from({ length: orderList.length }, (_, index) =>
                      <button
                        key={index}
                        onClick={() => setCurrentPage(index)}
                        className={currentPage === index ? 'activeWeekly' : ''}
                      >
                        Week {index + 1}
                      </button>
                    )}
                    <div className="selectedBtn">
                      <button onClick={allSelect}>Select all</button>
                      <button onClick={allDeselect}>Deselect all</button>
                      <button onClick={allDelete}>Delete all Selected</button>
                    </div>
                  </div>


                  {orderList && orderList[currentPage]?.map((productItem: any, i: number) => <div className='cartLine' key={`weeklyList` + i}>
                    <Col md={12}>
                      <div className='cartItemHeading'>
                        <h1>{productItem.days}</h1>
                        <p>Daily {productItem.totalProteins.toFixed(2)}g Protein | {productItem.totalFats.toFixed(2)}g Fat | {productItem.totalCarbohydrates.toFixed(2)}g Carbo | {productItem.totalCalories.toFixed(2)}g Calories</p>
                        <button>All Set!</button>
                      </div>
                    </Col>
                    <Col md={12} className='cartTable' style={{ display: "flex", alignItems: "center", paddingTop: "15px" }}>
                      {productItem.isChecked}
                      <label className="checkBoxLine">
                        <input type="checkbox" onClick={() => ChooseCart(productItem.date)} checked={productItem.isChecked} />
                        <span className="checkmark"></span>
                      </label>
                      {productItem.items.length > 1 ? <>
                        <div className='w-90 cartSlider'>
                          <Slider {...generateSettings(2)}>
                            {productItem && productItem.items.map((data: any, j: number) => (
                              <div className="" key={`menuCard${i}`}>
                                <CartCard lunchList={lunchList} breakfastList={breakfastList} count={count} setCount={setCount} key={`productList` + j} data={data} j={j} decreaseItem={decreaseItem} increaseItem={increaseItem} styleClass="singleCartCard" />
                              </div>
                            ))}
                          </Slider>
                        </div>
                      </> : <>
                        {productItem && productItem.items.map((data: any, j: number) =>
                          <CartCard lunchList={lunchList} breakfastList={breakfastList} count={count} setCount={setCount} key={`listProduct` + j} data={data} j={j} decreaseItem={decreaseItem} increaseItem={increaseItem} styleClass="singleCartCard" />
                        )}
                      </>}

                    </Col>
                  </div>)}

                </>}
              </Col>
              <Col md={4} lg={3}>
                <div className="cartPrice">
                  <h1>Vegetarian meal plan - small</h1>
                  <h2>Starts {result && result.firstDelivery.firstDate} {result && result.lastDelivery.lastDate ? ` to ${result.lastDelivery.lastDate}` : ""}</h2>
                  <h2>{result && convertDayName(result.firstDelivery.firstDay)} {result && result.lastDelivery.lastDay ? ` to ${convertDayName(result.lastDelivery.lastDay)}` : ""}</h2>
                  <button onClick={confirmCart} className='mb-3'>Checkout</button>
                  <div className="totalPrice">
                    <p>Sub-total </p>
                    <p>${sumPricesForCheckedItems().toFixed(2)}лв</p>
                  </div>
                  <div className="totalPrice">
                    <p>Discount</p>
                    <p>{discountAmount && discountAmount}%</p>
                  </div>
                  {chargeDelivery > 0 ? <div className="totalPrice">
                    <p>Delivery Charge <span className='dayCharge'>({deliveryDays} day * {chargePerDay})</span></p>
                    <p>{chargeDelivery}</p>
                  </div> : ""}
                  <div className="totalPrice totalLine">
                    <p>Total</p>
                    <p>{(sumPricesForCheckedItems() && sumPricesForCheckedItems() * (1 - discountAmount / 100) + (chargeDelivery ? chargeDelivery : 0)).toFixed(2)}лв</p>
                  </div>
                  {discountAmount && discountAmount ? <h6>You Saved {discountAmount && discountAmount}% for weekly order</h6> : ""}
                  {chargeDelivery > 0 ? <h5>Spend {amountDelivery}лв per day to qualify for free home delivery!</h5> : ""}
                </div>
                <div className="changeTime">
                  <h4 className='cartText mb-3'>Choose your hour delivery</h4>
                  <Accordion>
                    <Accordion.Item eventKey="breakfast">
                      <Accordion.Header>Breakfast</Accordion.Header>
                      <Accordion.Body>
                        <div className="radio-list">
                          {breakfastList.map((data, i) =>
                            <label className="timeCheckbox" key={`breakfastList` + i}>
                              {data.startTime} -- {data.endTime}
                              <input type="radio" name="breakfast" onChange={() => updateDataWithDefaultTimes("BREAKFAST", data.startTime, data.endTime)} />
                              <span className="checkmark"></span>
                            </label>
                          )}

                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="lunch">
                      <Accordion.Header>Lunch</Accordion.Header>
                      <Accordion.Body>
                        {/* <div className="radio-list"> */}
                        {lunchList.map((data, i) =>
                          <label className="timeCheckbox" key={`lunchList` + i}>
                            {data.startTime} -- {data.endTime}
                            <input type="radio" name="lunch" onChange={() => updateDataWithDefaultTimes("LUNCH", data.startTime, data.endTime)} />
                            <span className="checkmark"></span>
                          </label>)}
                        {/* </div> */}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <div className="extraInfo">
                    <label className='cartText mb-2'>Enter your delivery address</label>
                    <textarea onChange={(e) => setAddress(e.target.value)} className='form-control' placeholder='Address'></textarea>
                    <h2 className='cartText mt-3'>Additional Information</h2>
                    <textarea className='form-control' value={extraInfo} onChange={handleTextareaChange} ></textarea>
                  </div>
                </div>

              </Col>
            </> : <div className='NoProduct'>
              <img src="https://cdni.iconscout.com/illustration/premium/thumb/empty-cart-4344468-3613896.png" alt="no order" width="auto" />
              <h1>No Product Found !!</h1>
            </div>}
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default CartPage