import { API } from "../config";

export const managementCreate = async (body: {}) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';

    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/admin-management`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

export const managementList = async () => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/admin-management`, {
            headers: headers
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

export const managementUpdate = async (body: {}) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/admin-management`, {
            method: 'PATCH',
            headers: headers,
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};