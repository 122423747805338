import React from 'react';
import "./Footer.css";
import logo from "../../../assets/image/logo.png"
// font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faGooglePlus, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <>
      <footer id="dk-footer" className="dk-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-4">
              <div className="dk-footer-box-info">
                <Link to="index.html" className="footer-logo">
                  <img src={logo} width={200} alt="footer_logo" className="img-fluid" />
                </Link>
                <p className="footer-info-text">
                  Наистина <b>здравословна</b> кухня
                </p>

                <div className="footer-social-link">
                  <h3>Follow us</h3>
                  <ul>
                    <li>
                      <Link to="#">
                        <FontAwesomeIcon className="fa-facebook" icon={faFacebook} />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <FontAwesomeIcon className="fa-twitter" icon={faTwitter} />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <FontAwesomeIcon className="fa-google-plus" icon={faGooglePlus} />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <FontAwesomeIcon className="fa-linkedin" icon={faLinkedin} />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <FontAwesomeIcon className="fa-instagram" icon={faInstagram} />
                      </Link>
                    </li>
                  </ul>
                </div>

              </div>

            </div>
            <div className="col-md-12 col-lg-4">
              <div className="footer-widget footer-left-widget">
                <div className="section-heading">
                  <h3>Useful Links</h3>
                  <span className="animate-border border-black"></span>
                </div>
                <ul>
                  <li>
                    <Link to="#">About us</Link>
                  </li>
                  <li>
                    <Link to="#">Services</Link>
                  </li>
                  <li>
                    <Link to="#">Projects</Link>
                  </li>
                  <li>
                    <Link to="#">Our Team</Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to="#">Contact us</Link>
                  </li>
                  <li>
                    <Link to="#">Blog</Link>
                  </li>
                  <li>
                    <Link to="#">Testimonials</Link>
                  </li>
                  <li>
                    <Link to="#">Faq</Link>
                  </li>
                </ul>
              </div>

            </div>
            <div className="col-md-12 col-lg-4">
              <div className="footer-widget">
                <div className="section-heading">
                  <h3>Contact</h3>
                  <span className="animate-border border-black"></span>
                </div>
                <div className="contactNumber">
                  <a href="tel:0888301811"><FontAwesomeIcon icon={faPhone} /> 0888301811</a>
                  <a href="mailto:admin@foodplan.com"><FontAwesomeIcon icon={faEnvelope} /> admin@foodplan.com</a>
                </div>
                <hr/>
                <div className="workTime">
                  <h1>Work time:</h1>
                  <p>Mon - Fri: 09:00 - 18:00</p>
                </div>


              </div>

            </div>
          </div>

        </div>


        {/* copyright */}
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <span>Copyright © 2024, All Right Reserved Digale Foodplan</span>
              </div>
            </div>
          </div>
        </div>

      </footer>
    </>
  )
}

export default Footer