import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { DeliveryInterface } from '../../../CommonInterface/CommonInterface';
import { getDeliveryTime } from '../../../api/deliverytime';
import { convertTimeArrayFormat, deliveryTimeAdd, splitTimeRange } from '../../../lib/commonFunction';
import FoodImage from '../FoodImage/FoodImage';

interface CardProps {
    data: any;
    j: number;
    increaseItem?: any;
    decreaseItem?: any;
    styleClass: string;
    count: number;
    setCount: any;
    lunchList: any;
    breakfastList: any;
}
const CartCard = ({ breakfastList, lunchList, count, setCount, data, j, increaseItem, decreaseItem, styleClass }: CardProps) => {

    const handleSelectChange = (event: any, foodId: number, deliveryDate: string, deliveryTime: string) => {
        const { startTime, endTime } = splitTimeRange(event.target.value);
        deliveryTimeAdd(foodId, deliveryDate, deliveryTime, startTime, endTime);
        setCount(count + 1)
    };
    return (
        <div className={styleClass} key={`cartItem` + j}>
            <div style={{ display: 'flex' }}>
                <div className="cartImage">
                    {data && data.foodId ? (
                        <FoodImage foodId={data.foodId} title={data.title} imageSize="" />
                    ) : (
                        <p>No photo</p>
                    )}
                </div>
                <div className="cartContent">
                    <h1>{data.deliveryTime}</h1>
                    <h2>{window.innerWidth <= 768 ? data.title.slice(0, 10) : data.title.slice(0, 22)}</h2>
                    <h3>Price: {data.price}</h3>
                    <h4><span>Time</span>
                        <select
                            className="form-select"
                            id="inputGroupSelect01"
                            onChange={(e) => handleSelectChange(e, data.foodId, data.deliveryDate, data.deliveryTime)}
                        >
                            {data && data.startTime && data.endTime ? (
                                <option value={`${data.startTime}-${data.endTime}`}>
                                    {data.startTime}-{data.endTime}
                                </option>
                            ) : (
                                <option value="">
                                    Choice
                                </option>
                            )}
                            {(data && data.startTime && data.endTime && data.deliveryTime === "BREAKFAST") ? (
                                <>
                                    {breakfastList.map((option: any, i: number) => {
                                        const value = `${option.startTime}-${option.endTime}`;
                                        return (
                                            <option key={`breakfast${i}`} value={value} disabled={value === `${data.startTime}-${data.endTime}`}>
                                                {option.startTime}-{option.endTime}
                                            </option>
                                        );
                                    })}
                                </>
                            ) : (
                                <>
                                    {lunchList.map((option: any, i: number) => {
                                        const value = `${option.startTime}-${option.endTime}`;
                                        return (
                                            <option key={`lunch${i}`} value={value} disabled={value === `${data.startTime}-${data.endTime}`}>
                                                {option.startTime}-{option.endTime}
                                            </option>
                                        );
                                    })}
                                </>
                            )}
                        </select>
                    </h4>
                    <div className="energyList largeView">
                        <div className="energyValue">
                            <p>{(data.proteins * data.weight).toFixed(2)}gr</p>
                            <p>Proteins</p>
                        </div>
                        <div className="energyValue">
                            <p>{(data.fats * data.weight).toFixed(2)}gr</p>
                            <p>Fats</p>
                        </div>
                        <div className="energyValue">
                            <p>{(data.carbohydrates * data.weight).toFixed(2)}gr</p>
                            <p>carbs</p>
                        </div>
                        <div className="energyValue">
                            <p>{(data.calories * data.weight).toFixed(2)}gr</p>
                            <p>Calories</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="energyList smallView">
                <div className="energyValue">
                    <p>{(data.proteins * data.weight).toFixed(2)}gr</p>
                    <p>Proteins</p>
                </div>
                <div className="energyValue">
                    <p>{(data.fats * data.weight).toFixed(2)}gr</p>
                    <p>Fats</p>
                </div>
                <div className="energyValue">
                    <p>{(data.carbohydrates * data.weight).toFixed(2)}gr</p>
                    <p>carbs</p>
                </div>
                <div className="energyValue">
                    <p>{(data.calories * data.weight).toFixed(2)}gr</p>
                    <p>Calories</p>
                </div>
            </div>
            <div className='cartNumber'>
                <button className='btn' onClick={() => decreaseItem(data.foodId, data.deliveryDate, data.deliveryTime)}><FontAwesomeIcon icon={faMinus} /></button>
                <p>{data.quantity}</p>
                <button className='btn' onClick={() => increaseItem(data.foodId, data.deliveryDate, data.deliveryTime)}><FontAwesomeIcon icon={faPlus} /></button>
            </div>
        </div>
    )
}

export default CartCard