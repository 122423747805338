import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import AdminLayout from '../../Common/AdminLayout/AdminLayout';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { assignMenuUser, getMenuList, getSingleRequest, menuReject } from '../../../api/menu';
import { showNotifications } from '../../../lib/toaster';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { convertToDateOnly } from '../../../lib/commonFunction';
import MenuFood from './MenuFood';

const ViewRequest = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [menuView, setMenuView] = useState<any>({});
    const [menuList, setMenuList] = useState<any[] | undefined>([]);
    const [menuName, setMenuName] = useState("");
    const [count, setCount] = useState(0);
    const [show, setShow] = useState(false);
    const [daysOfMenu, setDaysOfMenu] = useState("");
    const [daysOfDate, setDaysOfDate] = useState("");
    const handleCloseModal = () => {
        setShow(false);
    };

    const handleLayoutStateChange = (clickedInParent: boolean) => {
        setIsCollapsed(!clickedInParent);
    };

    useEffect(() => {
        if (id) {
            getSingleRequest(id).then((data) => {
                if (data.statusCode !== 200) {
                    console.log('no data');
                }
                else {
                    setMenuView(data.request)
                }
            })
        }

        let body = { "name": menuName }
        getMenuList(1, 20, body).then((data) => {
            if (data.statusCode !== 200) {
                console.log('no data');
            }
            else {
                setMenuList(data.data.menus)
            }
        })
    }, [menuName, count, show]);


    const handleMenu = (value: any) => {
        setMenuName(value)
    }

    const assignMenu = (menuId: number) => {
        let menuInfo = {
            "menuID": menuId,
            "requestID": menuView.menuRequestId
        }
        assignMenuUser(menuInfo).then((data) => {
            if (data.statusCode !== 201) {
                showNotifications('error', data.message);
            }
            else {
                showNotifications('success', data.message);
                return navigate(`/menu-request`);
            }
        })
    }

    const deleteItem = (id: string) => {
        let deleteInfo = {
            "menuCartId": parseInt(id)
        }
        menuReject(deleteInfo, menuView.menuRequestId).then((data) => {
            if (data.statusCode !== 200) {
                showNotifications('error', data.message);
            }
            else {
                showNotifications('success', data.message);
                setCount(count + 1)
            }
        })
    }

    const menuUpdate = (daysOfMenu: string, deliveryDate: string) => {
        setShow(true);
        setDaysOfMenu(daysOfMenu);
        setDaysOfDate(deliveryDate);
    }



    return (
        <>
            <AdminLayout onLayoutStateChange={handleLayoutStateChange}>
                <ToastContainer />
                <section className={`main-body ${isCollapsed ? '' : 'bodyCollapsed'}`}>
                    <Container>
                        <Row>
                            <div className="profileBoxInfo">
                                <Row>
                                    <Col md={12}>
                                        <h1 className='adminHeading text-center'>Profile Information</h1>
                                    </Col>
                                    <Col md={4}>
                                        <div className="bio-row">
                                            <p><span><b>User Name:</b> </span> {menuView && menuView.user && menuView.user.firstName}</p>
                                        </div>
                                        <div className="bio-row">
                                            <p><span><b>User E-mail:</b> </span> {menuView && menuView.user && menuView.user.email}</p>
                                        </div>
                                        <div className="bio-row">
                                            <p><span><b>Heath Issue:</b> </span> {menuView && menuView.profile && menuView.profile.healthIssues}</p>
                                        </div>
                                        <div className="bio-row">
                                            <p><span><b>Medications</b> </span> {menuView && menuView.profile && menuView.profile.medications}</p>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="bio-row">
                                            <p><span><b>Gender:</b> </span> {menuView && menuView.profile && menuView.profile.gender}</p>
                                        </div>
                                        <div className="bio-row">
                                            <p><span><b>Purpose:</b> </span> {menuView && menuView.profile && menuView.profile.purpose}</p>
                                        </div>
                                        <div className="bio-row">
                                            <p><span><b>Allergens:</b> </span> {menuView && menuView.profile && menuView.profile.allergens}</p>
                                        </div>
                                        <div className="bio-row">
                                            <p><span><b>Number Of Days:</b> </span> {menuView && menuView.profile && menuView.profile.daysOfMenu}</p>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="bio-row">
                                            <p><span><b>Disliked Product:</b> </span> {menuView && menuView.profile && menuView.profile.disLikedProduct}</p>
                                        </div>
                                        <div className="bio-row">
                                            <p><span><b>Food Type:</b> </span> {menuView && menuView.profile && menuView.profile.veg === true ? "Vegetarian" : "Non-Vegetarian"}</p>
                                        </div>
                                        <div className="bio-row">
                                            <p><span><b>Tags:</b> </span> {menuView && menuView.profile && menuView.profile.importantTags}</p>
                                        </div>
                                        <div className="bio-row">
                                            <p><span><b>Notes:</b> </span> {menuView && menuView.profile && menuView.profile.note}</p>
                                        </div>
                                    </Col>
                                    {menuView && menuView.menuCartItem && menuView.menuCartItem ?
                                        <Col md={12}>
                                            {menuView && menuView.menuCartItem && menuView.menuCartItem.map((data: any, i: number) =>
                                                <button className='menuCard' onClick={() => menuUpdate(menuView?.profile?.daysOfMenu, data.deliveryDate)}>{convertToDateOnly(data.deliveryDate)}</button>
                                            )}
                                            <Table responsive bordered hover className='align-items-center'>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Date</th>
                                                        <th>Title</th>
                                                        <th>Food Type</th>
                                                        <th>Price</th>
                                                        <th>Quantity</th>
                                                        <th>Delivery Time</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {menuView && menuView.menuCartItem && menuView.menuCartItem.map((data: any, i: number) => <tr className='foodListTable' key={`menuList` + i}>
                                                        <td>{i + 1}</td>
                                                        <td>{convertToDateOnly(data.deliveryDate)}</td>
                                                        <td>{data.food.title}</td>
                                                        <td>{data.food.foodType}</td>
                                                        <td>{data.price}</td>
                                                        <td>{data.quantity}</td>
                                                        <td>{data.deliveryTime}</td>
                                                        <td>
                                                            <button className='btn btn-danger' onClick={() => deleteItem(data.menuCartId)}>Delete</button>
                                                        </td>
                                                    </tr>)}
                                                </tbody>
                                            </Table>
                                        </Col> : ""}
                                </Row>
                            </div>
                        </Row>

                        <Row>
                            <div className="profileBoxInfo mt-4">
                                <Row>
                                    <Col md={12}>
                                        <h1 className='adminHeading text-center'>Menu List</h1>
                                    </Col>
                                    <Col md={4}>
                                        <label>Search menu</label>
                                        <input className='form-control' onChange={(e) => handleMenu(e.target.value)} placeholder='Menu search' />
                                    </Col>
                                    <Col md={12} className='mt-3'>
                                        <Table responsive bordered hover className='align-items-center'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Charge</th>
                                                    <th>Number of days</th>
                                                    <th>Assign</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {menuList && menuList.map((data, i) => <tr className='foodListTable' key={`menuList` + i}>
                                                    <td>{i + 1}</td>
                                                    <td>{data.name}</td>
                                                    <td>{data.charge}</td>
                                                    <td>{data.daysOfMenu}</td>
                                                    <td><button className='btn btn-success text-white' onClick={() => assignMenu(data.menuId)}>Assign</button></td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </div>
                        </Row>
                    </Container>
                </section>

                <MenuFood show={show} handleCloseModal={handleCloseModal} setShow={setShow} daysOfMenu={daysOfMenu} daysOfDate={daysOfDate} menuRequestId={menuView.menuRequestId} />
            </AdminLayout>
        </>
    )
}

export default ViewRequest