import React from 'react';
import { Circles } from 'react-loader-spinner';
const Loader = () => {
    return (
        <>
           <div className='d-flex justify-content-center align-items-center' style={{height: '100vh'}}>
           <Circles
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
           </div>
        </>
    )
}

export default Loader