import { API } from "../config";

// create Delivery Time
export const CreateDeliveryTime = async (body: {}) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/admin-management/delivery-time`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

// Get Search Delivery Time
export const getDeliveryTime = async (deliveryTime: string) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const response = await fetch(`${API}/admin-management/delivery-time?page=1&limit=50&deliveryTime=${deliveryTime}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

// delete Delivery Time
export const deleteDeliveryTime = async (deliveryTimesID: number) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const response = await fetch(`${API}/admin-management/delivery-time/${deliveryTimesID}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

// get single delivery time 
export const getSingleDeliveryTime = async (deliveryId: number) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const response = await fetch(`${API}/admin-management/delivery-time/${deliveryId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

// update delivery time
export const updateDeliveryTimeApi = async (body: {}, deliveryId: number) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/admin-management/delivery-time/${deliveryId}`, {
            method: 'PATCH',
            headers: headers,
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};