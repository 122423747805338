import React, { useState, useEffect } from 'react'
import AdminLayout from '../../Common/AdminLayout/AdminLayout'
import { Col, Container, Modal, Row, Table } from 'react-bootstrap';
import { getFoodList } from '../../../api/foods';
import { DeliveryInterface, FoodInterface } from '../../../CommonInterface/CommonInterface';
import Loader from '../../Common/Loader/Loader';
import FoodImage from '../../Common/FoodImage/FoodImage';
import { getDeliveryTime } from '../../../api/deliverytime';
import { splitTimeRange } from '../../../lib/commonFunction';
import { menuCreate } from '../../../api/menu';
import { useNavigate } from 'react-router-dom';
import ViewMenu from './ViewMenu';
import ChooseDays from './ChooseDays';

const MenuAdd = () => {
    const navigate = useNavigate();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [foodId, setFoodId] = useState<number>(0);
    const [foodTitle, setFoodTitle] = useState("");
    const handleLayoutStateChange = (clickedInParent: boolean) => {
        setIsCollapsed(!clickedInParent);
    };

    const [itemList, setItemList] = useState<FoodInterface[] | undefined>([]);
    const [limit, setLimit] = useState<number>(10);
    const [titleName, setTitle] = useState("");
    const [prevButton, setPrevButton] = useState<boolean>(false);
    const [nextButton, setNextButton] = useState<boolean>(false);
    const [noFoods, setNoFoods] = useState<boolean>(false);
    const [pageValue, setPageValue] = useState<number>();
    const [totalValue, setTotalValue] = useState<number>(0);
    const [page, setPage] = useState(1);
    const pageCount = Math.ceil(totalValue / 10);
    const [lunchList, setLunchList] = useState<DeliveryInterface[]>([]);
    const [breakfastList, setBreakfastList] = useState<DeliveryInterface[]>([]);
    const [startTime, setStartTime] = useState<string>("");
    const [endTime, setEndTime] = useState<string>("");
    const [quantity, setQuantity] = useState<string>("1");
    const [selectedTimes, setSelectedTimes] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const [selectedPrice, setSelectedPrice] = useState("");
    const [selectedWeight, setSelectedWeight] = useState("");
    const menu = localStorage.getItem("menu") || '{}';
    const createMenu = JSON.parse(menu);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [menuItemShow, setMenuItemShow] = useState(false);
    const handleMenuClose = () => setMenuItemShow(false);
    const [menuDaysShow, setMenuDaysShow] = useState(false);
    const handleDaysClose = () => setMenuDaysShow(false);
    const [currentDays, setCurrentDays] = useState<number[]>([]);

    useEffect(() => {
        let body = { "tags": [], "featured": null, "title": titleName }
        getFoodList(page, limit, body).then((data) => {
            if (data.data.foods.length === 0) {
                setNoFoods(true);
            }
            else {
                setNoFoods(false);
                setItemList(data.data.foods);
                setPageValue(data.data.currentPage);
                setTotalValue(data.data.total);
            }
        })
        if (pageCount > 1) {
            setPrevButton(true)
        }
        if (page === 1) {
            setPrevButton(false)
        }
        // next button
        if (pageCount > 1) {
            setNextButton(true)
        }
        if (pageCount === page) {
            setNextButton(false)
        }
        getDeliveryTime("LUNCH").then((data) => {
            setLunchList(data.data.deliveryTimes)
        });
        getDeliveryTime("BREAKFAST").then((data) => {
            setBreakfastList(data.data.deliveryTimes)
        });
    }, [page, pageCount, titleName])

    const handleMenu = (value: any) => {
        setTitle(value)
    }


    // pagination
    const nextPage = () => {
        setPage(page + 1)
    }

    const prevPage = () => {
        setPage(page - 1)
    }

    const handleSelectChange = (event: any) => {
        const { startTime, endTime } = splitTimeRange(event.target.value);
        setStartTime(startTime);
        setEndTime(endTime);
    };

    const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const orderAdd = (foodId: number, deliveryTime: string) => {
        setMenuDaysShow(true);
        setFoodId(foodId);
    }

    const handleOptionChange = (event: any, index: number, foodTitle: string) => {
        const { value } = event.target;
        const [price, weight] = value.split(" ");
        setSelectedPrice(price);
        setSelectedWeight(weight);
        setSelectedOption(prevOptions => {
            const updatedOptions: any = [...prevOptions];
            updatedOptions[index] = value;
            return updatedOptions;
        });
        setFoodTitle(foodTitle);
    };

    const menuFunction = () => {
        let existingData: any = localStorage.getItem('menu');
        let menuData = JSON.parse(existingData);
        menuCreate(menuData).then((data) => {
            if (data.statusCode !== 201) {
                console.log('discount', data);
            }
            else {
                return navigate(`/menu-set`);
            }
        });
    }

    const viewMenuModal = () => {
        setMenuItemShow(true);
    }

    const menuCart = (index: number, deliveryTime: string) => {
        const indexExists = currentDays.includes(index);

        if (indexExists) {
            const updatedDays = currentDays.filter(day => day !== index);
            setCurrentDays(updatedDays);
        } else {
            setCurrentDays(prevDays => [...prevDays, index]);
        }

        let existingData = localStorage.getItem('menu');
        let newData;
        if (existingData) {
            newData = JSON.parse(existingData);
        } else {
            newData = {};
        }
        const todayDate: Date = new Date();
        const formattedDate: string = formatDate(todayDate);
        let menuCart = {
            "price": parseInt(selectedPrice),
            "quantity": parseInt(quantity),
            "foodId": foodId,
            "weight": parseInt(selectedWeight),
            "dayCount": index + 1,
            "deliveryTime": deliveryTime,
            "deliveryDate": formattedDate,
            "startTime": startTime,
            "endTime": endTime,
            "title": foodTitle
        }

        if (!newData.menuCart) {
            newData.menuCart = [];
        }
        newData.menuCart.push(menuCart);
        localStorage.setItem('menu', JSON.stringify(newData));

    }



    return (
        <>
            <AdminLayout onLayoutStateChange={handleLayoutStateChange}>
                <section className={`main-body ${isCollapsed ? '' : 'bodyCollapsed'}`}>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <h1 className='adminHeading text-center'>Menu Request</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className='mb-3 d-flex justify-content-between align-items-end'>
                                <div>
                                    <label>Search food</label>
                                    <input type='text' className='form-control' onChange={(e) => handleMenu(e.target.value)} placeholder='search food' />
                                </div>
                                <div>
                                    <button className='daysBtn' onClick={menuFunction}>Save</button>
                                    <button className='daysViewBtn' type='submit' onClick={viewMenuModal}>View</button>
                                </div>
                            </Col>
                            {itemList && itemList.length ? <>
                                <Col ms={12} className='tableList'>
                                    <Table responsive bordered hover className='align-items-center'>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Image</th>
                                                <th>Title</th>
                                                <th>Price/Weight large</th>
                                                <th>Price/Weight small</th>
                                                <th>Quantity</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {itemList && itemList.map((data, i) => <tr className='foodListTable' key={`food` + i}>
                                                <td>{i + 1}</td>
                                                <td className='text-center'>
                                                    {data && data.foodId ? (
                                                        <FoodImage foodId={data.foodId} title={data.title} imageSize="shortImage" />
                                                    ) : (
                                                        <p>No photo</p>
                                                    )}
                                                </td>
                                                <td>{data.title}</td>
                                                <td className='checkPrice'>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={`largeSize`}
                                                        id={`largeSize${i}`}
                                                        value={`${data.priceLarge} ${data.weightLarge}`}
                                                        onChange={(e) => handleOptionChange(e, i, data.title)}
                                                    />
                                                    <label htmlFor={`largeSize${i}`}>{`${data.priceLarge}лв/${data.weightLarge}`}</label>
                                                </td>
                                                <td className='checkPrice'>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={`largeSize`}
                                                        id={`smallSize${i}`}
                                                        value={`${data.priceSmall} ${data.wightSmall}`}
                                                        onChange={(e) => handleOptionChange(e, i, data.title)}
                                                    />
                                                    <label htmlFor={`smallSize${i}`}>{`${data.priceSmall}лв/${data.wightSmall}`}</label>
                                                </td>
                                                <td>
                                                    <input type="number" className='form-control' style={{ width: "80px" }} min={1} defaultValue={1} onChange={(e) => setQuantity(e.target.value)} />
                                                </td>
                                                <td>
                                                    <button className='btn btn-success' onClick={() => orderAdd(data.foodId, selectedTimes[i])}>Add</button>
                                                </td>
                                            </tr>)}
                                        </tbody>
                                    </Table>
                                    <Col md={12} className='d-flex justify-content-between'>
                                        <div className='limitBtn'>
                                            <p>Showing <b>{itemList && itemList.length}</b> of <b>{totalValue && totalValue}</b> Foods</p>
                                        </div>
                                        <div className='pagination'>
                                            <nav aria-label="Page navigation example" className='bottomPagination'>
                                                <ul className="pagination">
                                                    <li className="page-item">
                                                        <button onClick={() => prevPage()} className={prevButton === true ? "page-link" : "page-link disablePag"} aria-label="Previous">
                                                            <span aria-hidden="true">Prev</span>
                                                        </button>
                                                    </li>
                                                    <li className="page-item"><button className="page-link"><b>{pageValue}</b></button></li>
                                                    <li className="page-item">
                                                        <button onClick={() => nextPage()} className={nextButton === true ? "page-link" : "page-link disablePag"} aria-label="Next">
                                                            <span aria-hidden="true">Next</span>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </Col>
                                </Col>
                            </> : <Loader />}
                        </Row>
                    </Container>
                </section>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Menu List</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h1>Menu</h1>
                    </Modal.Body>
                </Modal>

                <ViewMenu menuItemShow={menuItemShow} handleMenuClose={handleMenuClose} />
                <ChooseDays handleSelectChange={handleSelectChange} currentDays={currentDays} menuDaysShow={menuDaysShow} handleDaysClose={handleDaysClose} menuCart={menuCart} />
            </AdminLayout>
        </>
    )
}

export default MenuAdd