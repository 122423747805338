import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Buffer } from 'buffer';
import { getFoodImage } from '../../../api/foods';
import PlaceholderImage from "../../../assets/image/placeHolder.jpg";
import 'react-lazy-load-image-component/src/effects/blur.css';

interface FoodProps {
    foodId: number;
    title: string;
    imageSize: string;
}

const FoodImage = ({ foodId, title, imageSize }: FoodProps) => {

    const [foodImage, setFoodImage] = useState<any>("")
    useEffect(() => {
        getFoodImage(foodId).then((data) => {
            setFoodImage(data.data.foodImage.photo)
        })
    }, [foodId])

    return (
        <>
            <LazyLoadImage src={`data:image/jpeg;base64,${Buffer.from(foodImage).toString('base64')}`} className={imageSize} PlaceholderSrc={PlaceholderImage} effect="blur" />
        </>
    )
}

export default FoodImage