import React, { useEffect, useState } from 'react'
import Layout from '../Common/Layout/Layout'
import { Col, Container, Row } from 'react-bootstrap'
import { convertDayName, divideArrayIntoGroups, generateSettings, getCheckedItems, groupFoodItemsByDate, jsonCheck, sortArrayByDeliveryDate } from '../../lib/commonFunction';
import { validateDiscount } from '../../api/discount';
import { getMenuProfile } from '../../api/user';
import Slider from 'react-slick';
import { getSingleFood } from '../../api/foods';
import Loader from '../Common/Loader/Loader';
import MenuCard from '../Common/MenuCard/MenuCard';
import { CreateOrder } from '../../api/order';
import { showNotifications } from '../../lib/toaster';
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { menuOption } from '../../api/menu';



const MenuCart = () => {
    const navigate = useNavigate();
    const [address, setAddress] = useState("");
    const [extraInfo, setExtraInfo] = useState("");
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [menuCart, setMenuCart] = useState<any>();
    const [menuCartList, setMenuCartList] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    let [count, setCount] = useState<number>(0);
    const [orderList, setOrderList] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [menuNotes, setMenuNotes] = useState("");

    function formatDate(dateString: string) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }


    function convertToNewFormat(originalJson: any) {
        return {
            "foodTitle": originalJson.food.title,
            "price": parseFloat(originalJson.price),
            "quantity": originalJson.quantity,
            "foodId": originalJson.food.foodId,
            "weight": parseFloat(originalJson.weight),
            "deliveryDate": formatDate(originalJson.deliveryDate),
            "days": null,
            "deliveryTime": originalJson.deliveryTime,
            "dayCount": originalJson.dayCount,
            "startTime": originalJson.startTime,
            "endTime": originalJson.endTime,
        };
    }

    useEffect(() => {
        getMenuProfile().then((data) => {
            setMenuCart(data.data.menu);
            setMenuCartList(data.data.menu.requestMenu.menuCartItem);
            let menuCartList = data.data.menu.requestMenu.menuCartItem;
            let convertedMenuCartList: any = [];
            menuCartList.forEach((item: any) => {
                let convertedItem = convertToNewFormat(item);
                convertedMenuCartList.push(convertedItem);
            });
            // new menu cart item set
            const serialDeliveryDate = sortArrayByDeliveryDate(convertedMenuCartList);
            if (serialDeliveryDate.length === 0) {
                setIsLoading(false);
                return;
            }
            Promise.all(serialDeliveryDate.map((orderItem) => getSingleFood(orderItem.foodId)))
                .then((foodResponses) => {
                    const newFoodList = foodResponses.map((data) => data.data.food);
                    const mergedList = serialDeliveryDate && serialDeliveryDate.map((orderItem, index) => ({
                        ...orderItem,
                        ...newFoodList[index],
                    }));
                    const totalPrice = mergedList && mergedList.reduce((accumulator, currentItem) => {
                        return accumulator + currentItem.price * currentItem.quantity;
                    }, 0);
                    setTotalPrice(totalPrice);
                    const dateWiseFoodItems = groupFoodItemsByDate(mergedList);
                    const weeklyGroups = divideArrayIntoGroups(dateWiseFoodItems);
                    setOrderList(weeklyGroups);
                    setIsLoading(false);
                });
        })

    }, []);


    useEffect(() => {
        const serialCount = getCheckedItems(orderList).length;
        validateDiscount(serialCount).then((data) => {
            if (data.statusCode !== 200) {
                console.log('discount', data);
            }
            else {
                setDiscountAmount(data.discountAmount)
            }
        });
    }, [orderList]);

    const maxWords = 100;
    const handleTextareaChange = (e: any) => {
        const inputValue = e.target.value;
        const words = inputValue.split(/\s+/);
        const truncatedWords = words.slice(0, maxWords);
        const truncatedText = truncatedWords.join(' ');
        setExtraInfo(truncatedText);
    };


    const confirmCart = () => {
        const checkedItems = getCheckedItems(orderList);
        if (checkedItems) {
            var jsonResult = jsonCheck(checkedItems);
            const orderCart: any = {
                "address": address,
                "cart": checkedItems
            }
            if (extraInfo) {
                orderCart.note = extraInfo;
            }
            let menuTExt = {
                "acceptMenu": true,
            }
            if (jsonResult === true) {
                menuOption(menuTExt, menuCart.requestMenu.menuRequestId).then((data) => {
                    if (data.statusCode !== 200) {
                        showNotifications('error', data.message);
                    }
                    else {
                        CreateOrder(orderCart).then((data) => {
                            if (data.statusCode !== 201) {
                                showNotifications('error', data.message);
                            }
                            else {
                                localStorage.removeItem('orderList');
                                showNotifications('success', data.message);
                                return navigate(`/checkout/${data.data.orderID}`);

                            }
                        })
                    }

                });

            }
            else {
                showNotifications('error', "Please Choose time");
            }
        }
    }

    function findFirstAndLastDelivery(data: any) {
        if (data) {
            if (data.length === 1) {
                const { date, days } = data[0];
                const firstDate = date;
                const firstDay = days;
                const lastDate = "";
                const lastDay = "";
                return { firstDelivery: { firstDate, firstDay }, lastDelivery: { lastDate, lastDay } };
            }
            else if (data.length > 1) {
                data.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());
                const firstDelivery: any = data[0];
                const lastDelivery: any = data[data.length - 1];
                const firstDate = firstDelivery.date;
                const firstDay = firstDelivery.days;
                const lastDate = lastDelivery.date;
                const lastDay = lastDelivery.days;
                return { firstDelivery: { firstDate, firstDay }, lastDelivery: { lastDate, lastDay } };
            }
        }
    }

    const result = findFirstAndLastDelivery(orderList && orderList[currentPage]);

    const menuReject = () => {
        let menuInfo={
            "acceptMenu": false,
            "note": menuNotes
        }
        if (menuNotes) {
            menuOption(menuInfo, menuCart.requestMenu.menuRequestId).then((data) => {
                if (data.statusCode !== 200) {
                    showNotifications('error', data.message);
                }else{
                    showNotifications('success', data.message);
                }
            })
        } else {
            showNotifications('error', "Please write the reason");
        }
        
    }

    return (
        <>
            <Layout>
                <ToastContainer />
                <section className="cartSection">
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                <div className="cartHeading">
                                    <h1>Cart</h1>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8} lg={9}>
                                {isLoading ? <Loader /> : <>
                                    <div className="weeklyLine">
                                        {orderList && Array.from({ length: orderList.length }, (_, index) =>
                                            <button
                                                key={index}
                                                onClick={() => setCurrentPage(index)}
                                                className={currentPage === index ? 'activeWeekly' : ''}
                                            >
                                                Week {index + 1}
                                            </button>
                                        )}
                                    </div>

                                    {orderList && orderList[currentPage].map((productItem: any, i: number) => <div className='cartLine' key={`weeklyList` + i}>
                                        <Col md={12}>
                                            <div className='cartItemHeading'>
                                                <h1>{productItem.date}</h1>
                                                <p>Daily {productItem.totalProteins.toFixed(2)}g Protein | {productItem.totalFats.toFixed(2)}g Fat | {productItem.totalCarbohydrates.toFixed(2)}g Carbo | {productItem.totalCalories.toFixed(2)}g Calories</p>
                                                <button>All Set!</button>
                                            </div>
                                        </Col>
                                        <Col md={12} className='cartTable' style={{ display: "flex", alignItems: "center" }}>

                                            {productItem.items.length > 1 ? <>
                                                <div className='w-90 cartSlider'>
                                                    <Slider {...generateSettings(2)}>
                                                        {productItem && productItem.items.map((data: any, j: number) => (
                                                            <div className="" key={`menuCard${i}`}>
                                                                <MenuCard key={`productList` + j} data={data} j={j} styleClass="singleCartCard" />
                                                            </div>
                                                        ))}
                                                    </Slider>
                                                </div>
                                            </> : <>
                                                {productItem && productItem.items.map((data: any, j: number) =>
                                                    <MenuCard key={`listProduct` + j} data={data} j={j} styleClass="singleCartCard" />
                                                )}
                                            </>}

                                        </Col>
                                    </div>)}
                                </>}
                            </Col>
                            <Col md={4} lg={3}>
                                <div className="cartPrice">
                                    <div className="menuNotes">
                                        <textarea onChange={(e) => setMenuNotes(e.target.value)} className='form-control' />
                                        <button onClick={menuReject} className='mt-4 mb-3'>Do you want to change</button>
                                    </div>
                                    <h1>Vegetarian meal plan - small</h1>
                                    <h2>Starts {result && result.firstDelivery.firstDate} {result && result.lastDelivery.lastDate ? ` to ${result.lastDelivery.lastDate}` : ""}</h2>
                                    <button onClick={confirmCart} className='mb-3'>Accept and Checkout</button>
                                    <div className="totalPrice">
                                        <p>Sub-total</p>
                                        <p>{totalPrice && totalPrice}лв</p>
                                    </div>
                                    <div className="totalPrice">
                                        <p>Discount</p>
                                        <p>{discountAmount && discountAmount}%</p>
                                    </div>
                                    <div className="totalPrice">
                                        <p>Charge</p>
                                        <p>{menuCart && menuCart.requestMenu.charge}</p>
                                    </div>
                                    <div className="totalPrice totalLine">
                                        <p>Total</p>
                                        <p>{(totalPrice && totalPrice * (1 - discountAmount / 100)).toFixed(2)}лв</p>
                                    </div>
                                    {discountAmount && discountAmount ? <h6>You Saved {discountAmount && discountAmount}% for weekly order</h6> : ""}

                                </div>
                                <div className="changeTime">
                                <div className="addressDelivery">
                                    <label className='cartText'>Enter your delivery address</label>
                                    <textarea onChange={(e) => setAddress(e.target.value)} className='form-control' placeholder='Address'></textarea>
                                </div>
                                    <div className="extraInfo">
                                        <h2 className='cartText'>Additional Information</h2>
                                        <textarea className='form-control' value={extraInfo} onChange={handleTextareaChange} ></textarea>
                                    </div>
                                </div>
                                
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        </>
    )
}

export default MenuCart
