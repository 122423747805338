import { useState } from "react";
import { LoginInterface, RegisterInterface } from "../CommonInterface/CommonInterface";
import { API } from "../config";

// signup
export const registerUser = async (body: RegisterInterface) => {
    try {
        const response = await fetch(`${API}/users/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

// login
export const loginUser = async (body: LoginInterface) => {
    try {
        const response = await fetch(`${API}/users/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

// save jwt to local storage
export const authenticate = (data: any, next: any) => {
    if (typeof window !== 'undefined') {
        localStorage.setItem("foodjwt", JSON.stringify(data));
        next();
    }
};

// is authenticated
export const isAuthenticate = () => {
    if (typeof window === 'undefined') {
        return false;
    }
    if (localStorage.getItem("foodjwt")) {
        return JSON.parse(localStorage.getItem("foodjwt") || '{}');
    } else {
        return false;
    }
};

// logout
export const logout = async () => {
    localStorage.removeItem("foodjwt");
};