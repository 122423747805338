import { API } from "../config";

export const CreateOrder = async (body: {}) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';

    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/orders/create`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

export const getSingleOrder = async (orderId: number) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/orders/${orderId}`, {
            headers: headers
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

export const getMyOrder = async () => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/orders/my-order`, {
            headers: headers
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

export const getOrderList = async (sortPropertyOne: string, sortPropertyTwo: string, sortOne: string, sortTwo: string, limit: number, page: number,deliveryDate :string) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/orders?sortByOptionOne=${sortPropertyOne}&sortOrderOne=${sortOne}&sortByOptionTwo=${sortPropertyTwo}&sortOrderTwo=${sortTwo}&limit=${limit}&page=${page}&deliveryDate=${deliveryDate}`, {
            headers: headers
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

export const orderStatusApi = async (body: {}) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
        };
        const response = await fetch(`${API}/orders/cart-status`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};