import React, { useEffect, useState } from 'react'
import { getFoodList } from '../../../api/foods'
import { DeliveryInterface, FoodInterface } from '../../../CommonInterface/CommonInterface';
import { Container, Modal, Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import FoodImage from '../../Common/FoodImage/FoodImage';
import { getMenuUpdate } from '../../../api/menu';
import { getDeliveryTime } from '../../../api/deliverytime';
import { convertTimeArrayFormat, convertToDateOnly, splitTimeRange } from '../../../lib/commonFunction';
import { showNotifications } from '../../../lib/toaster';


interface FoodProps {
    show: any;
    handleCloseModal: any;
    daysOfMenu: string;
    daysOfDate: string;
    menuRequestId:string;
    setShow:any;
}

const MenuFood = ({ show,setShow, handleCloseModal, daysOfMenu, daysOfDate,menuRequestId }: FoodProps) => {
    const [limit, setLimit] = useState<number>(10);
    const [itemList, setItemList] = useState<FoodInterface[] | undefined>([]);
    const [prevButton, setPrevButton] = useState<boolean>(false);
    const [nextButton, setNextButton] = useState<boolean>(false);
    const [pageValue, setPageValue] = useState<number>();
    const [totalValue, setTotalValue] = useState<number>(0);
    const [page, setPage] = useState(1);
    const pageCount = Math.ceil(totalValue / limit);
    const [noFoods, setNoFoods] = useState<boolean>(false);
    const [lunchList, setLunchList] = useState<DeliveryInterface[]>([]);
    const [breakfastList, setBreakfastList] = useState<DeliveryInterface[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<any>({});
    const [quantities, setQuantities] = useState<any>({});
    const [selectedTimes, setSelectedTimes] = useState<any>({});


    useEffect(() => {
        let body = { "tags": [], "featured": null }
        getFoodList(page, limit, body).then((data) => {
            if (data.data.foods.length === 0) {
                setNoFoods(true);
            }
            else {
                setNoFoods(false);
                setItemList(data.data.foods);
                setPageValue(data.data.currentPage);
                setTotalValue(data.data.total);
            }
        })
        if (pageCount > 1) {
            setPrevButton(true)
        }
        if (page === 1) {
            setPrevButton(false)
        }
        // next button
        if (pageCount > 1) {
            setNextButton(true)
        }
        if (pageCount === page) {
            setNextButton(false)
        }
    }, [page, pageCount])

    // pagination

    const nextPage = () => {
        setPage(page + 1)
    }

    const prevPage = () => {
        setPage(page - 1)
    }

    useEffect(() => {
        getDeliveryTime("LUNCH").then((data) => {
            let arrayTime = convertTimeArrayFormat(data.data.deliveryTimes);
            setLunchList(arrayTime)
        });
        getDeliveryTime("BREAKFAST").then((data) => {
            let arrayTime = convertTimeArrayFormat(data.data.deliveryTimes);
            setBreakfastList(arrayTime)
        });
    }, []);


    const handleOptionChange = (e: any, foodId: any, sizeType: any) => {
        const { value } = e.target;
        const [price, weight] = value.split(" ");
        setSelectedOptions((prevState: any) => ({
            ...prevState,
            [foodId]: {
                ...prevState[foodId],
                price,
                weight,
                sizeType
            }
        }));
    };

    const handleSelectChange = (e: any, foodId: any, mealType: any) => {
        const [startTime, endTime] = e.target.value.split('-');
        setSelectedOptions((prevState: any) => ({
            ...prevState,
            [foodId]: {
                ...prevState[foodId],
                [`${mealType}StartTime`]: startTime,
                [`${mealType}EndTime`]: endTime
            }
        }));
    };

    const setQuantity = (e: any, foodId: any) => {
        setQuantities((prevState: any) => ({
            ...prevState,
            [foodId]: e.target.value
        }));
    };

    const handleTimeChange = (e: any, foodId: any) => {
        setSelectedTimes((prevState: any) => ({
            ...prevState,
            [foodId]: e.target.value
        }));
    };


    const foodMenuAdd = (index: any) => {
        const data: any = itemList && itemList[index];
        const foodId = data.foodId;
        const selectedData = selectedOptions[foodId] || {};
        const quantity = quantities[foodId] || 1;
        const selectedTime: any = selectedTimes[foodId] || '';

        let menuInfo = {
            price: parseFloat(selectedData.price) || 0,
            weight: parseFloat(selectedData.weight) || 0,
            deliveryTime: selectedTime,
            quantity: parseInt(quantity) || 1,
            startTime: selectedData.breakfastStartTime || selectedData.lunchStartTime || '',
            endTime: selectedData.breakfastEndTime || selectedData.lunchEndTime || '',
            foodId: parseInt(foodId) || 0,
            deliveryDate: convertToDateOnly(daysOfDate),
            dayCount: parseInt(daysOfMenu) || 0
        };
 
        getMenuUpdate(menuRequestId,menuInfo).then((data) => {
          if (data.statusCode !== 200) {
            showNotifications('error',data.message);
          } else {
            setShow(false)
            showNotifications('success',data.message);
          }
         
        });
    };

    return (
        <Modal show={show} onHide={handleCloseModal} centered scrollable size="xl">
        <h2 className='foodText'>Food list</h2>
        <div style={{ maxHeight: "500px", overflowY: "auto" }}>
            <Container>
                <Row>
                    <Col ms={12} className='tableList'>
                        <Table responsive bordered hover className='align-items-center'>
                            <thead style={{ position: "sticky", top: 0, backgroundColor: "#fff", zIndex: 1 }}>
                                <tr>
                                    <th>#</th>
                                    <th>Title</th>
                                    <th>Food type</th>
                                    <th>Price large</th>
                                    <th>Price small</th>
                                    <th>Delivery time</th>
                                    <th>Start time</th>
                                    <th>End time</th>
                                    <th>Quantity</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {itemList && itemList.map((data, i) => (
                                    <tr className='foodListTable' key={`food` + i}>
                                        <td>{pageValue && pageValue === 1 ? i + 1 : i === 9 ? `${(pageValue && (pageValue))}${0}` : `${(pageValue && (pageValue - 1))}${(i + 1)}`}</td>
                                        <td>{data.title}</td>
                                        <td>{data && data.foodType === "VEG" ? "Vegetarian" : "Non-Vegetarian"}</td>
                                        <td className='checkPrice'>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name={`price${i}`}
                                                id={`largeSize${i}`}
                                                value={`${data.priceLarge} ${data.weightLarge}`}
                                                onChange={(e) => handleOptionChange(e, data.foodId, 'priceLarge')}
                                            />
                                            <label htmlFor={`largeSize${i}`}>{`${data.priceLarge}лв`}<br />{`${data.weightLarge}`}</label>
                                        </td>
                                        <td className='checkPrice'>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name={`price${i}`}
                                                id={`smallSize${i}`}
                                                value={`${data.priceSmall} ${data.wightSmall}`}
                                                onChange={(e) => handleOptionChange(e, data.foodId, 'priceSmall')}
                                            />
                                            <label htmlFor={`smallSize${i}`}>{`${data.priceSmall}лв`}<br />{`${data.wightSmall}`}</label>
                                        </td>
                                        <td>
                                            <select className="form-control" onChange={(e) => handleTimeChange(e, data.foodId)}>
                                                <option value="">Choose time</option>
                                                <option value="BREAKFAST">Breakfast</option>
                                                <option value="LUNCH">Lunch</option>
                                            </select>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <select className="form-select" id="inputGroupSelect01" onChange={(e) => handleSelectChange(e, data.foodId, 'breakfast')}>
                                                <option value="">Choose time</option>
                                                {breakfastList.map((option, i) => {
                                                    const value = `${option.startTime}-${option.endTime}`;
                                                    return (
                                                        <option key={`breakfast${i}`} value={value} disabled={value === `${data.startTime}-${data.endTime}`}>
                                                            {option.startTime}-{option.endTime}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </td>
                                        <td style={{ width: "150px" }}>
                                            <select className="form-select" id="inputGroupSelect01" onChange={(e) => handleSelectChange(e, data.foodId, 'lunch')}>
                                                <option value="">Choose time</option>
                                                {lunchList.map((option, i) => {
                                                    const value = `${option.startTime}-${option.endTime}`;
                                                    return (
                                                        <option key={`lunch${i}`} value={value} disabled={value === `${data.startTime}-${data.endTime}`}>
                                                            {option.startTime}-{option.endTime}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </td>
                                        <td>
                                            <input type="number" className='form-control' style={{ width: "60px" }} min={1} defaultValue={1} onChange={(e) => setQuantity(e, data.foodId)} />
                                        </td>
                                        <td>
                                            <button className='btn btn-success' onClick={() => foodMenuAdd(i)}>Add</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Col md={12} className='d-flex justify-content-between'>
                            <div className='limitBtn'>
                                <p>Showing <b>{itemList && itemList.length}</b> of <b>{totalValue && totalValue}</b> Foods</p>
                            </div>
                            <div className='pagination'>
                                <nav aria-label="Page navigation example" className='bottomPagination'>
                                    <ul className="pagination">
                                        <li className="page-item">
                                            <button onClick={() => prevPage()} className={prevButton === true ? "page-link" : "page-link disablePag"} aria-label="Previous">
                                                <span aria-hidden="true">Prev</span>
                                            </button>
                                        </li>
                                        <li className="page-item"><button className="page-link"><b>{pageValue}</b></button></li>
                                        <li className="page-item">
                                            <button onClick={() => nextPage()} className={nextButton === true ? "page-link" : "page-link disablePag"} aria-label="Next">
                                                <span aria-hidden="true">Next</span>
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </Col>
                    </Col>
                </Row>
            </Container>
        </div>
    </Modal>
    
    )
}

export default MenuFood