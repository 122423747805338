import { API } from "../config";

// discount create
export const addDiscount = async (body = {}) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
        };
        const response = await fetch(`${API}/discounts/create`, {
            method: 'POST',
            headers: {
                ...headers,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

// get discount list
export const getDiscount = async () => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const response = await fetch(`${API}/discounts`, {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

// delete discount item
export const deleteDiscountItem = async (discountId: number) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const response = await fetch(`${API}/discounts/${discountId}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

// update discount item
export const updateDiscountItem = async (discountId: number, body: {}) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const headers = {
            Authorization: `Bearer ${jwtToken}`,
        };
        const response = await fetch(`${API}/discounts/${discountId}`, {
            method: 'PUT',
            headers: {
                ...headers,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};

// validate discount
export const validateDiscount = async (discountDays: number) => {
    const token = localStorage.getItem("foodjwt") || '{}';
    const tokenObject = JSON.parse(token);
    const jwtToken = tokenObject.jwt || '';
    try {
        const response = await fetch(`${API}/discounts/validate/${discountDays}`, {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
        });
        return await response.json();
    } catch (error) {
        return error;
    }
};