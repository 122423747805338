import React, { useEffect, useState } from 'react'
import AdminLayout from '../../Common/AdminLayout/AdminLayout'
import { useParams } from 'react-router'
import { getSingleFood } from '../../../api/api';
import { Col, Container, Row } from 'react-bootstrap';
import { FoodInterface } from '../../../CommonInterface/CommonInterface';
import { Buffer } from 'buffer';
import Loader from '../../Common/Loader/Loader';


const FoodDetails: React.FC = () => {
  const { id }: any = useParams();
  const [foodInfo, setFoodInfo] = useState<FoodInterface | undefined>();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleLayoutStateChange = (clickedInParent: boolean) => {
    setIsCollapsed(!clickedInParent);
  };
  useEffect(() => {
    getSingleFood(id).then((data) => {
      setFoodInfo(data.data.food);
    })
  }, []);

  return (
    <div>
      <AdminLayout onLayoutStateChange={handleLayoutStateChange}>
        <section className={`main-body ${isCollapsed ? '' : 'bodyCollapsed'}`}>
          <Container>
            <Row className='d-flex justify-content-center'>
              <Col md={8}>
                {foodInfo && foodInfo ? 
                <Row className='foodDetails'>
                <Col md={12}>
                  <div className="foodHeading">
                    <h2>Food Details</h2>
                  </div>
                </Col>
                <Col md={12} className='d-flex justify-content-center mb-4'>
                  <div className='createImage'>
                    {foodInfo && foodInfo.photo ? (
                      <img src={`data:image/jpeg;base64,${Buffer.from(foodInfo.photo).toString('base64')}`} alt={foodInfo.title} width="200px" height="200px" />
                    ) : (
                      <p>No photo available</p>
                    )}
                  </div>
                </Col>
                <Col md={6}>
                  <h1><b>Title:</b> {foodInfo && foodInfo.title}</h1>
                </Col>
                <Col md={6}>
                  <h1><b>Food Category:</b> {foodInfo && foodInfo.category}</h1>
                </Col>
               
                <Col md={6}>
                  <h1><b>Price large:</b> {foodInfo && foodInfo.priceLarge}</h1>
                </Col>
                <Col md={6}>
                  <h1><b>Price small:</b> {foodInfo && foodInfo.priceSmall}</h1>
                </Col>
                <Col md={6}>
                  <h1><b>WightSmall:</b> {foodInfo && foodInfo.wightSmall}</h1>
                </Col>
                <Col md={6}>
                  <h1><b>WeightLarge:</b> {foodInfo && foodInfo.weightLarge}</h1>
                </Col>
                <Col md={6}>
                  <h1><b>Small Discount:</b> {foodInfo && foodInfo.discount}</h1>
                </Col>
                <Col md={6}>
                  <h1><b>Big Discount:</b> {foodInfo && foodInfo.discountBig}</h1>
                </Col>
                <Col md={6}>
                  <h1><b>Food type:</b> {foodInfo && foodInfo.foodType === "VEG" ? "Vegetarian" : "Non-Vegetarian"}</h1>
                </Col>
                <Col md={6}>
                  <h1><b>Carbohydrates:</b> {foodInfo && foodInfo.carbohydrates}</h1>
                </Col>
                <Col md={6}>
                  <h1><b>Calories:</b> {foodInfo && foodInfo.calories}</h1>
                </Col>
               
                <Col md={6}>
                  <h1><b>Proteins:</b> {foodInfo && foodInfo.proteins}</h1>
                </Col>
                <Col md={6}>
                  <h1><b>fats:</b> {foodInfo && foodInfo.fats}</h1>
                </Col>
                <Col md={6}>
                  <h1><b>Vitamines:</b> {foodInfo && foodInfo.vitamines}</h1>
                </Col>
                <Col md={6}>
                  <h1><b>Minerals:</b> {foodInfo && foodInfo.minerals}</h1>
                </Col>
                <Col md={6}>
                  <h1><b>Allergens:</b> {foodInfo && foodInfo.allergens}</h1>
                </Col>
                <Col md={12}>
                  <h1><b>Tags:</b> {foodInfo && foodInfo.tags}</h1>
                </Col>
                <Col md={12}>
                  <h1><b>Description:</b> {foodInfo && foodInfo.description}</h1>
                </Col>
              </Row>: <Loader />}
                
              </Col>
            </Row>
          </Container>
        </section>
      </AdminLayout>
    </div>
  );
};

export default FoodDetails;
