import React, { useEffect } from 'react';
import { getDeliveryTime } from '../../../api/deliverytime';
import { convertTimeArrayFormat } from '../../../lib/commonFunction';
import FoodImage from '../FoodImage/FoodImage';

interface CardProps {
    data: any;
    j: number;
    increaseItem?: any;
    decreaseItem?: any;
    styleClass: string;
}

const MenuCard = ({ data, j, styleClass }: CardProps) => {
    

  return (
    <div className={styleClass} key={`cartItem` + j}>
    <div style={{ display: 'flex' }}>
        <div className="cartImage">
            {data && data.foodId ? (
                <FoodImage foodId={data.foodId} title={data.title} imageSize="" />
            ) : (
                <p>No photo</p>
            )}
        </div>
        <div className="cartContent">
            <h1>{data.deliveryTime}</h1>
            <h2>{window.innerWidth <= 768 ? data.title.slice(0, 10) : data.title.slice(0, 22)}</h2>
            <h3>Price: {data.price}</h3>
            <h4>Time: {data.startTime}-{data.endTime}</h4>
            <div className="energyList largeView">
                <div className="energyValue">
                    <p>{(data.proteins * data.weight).toFixed(2)}gr</p>
                    <p>Proteins</p>
                </div>
                <div className="energyValue">
                    <p>{(data.fats * data.weight).toFixed(2)}gr</p>
                    <p>Fats</p>
                </div>
                <div className="energyValue">
                    <p>{(data.carbohydrates * data.weight).toFixed(2)}gr</p>
                    <p>carbs</p>
                </div>
                <div className="energyValue">
                    <p>{(data.calories * data.weight).toFixed(2)}gr</p>
                    <p>Calories</p>
                </div>
            </div>
        </div>
    </div>
    <div className="energyList smallView">
        <div className="energyValue">
            <p>{(data.proteins * data.weight).toFixed(2)}gr</p>
            <p>Proteins</p>
        </div>
        <div className="energyValue">
            <p>{(data.fats * data.weight).toFixed(2)}gr</p>
            <p>Fats</p>
        </div>
        <div className="energyValue">
            <p>{(data.carbohydrates * data.weight).toFixed(2)}gr</p>
            <p>carbs</p>
        </div>
        <div className="energyValue">
            <p>{(data.calories * data.weight).toFixed(2)}gr</p>
            <p>Calories</p>
        </div>
    </div>
    <div className='cartNumber text-center justify-content-center'>
        <p>{data.quantity}</p>
    </div>
</div>
  )
}

export default MenuCard