import React from 'react';
import { BrowserRouter, Route, Routes, Router } from "react-router-dom";
import Home from './Component/Home/Home';
import Auth from './Component/Auth/Auth';
import AddFood from './Component/Admin/AddFood/AddFood';
import FoodList from './Component/Admin/FoodList/FoodList';
import FoodDetails from './Component/Admin/FoodDetails/FoodDetails';
import EditFood from './Component/Admin/FoodList/EditFood';
import PrivateRoute from './api/PrivateRoute';
import UserProfile from './Component/UserProfile/UserProfile';
import FoodView from './Component/FoodView/FoodView';
import AdminProfile from './Component/Admin/AdminProfile/AdminProfile';
import UserList from './Component/Admin/UserList/UserList';
import CartPage from './Component/CartPage/CartPage';
import Checkout from './Component/Checkout/Checkout';
import Menu from './Component/Menu/Menu';
import FeatureCarousel from './Component/Admin/FeatureCarousel/FeatureCarousel';
import OrderDetails from './Component/OrderDetails/OrderDetails';
import DeliveryTime from './Component/Admin/DeliveryTime/DeliveryTime';
import OrderList from './Component/Admin/OrderList/OrderList';
import Discount from './Component/Admin/Discount/Discount';
import MenuSet from './Component/Admin/MenuSet/MenuSet';
import CreateMenu from './Component/CreateMenu/CreateMenu';
import MenuAdd from './Component/Admin/MenuSet/MenuAdd';
import MenuRequest from './Component/Admin/MenuRequest/MenuRequest';
import ViewRequest from './Component/Admin/MenuRequest/ViewRequest';
import MenuInfo from './Component/Admin/MenuSet/MenuInfo';
import MenuCart from './Component/MenuCart/MenuCart';
import DeliveryRoute from './api/DeliveryRoute';


const Routing = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* admin guard */}
          <Route element={<PrivateRoute />}>
            <Route path="/add_food" element={<AddFood />}></Route>
            <Route path="/foodList" element={<FoodList />}></Route>
            <Route path="/foodDetails/:id" element={<FoodDetails />}></Route>
            <Route path="/editFood/:id" element={<EditFood />}></Route>
            <Route path="/delivery_time" element={<DeliveryTime />}></Route>
            <Route path="/order_list" element={<OrderList />}></Route>
            <Route path="/single_order" element={<OrderList />}></Route>
            <Route path="/discount" element={<Discount />}></Route>
            <Route path="/menu-set" element={<MenuSet />}></Route>
            <Route path="/create-menu" element={<MenuAdd />}></Route>
            <Route path="/menu-request" element={<MenuRequest />}></Route>
            <Route path="/view-request/:id" element={<ViewRequest />}></Route>
            <Route path="/menu/:id" element={<MenuInfo />}></Route>
          </Route>
          <Route element={<DeliveryRoute />}>
            <Route path="/client_order" element={<OrderList />}></Route>
          </Route>
          {/* admin */}
          <Route path="/admin_profile" element={<AdminProfile />}></Route>
          <Route path="/user_list" element={<UserList />}></Route>
          <Route path="/feature" element={<FeatureCarousel />}></Route>
          {/* home */}
          <Route path="/" element={<Home />}></Route>
          <Route path="/auth" element={<Auth />}></Route>
          <Route path="/profile" element={<UserProfile />}></Route>
          <Route path="/food_view/:id" element={<FoodView />}></Route>
          <Route path="/cart" element={<CartPage />}></Route>
          <Route path="/checkout/:id" element={<Checkout />}></Route>
          <Route path="/menu" element={<Menu />}></Route>
          <Route path="/order/:id" element={<OrderDetails />}></Route>
          <Route path="/create-menu" element={<CreateMenu />}></Route>
          <Route path="/menu-cart" element={<MenuCart />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default Routing