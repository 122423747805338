import React, { useState, useEffect } from 'react';
import Layout from '../Common/Layout/Layout';
import "./Menu.css";
import { Col, Container, Row } from 'react-bootstrap';
import { getFoodList } from '../../api/foods';
// carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from '../Common/Card/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faInfo, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import TagInfo from './TagInfo';
import { Link } from 'react-router-dom';
import OrderSidebar from '../Common/OrderSidebar/OrderSidebar';
// icon image
import ANTIOXIDANTS from "../../assets/icon/blueberry.png";
import CASEIN from "../../assets/icon/casein.png";
import RICH_IN_COLLAGEN from "../../assets/icon/collagen.png";
import GLUTEN_FREE from "../../assets/icon/gluten-free.png";
import IN_GOUT from "../../assets/icon/gout.png";
import WITH_HIGH_BLOOD_PRESSURE from "../../assets/icon/hypertension.png";
import NO_OXALATES from "../../assets/icon/kidney.png";
import WITH_GASTRITIS from "../../assets/icon/medical.png";
import N0_CRABS from "../../assets/icon/no-carbs.png";
import NO_FAT from "../../assets/icon/no-fat.png";
import HIGH_PROTEIN from "../../assets/icon/protein.png";
import VEGETARIAN from "../../assets/icon/salad.png";
import WITHOUT_SUGAR from "../../assets/icon/sugar-free.png";
import VEGAN from "../../assets/icon/vegan.png";
import VITAMINS from "../../assets/icon/vitamin.png";
import RICH_IN_GOOD_FATS from '../../assets/icon/RICH_IN_GOOD_FATS.png';
import { Circles } from 'react-loader-spinner';
import { menuRequest } from '../../api/menu';
import { getMenuProfile } from '../../api/user';
import { showNotifications } from '../../lib/toaster';
import MenuModal from './MenuModal';
import { countActiveFoods } from '../../lib/commonFunction';

const Menu = () => {
  const [show, setShow] = useState<boolean>(false);
  const [daysMenu, setDaysMenu] = useState<number>(0);
  const handleShow = () => setShow(true);
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const [parentState, setParentState] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [activeCategory, setActiveCategory] = useState<string>("MENU");
  const [orderList, setOrderList] = useState<any>([]);
  const [categorizedItems, setCategorizedItems] = useState<any>([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const [sidebarMenu, setSidebarMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [personalMenu, setPersonalMenu] = useState(false);
  const [loginMenu, setLoginMenu] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  const handleMenuClose = () => setMenuShow(false);


  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    getMenuProfile().then((data) => {
      if (data.statusCode === 200) {
        setDaysMenu(data.data.profile.daysOfMenu);
        setPersonalMenu(true);
      }
      if (data.statusCode === 401) {
        setLoginMenu(true);
      }
    })
    let body = { "tags": activeFilters, "category": activeCategory }
    getFoodList(1, 500, body, true).then((data) => {
      setIsLoading(true);
      if (data.statusCode !== 200) {
        console.log('no data');
      } else {
        for (let category in data.data.foods) {
          data.data.foods[category] = data.data.foods[category].filter((item: any) => item.isActive);
        }
        setCategorizedItems(data.data.foods);
        setIsLoading(false);
      }

    })
    const storedOrderList = localStorage.getItem('orderList');
    if (storedOrderList) {
      setOrderList(JSON.parse(storedOrderList));
    }
    if (screenWidth < 778) {
      setSidebarMenu(true)
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [activeCategory, activeFilters, isLoading]);




  const categoryAdd = (categoryName: string) => {

    if (activeCategory !== categoryName) setIsLoading(true);
    setActiveCategory(categoryName);
  }

  const handleButtonClick = (filterId: string) => {

    const index = activeFilters.indexOf(filterId);
    setCount(count + 1);
    if (index === -1) {
      setIsLoading(true);
      setActiveFilters([...activeFilters, filterId]);
    } else {
      setIsLoading(true);
      setActiveFilters(activeFilters.filter(id => id !== filterId));
    }
  };


  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const CustomPrevArrow: React.FC<React.HTMLProps<HTMLDivElement>> = (props) => (
    <div {...props} style={{ ...props.style, display: 'block', background: 'transparent' }}>
      Previous
    </div>
  );

  const CustomNextArrow: React.FC<React.HTMLProps<HTMLDivElement>> = (props) => (
    <div {...props} style={{ ...props.style, display: 'block', background: 'transparent' }}>
      Next
    </div>
  );

  const generateSettings = (itemCount: number) => {
    return {
      dots: false,
      autoplaySpeed: 2000,
      autoplay: false,
      slidesToShow: itemCount,
      slidesToScroll: 1,
      infinite: true,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1990,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: false,
            prevArrow: <CustomPrevArrow />,
            nextArrow: <CustomNextArrow />,
            infinite: true,
          },
        },
        {
          breakpoint: 1334,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            prevArrow: <CustomPrevArrow />,
            nextArrow: <CustomNextArrow />,
            infinite: true,
          },
        },
        {
          breakpoint: 670,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            prevArrow: <CustomPrevArrow />,
            nextArrow: <CustomNextArrow />,
            infinite: true,
          },
        },
      ],
    };
  };


  // menu request
  const sendMenuRequest = () => {
    setMenuShow(true);
  }



  return (
    <Layout>
      <section className="menu-main-body">
        <div className='menuPage'>
          <Container fluid>
            <Row>
              <Col md={9}>
                <div className="main-content menuLeft">
                  <div className="tagHeading">
                    <div className="menu-bar">
                      <button onClick={() => categoryAdd("MENU")} className={activeCategory === "MENU" ? 'activeOption button-50' : 'disableOption button-50'}>
                        <img src="https://delarakazakova.github.io/Foodplan/img/menus.png" alt="menus" />Menu
                      </button>

                      <button onClick={() => categoryAdd("PORTION")} className={activeCategory === "PORTION" ? 'activeOption button-50' : 'disableOption button-50'}>
                        <img src="https://delarakazakova.github.io/Foodplan/img/meals.png" alt="meals" />Portion
                      </button>

                      {loginMenu ? <Link className="requestMenu" to="/auth"><FontAwesomeIcon icon={faUserCheck} /> Create a personal menu</Link>
                        : <>{personalMenu ? <Link className="requestMenu" to="#" onClick={sendMenuRequest}><FontAwesomeIcon icon={faUserCheck} /> Request a personal menu</Link>
                          : <Link className="requestMenu" to="/profile"><FontAwesomeIcon icon={faUserCheck} /> Create a personal menu</Link>}
                        </>}


                    </div>
                  </div>
                  <div className="tag-bar">
                    <span className='tagInfo' onClick={handleShow}><FontAwesomeIcon icon={faInfo} /></span>
                    <button type="button" className={`btn btn-outline-success rounded-pill filter-button ${activeFilters.includes('') ? 'active' : ''}`} onClick={() => handleButtonClick('')}>All</button>
                    <button type="button" className={`btn btn-outline-success rounded-pill filter-button ${activeFilters.includes('ANTIOXIDANTS') ? 'active' : ''}`} onClick={() => handleButtonClick('ANTIOXIDANTS')} ><img className="icon" src={ANTIOXIDANTS} alt="" />Antioxidants</button>
                    <button type="button" className={`btn btn-outline-success rounded-pill filter-button ${activeFilters.includes('N0_CRABS') ? 'active' : ''}`} onClick={() => handleButtonClick('N0_CRABS')}><img className="icon" src={N0_CRABS} alt="" />No carbs</button>
                    <button type="button" className={`btn btn-outline-success rounded-pill filter-button ${activeFilters.includes('GLUTEN_FREE') ? 'active' : ''}`} onClick={() => handleButtonClick('GLUTEN_FREE')}><img className="icon" src={GLUTEN_FREE} alt="" />Gluten free</button>
                    <button type="button" className={`btn btn-outline-success rounded-pill filter-button ${activeFilters.includes('WITHOUT_SUGAR') ? 'active' : ''}`} onClick={() => handleButtonClick('WITHOUT_SUGAR')}><img className="icon" src={WITHOUT_SUGAR} alt="" />Without sugar</button>
                    <button type="button" className={`btn btn-outline-success rounded-pill filter-button ${activeFilters.includes('NO_FAT') ? 'active' : ''}`} onClick={() => handleButtonClick('NO_FAT')}><img className="icon" src={NO_FAT} alt="" />No fat</button>
                    <button type="button" className={`btn btn-outline-success rounded-pill filter-button ${activeFilters.includes('NO_OXALATES') ? 'active' : ''}`} onClick={() => handleButtonClick('NO_OXALATES')}><img className="icon" src={NO_OXALATES} alt="" />No oxalates</button>
                    <button type="button" className={`btn btn-outline-success rounded-pill filter-button ${activeFilters.includes('NO_CHOLESTEROL') ? 'active' : ''}`} onClick={() => handleButtonClick('NO_CHOLESTEROL')}><img className="icon" src={VEGETARIAN} alt="" />No cholesterol</button>
                    <button type="button" className={`btn btn-outline-success rounded-pill filter-button ${activeFilters.includes('RICH_IN_GOOD_FATS') ? 'active' : ''}`} onClick={() => handleButtonClick('RICH_IN_GOOD_FATS')}><img className="icon" src={RICH_IN_GOOD_FATS} alt="" />Good fat</button>
                    <button type="button" className={`btn btn-outline-success rounded-pill filter-button ${activeFilters.includes('RICH_IN_COLLAGEN') ? 'active' : ''}`} onClick={() => handleButtonClick('RICH_IN_COLLAGEN')}><img className="icon" src={RICH_IN_COLLAGEN} alt="" />Rich in collagen</button>
                    <button type="button" className={`btn btn-outline-success rounded-pill filter-button ${activeFilters.includes('VEGAN') ? 'active' : ''}`} onClick={() => handleButtonClick('VEGAN')}><img className="icon" src={VEGAN} alt="" />Vegan</button>
                    <button type="button" className={`btn btn-outline-success rounded-pill filter-button ${activeFilters.includes('VEGETARIAN') ? 'active' : ''}`} onClick={() => handleButtonClick('VEGETARIAN')}><img className="icon" src={VEGETARIAN} alt="" />Vegetarian</button>
                    <button type="button" className={`btn btn-outline-success rounded-pill filter-button ${activeFilters.includes('HIGH_PROTEIN') ? 'active' : ''}`} onClick={() => handleButtonClick('HIGH_PROTEIN')}><img className="icon" src={HIGH_PROTEIN} alt="" />High protein</button>
                    <button type="button" className={`btn btn-outline-success rounded-pill filter-button ${activeFilters.includes('VITAMINS') ? 'active' : ''}`} onClick={() => handleButtonClick('VITAMINS')}><img className="icon" src={VITAMINS} alt="" />Vitamins</button>
                    <button type="button" className={`btn btn-outline-success rounded-pill filter-button ${activeFilters.includes('CASEIN') ? 'active' : ''}`} onClick={() => handleButtonClick('CASEIN')}><img className="icon" src={CASEIN} alt="" />Casein</button>
                    <button type="button" className={`btn btn-outline-success rounded-pill filter-button ${activeFilters.includes('WITH_HIGH_BLOOD_PRESSURE') ? 'active' : ''}`} onClick={() => handleButtonClick('WITH_HIGH_BLOOD_PRESSURE')}><img className="icon" src={WITH_HIGH_BLOOD_PRESSURE} alt="" />With high blood pressure</button>
                    <button type="button" className={`btn btn-outline-success rounded-pill filter-button ${activeFilters.includes('WITH_GASTRITIS') ? 'active' : ''}`} onClick={() => handleButtonClick('WITH_GASTRITIS')}><img className="icon" src={WITH_GASTRITIS} alt="" />With gastritis</button>
                    <button type="button" className={`btn btn-outline-success rounded-pill filter-button ${activeFilters.includes('IN_GOUT') ? 'active' : ''}`} onClick={() => handleButtonClick('IN_GOUT')}><img className="icon" src={IN_GOUT} alt="" />In gout</button>
                  </div>
                  <div className="itemCarousel">
                    {isLoading && isLoading ? <>
                      <div className='d-flex justify-content-center align-items-center' style={{ height: '20vh' }}>
                        <Circles
                          height="80"
                          width="80"
                          color="#4fa94d"
                          ariaLabel="circles-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </div>
                    </> : <>
                      {Object.entries(categorizedItems).map(([category, productItems]: any, i: number) => (
                        <div key={`menuItemCard${i}` + i}>
                          <h2 className='itemHead'>
                            <span>{category === "MENU" ? "Menu" : category === "SALAD" ? "Salad" : category === "SNACK" ? "Snack" : category === "SOUP" ? "Soup" : category === "MAIN_DISH" ? "Main Dish" : category === "DESSERT" ? "Dessert" : category === "DRINKS" ? "Drinks" : category === "SANDWICHES" ? "Sandwiches" : ""}</span>
                          </h2>
                          {productItems && countActiveFoods(productItems) === 1 ? <>
                            <div className='d-flex justify-content-left'>
                              <div className="oneSlider">
                                {productItems && productItems.map((data: any, j: number) => (
                                  <div className="" key={`menuCard` + j}>
                                    {data.isActive === true &&
                                      <Card styleClass={`menu_item`} data={data} setParentState={setParentState} parentState={parentState} />
                                    }
                                  </div>
                                ))}
                              </div>
                            </div>
                          </> : productItems && countActiveFoods(productItems) === 2 ? <>
                            <div className=''>
                              <div className="twoSlider">
                                {productItems && productItems.map((data: any, k: number) => (
                                  <div className="" key={`menuProduct` + k}>
                                    {data.isActive === true &&
                                      <Card styleClass={`menu_item`} data={data} setParentState={setParentState} parentState={parentState} />
                                    }
                                  </div>
                                ))}
                              </div>
                            </div>
                          </> : countActiveFoods(productItems) === 3 ? <>
                            <div className=''>
                              <div className="threeSlider">
                                <Slider {...generateSettings(3)}>
                                  {productItems && productItems.map((data: any, l: number) => (
                                    <div className="" key={`menuSlider` + l}>
                                      {data.isActive === true &&
                                        <Card styleClass={`menu_item`} data={data} setParentState={setParentState} parentState={parentState} />
                                      }
                                    </div>
                                  ))}
                                </Slider>
                              </div>
                            </div>
                          </> : <>
                            <Slider {...generateSettings(4)}>
                              {productItems && productItems.map((data: any, m: number) => (
                                <div className="" key={`menuAll` + m}>
                                  {data.isActive === true &&
                                    <Card styleClass={`menu_item`} data={data} setParentState={setParentState} parentState={parentState} />
                                  }
                                </div>
                              ))}
                            </Slider>
                          </>}
                        </div>
                      ))}
                    </>}
                    {Object.keys(categorizedItems).length === 0 ? <><h1 className='NoItemHead'>No Product Found!</h1></> : <></>}
                  </div>
                </div>
              </Col>
              <Col md={3}>
                {sidebarMenu && sidebarMenu ? <>
                  <div className={`rightSidebar ${isCollapsed ? "" : "collapsedSidebar"}`}>
                    <button className='cartBtn' onClick={toggleSidebar}><FontAwesomeIcon icon={faCartShopping} /><span>{orderList.length}</span></button>
                    <div className="sideItems">
                      <OrderSidebar parentState={parentState} orderList={orderList} setOrderList={setOrderList} />
                    </div>
                  </div></> : <>
                  <div style={{ position: 'relative', width: '-moz-available', marginLeft: "25px" }}>
                    <div className='sideItemsBox'>
                      <OrderSidebar parentState={parentState} orderList={orderList} setOrderList={setOrderList} />
                    </div>
                  </div></>}
              </Col>
            </Row>
          </Container>
        </div>
        <TagInfo show={show} setShow={setShow} />
        <MenuModal daysMenu={daysMenu} menuShow={menuShow} setMenuShow={setMenuShow} handleMenuClose={handleMenuClose} />
      </section>
    </Layout>
  )
}

export default Menu