import React, { useEffect, useState } from "react";
import { Col, Dropdown, Form, Row } from "react-bootstrap";
import { getMenuProfile, getProfileUpdate } from "../../api/user";
import { DeliveryInterface } from "../../CommonInterface/CommonInterface";
import { getDeliveryTime } from "../../api/deliverytime";
import DatePicker from "react-datepicker";
import { showNotifications } from "../../lib/toaster";
// icon image
import ANTIOXIDANTS from "../../assets/icon/blueberry.png";
import CASEIN from "../../assets/icon/casein.png";
import RICH_IN_COLLAGEN from "../../assets/icon/collagen.png";
import GLUTEN_FREE from "../../assets/icon/gluten-free.png";
import IN_GOUT from "../../assets/icon/gout.png";
import WITH_HIGH_BLOOD_PRESSURE from "../../assets/icon/hypertension.png";
import NO_OXALATES from "../../assets/icon/kidney.png";
import WITH_GASTRITIS from "../../assets/icon/medical.png";
import N0_CRABS from "../../assets/icon/no-carbs.png";
import NO_FAT from "../../assets/icon/no-fat.png";
import HIGH_PROTEIN from "../../assets/icon/protein.png";
import VEGETARIAN from "../../assets/icon/salad.png";
import WITHOUT_SUGAR from "../../assets/icon/sugar-free.png";
import VEGAN from "../../assets/icon/vegan.png";
import VITAMINS from "../../assets/icon/vitamin.png";
import RICH_IN_GOOD_FATS from "../../assets/icon/RICH_IN_GOOD_FATS.png";
import { splitTimeRange } from "../../lib/commonFunction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

interface updateProfileProps {
  setUpdateProfile: any;
}

const EditProfile = ({ setUpdateProfile }: updateProfileProps) => {
  const options = [
    { value: "ANTIOXIDANTS", label: "Antioxidants", icon: ANTIOXIDANTS },
    { value: "N0_CRABS", label: "No crabs", icon: N0_CRABS },
    { value: "GLUTEN_FREE", label: "Gluten Free", icon: GLUTEN_FREE },
    { value: "WITHOUT_SUGAR", label: "Without Sugar", icon: WITHOUT_SUGAR },
    { value: "NO_FAT", label: "No Fat", icon: NO_FAT },
    { value: "NO_OXALATES", label: "No Oxalates", icon: NO_OXALATES },
    { value: "NO_CHOLESTEROL", label: "No Cholesterol", icon: VEGETARIAN },
    { value: "GOOD_FAT", label: "Good Fat", icon: RICH_IN_GOOD_FATS },
    {
      value: "RICH_IN_COLLAGEN",
      label: "Rich in Collagen",
      icon: RICH_IN_COLLAGEN,
    },
    { value: "VEGAN", label: "Vegan", icon: VEGAN },
    { value: "VEGETARIAN", label: "Vegetarian", icon: VEGETARIAN },
    { value: "HIGH_PROTEIN", label: "High Protein", icon: HIGH_PROTEIN },
    { value: "VITAMINS", label: "Vitamins", icon: VITAMINS },
    { value: "CASEIN", label: "Casein", icon: CASEIN },
    {
      value: "WITH_HIGH_BLOOD_PRESSURE",
      label: "With High Blood Pressure",
      icon: WITH_HIGH_BLOOD_PRESSURE,
    },
    { value: "WITH_GASTRITIS", label: "With Gastritis", icon: WITH_GASTRITIS },
    { value: "IN_GOUT", label: "In Gout", icon: IN_GOUT },
  ];
  const [lunchList, setLunchList] = useState<DeliveryInterface[]>([]);
  const [breakfastList, setBreakfastList] = useState<DeliveryInterface[]>([]);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [allergens, setAllergens] = useState<string>("");
  const [allergensText, setAllergensText] = useState<string>("");
  const [purpose, setPurpose] = useState<string>("");
  const [purposeText, setPurposeText] = useState<string>("");
  const [medicalConditions, setMedicalConditions] = useState<string>("");
  const [medicalText, setMedicalText] = useState<string>("");
  const [disLikedProduct, setDisLikedProduct] = useState<string>("");
  const [disLikedText, setDisLikedText] = useState<string>("");
  const [consumeMeat, setConsumeMeat] = useState<string>("");
  const [importantTags, setImportantTags] = useState<string[]>([]);
  const [disLike, setDisLike] = useState<boolean>(false);
  const [medicalCondition, setMedicalCondition] = useState<boolean>(false);
  const [allergy, setAllergy] = useState<boolean>(false);
  const [medical, setMedical] = useState<boolean>(false);
  const [breakStartTime, setBreakStartTime] = useState<string>("");
  const [breakEndTime, setBreakEndTime] = useState<string>("");
  const [lunchStartTime, setLunchStartTime] = useState<string>("");
  const [lunchEndTime, setLunchEndTime] = useState<string>("");
  const [gender, setGender] = useState("");
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [age, setAge] = useState("");
  const [medications, setMedications] = useState("");
  const [note, setNote] = useState("");
  const [daysOfMenu, setDaysOfMenu] = useState(0);
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [deliveryTime, setDeliveryTime] = useState("");
  const [priceRange, setPriceRange] = useState("");

  useEffect(() => {
    getMenuProfile().then((data) => {
      if (data.statusCode !== 200) {
      } else {
        setGender(data.data.menu.gender);
        setWeight(data.data.menu.weight);
        setHeight(data.data.menu.height);
        setAge(data.data.menu.age);
        setPurpose(data.data.menu.purpose);
        if (
          data.data.menu.purpose === "cut_weight" ||
          data.data.menu.purpose === "gain_muscles" ||
          data.data.menu.purpose === "other"
        ) {
          setMedicalCondition(false);
        } else {
          setMedicalCondition(true);
          setPurpose("medical_condition");
          setPurposeText(data.data.menu.purpose);
        }

        if (data.data.menu.allergens === "no") {
          setAllergy(false);
          setAllergens(data.data.menu.allergens);
        } else {
          setAllergy(true);
          setAllergens("yes");
          setAllergensText(data.data.menu.allergens);
        }
        setMedicalConditions(data.data.menu.medicalConditions);
        if (data.data.menu.medicalConditions === "yes") {
          setMedical(true);
          setMedicalConditions("yes");
          setMedicalText(data.data.menu.medicalConditions);
        } else {
          setMedical(false);
        }
        setDisLikedProduct(data.data.menu.disLikedProduct);
        if (data.data.menu.disLikedProduct !== "no") {
          setDisLike(true);
          setDisLikedProduct("yes");
          setDisLikedText(data.data.menu.disLikedProduct);
        } else {
          setDisLike(false);
        }
        setMedications(data.data.menu.medications);
        const tags = data.data.menu.importantTags.split(',')
        setImportantTags(tags);
        setConsumeMeat(data.data.menu.consumeMeat);
        setNote(data.data.menu.note);
        setDeliveryAddress(data.data.menu.deliveryAddress);
        setDeliveryTime(data.data.menu.deliveryTime);
        setDaysOfMenu(data.data.menu.daysOfMenu);
        setSelectedDate(data.data.menu.startDate);
        setBreakStartTime(data.data.menu.breakfastStartTime);
        setBreakEndTime(data.data.menu.breakfastEndTime);
        setLunchStartTime(data.data.menu.lunchStartTime);
        setLunchEndTime(data.data.menu.lunchEndTime);
        setPriceRange(data.data.menu.priceRange);
      }
    });

    getDeliveryTime("LUNCH").then((data) => {
      setLunchList(data.data.deliveryTimes);
    });
    getDeliveryTime("BREAKFAST").then((data) => {
      setBreakfastList(data.data.deliveryTimes);
    });
  }, []);

  const handleBreakfastChange = (event: any) => {
    const { startTime, endTime } = splitTimeRange(event.target.value);
    setBreakStartTime(startTime);
    setBreakEndTime(endTime);
  };
  const handleLunchChange = (event: any) => {
    const { startTime, endTime } = splitTimeRange(event.target.value);
    setLunchStartTime(startTime);
    setLunchEndTime(endTime);
  };

  function handleRadioChange(event: any) {
    if (event.target.value === "medical_condition") {
      setMedicalCondition(true);
      setPurpose(event.target.value);
    } else {
      setMedicalCondition(false);
      setPurpose(event.target.value);
    }
  }

  function handleAllergyChange(event: any) {
    if (event.target.value === "yes") {
      setAllergy(true);
      setAllergens(event.target.value);
    } else {
      setAllergy(false);
      setAllergens(event.target.value);
    }
  }

  function handleMedicalChange(event: any) {
    if (event.target.value === "yes") {
      setMedical(true);
      setMedicalConditions(event.target.value);
    } else {
      setMedical(false);
      setMedicalConditions(event.target.value);
    }
  }

  function handleDislikeChange(event: any) {
    if (event.target.value === "yes") {
      setDisLike(true);
      setDisLikedProduct(event.target.value);
    } else {
      setDisLike(false);
      setDisLikedProduct(event.target.value);
    }
  }
  function handleMeatChange(event: any) {
    setConsumeMeat(event.target.value);
  }
  const handleSelectChange = (selectedValue: any) => {
    if (!importantTags.includes(selectedValue)) {
      if (importantTags.length < 5) {
        setImportantTags([...importantTags, selectedValue]);
      } else {
        showNotifications("error", "You can't select more than 5 items.");
      }
    }
  };
  function formatDate(date: any) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  const handleSelect = (date: Date) => {
    const currentDate = new Date();
    const minAllowedDate = new Date();
    minAllowedDate.setDate(currentDate.getDate() + 5);
    if (date > minAllowedDate) {
      const formattedDate = formatDate(date);
      setSelectedDate(formattedDate);
    } else {
      showNotifications("error", "Please select a future date.");
    }
  };
  const updateProfile = () => {
    const profileInfo: any = {
      gender: gender,
      weight: weight,
      height: height,
      age: age,
      medications: medications,
      consumeMeat: consumeMeat,
      importantTags: importantTags.join(','),
      note: note,
      daysOfMenu: daysOfMenu,
      deliveryTime: deliveryTime,
      priceRange: priceRange,
      deliveryAddress: deliveryAddress,
    };
    if (purposeText) {
      profileInfo.purpose = purposeText;
    }
    if (purpose) {
      profileInfo.purpose = purpose;
    }
    if (breakStartTime) {
      profileInfo.breakfastStartTime = breakStartTime;
      profileInfo.breakfastEndTime = breakEndTime;
    }
    if (lunchStartTime) {
      profileInfo.lunchStartTime = lunchStartTime;
      profileInfo.lunchEndTime = lunchEndTime;
    }
    if (selectedDate) {
      profileInfo.startDate = selectedDate;
    }
    if (allergensText) {
      profileInfo.allergens = allergensText;
    }
    if (allergens) {
      profileInfo.allergens = allergens;
    }
    if (disLikedText) {
      profileInfo.disLikedProduct = disLikedText;
    }
    if (disLikedProduct) {
      profileInfo.disLikedProduct = disLikedProduct;
    }
    if (medicalConditions) {
      profileInfo.medicalConditions = medicalConditions;
    }
    if (medicalText) {
      profileInfo.medicalConditions = medicalText;
    }

    getProfileUpdate(profileInfo).then((data) => {
      if (data.statusCode !== 200) {
        showNotifications("error", data.message);
      } else {
        showNotifications("success", data.message);
        setUpdateProfile(false);
      }
    });
  };


  const deleteSelectItem = (selectItem: string) => {
    const updatedOptions = importantTags.filter((value: any) => value !== selectItem);
    setImportantTags(updatedOptions);
  };


  return (
    <>
      <h1 className="text-center">Create Menu Profile</h1>
      <Row>
        <Col md={6}>
          <div className="menuInput">
            <label className="inputHeading">Gender</label>
            <select
              onChange={(e) => setGender(e.target.value)}
              value={gender}
              className="form-control"
            >
              <option value="MALE">Male</option>
              <option value="FEMALE">Female</option>
            </select>
          </div>
          <div className="menuInput">
            <label className="inputHeading">Height (feet)</label>
            <input
              type="text"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
              placeholder="Ex: 6 feet"
              className="form-control"
            />
          </div>
          <div className="menuInput">
            <label className="inputHeading">
              When are you free from work so you can eat?
            </label>
            <select
              onChange={(e) => setDeliveryTime(e.target.value)}
              value={deliveryTime}
              className="form-control"
            >
              <option value="BREAKFAST">Breakfast</option>
              <option value="LUNCH">Lunch</option>
              <option value="BOTH">Both</option>
            </select>
          </div>
          <div className="menuInput">
            <label className="inputHeading">
              In what time do you want to delivery breakfast?
            </label>
            <select
              className="form-select"
              id="inputGroupSelect01"
              onChange={handleBreakfastChange}
              disabled={deliveryTime !== "BREAKFAST" && deliveryTime !== "BOTH"}
            >
              <option value="">{`${breakStartTime}-${breakEndTime}`}</option>
              {breakfastList.map((option, index) => {
                const value = `${option.startTime}-${option.endTime}`;
                return (
                  <option key={`lunch${index}`} value={value}>
                    {option.startTime}-{option.endTime}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="menuInput">
            <label className="inputHeading">Select an tags:</label>
            <Dropdown className="form-control tagsDrop">
              <Dropdown.Toggle
                className="tagsBox"
                id="dropdown-basic"
              >
                Select the tags
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {options.map((option) => (
                  <Dropdown.Item
                    className="tagsBox"
                    key={option.value}
                    onClick={() =>
                      handleSelectChange(option.value)
                    }
                  >
                    <img
                      className="icon"
                      src={option.icon}
                      alt=""
                    />
                    {option.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <div className="selectItems mt-2">
              {importantTags && importantTags.map((tag: any, index: number) => {
                const option = options.find(opt => opt.value === tag.trim());
                if (option) {
                  return <span>
                    <img key={index} src={option.icon} alt={option.label} style={{ width: '20px', height: '20px', borderRadius: '20px', marginRight: '8px' }} />
                    {option.label}
                    <FontAwesomeIcon icon={faClose} onClick={() => deleteSelectItem(option.value)} />
                  </span>
                }
                return null;
              })}
            </div>
          </div>
          <div className="menuInput">
            <label className="inputHeading">What is your range?</label>
            <select
              onChange={(e) => setPriceRange(e.target.value)}
              value={deliveryTime}
              className="form-control"
            >
              <option value="">Select Price Range</option>
              <option value="10-15$">10-15$</option>
              <option value="15-20$">15-20$</option>
              <option value="I want best menu for me">
                I want best menu for me
              </option>
            </select>
          </div>

          <div className="menuInput">
            <label className="inputHeading">Purpose of diet </label>
            <Form.Check
              inline
              type="radio"
              label="Cut weight"
              name="purposeDiet"
              id="inlineRadio1"
              value="cut_weight"
              checked={purpose === "cut_weight"}
              onChange={handleRadioChange}
            />
            <Form.Check
              inline
              type="radio"
              label="Gain muscles"
              name="purposeDiet"
              id="inlineRadio2"
              value="gain_muscles"
              checked={purpose === "gain_muscles"}
              onChange={handleRadioChange}
            />
            <Form.Check
              inline
              type="radio"
              label="Medical condition"
              name="purposeDiet"
              id="inlineRadio3"
              value="medical_condition"
              checked={purpose === "medical_condition"}
              onChange={handleRadioChange}
            />

            <Form.Check
              inline
              type="radio"
              label="Other"
              name="inlineRadioOptions"
              id="inlineRadio4"
              value="other"
              checked={purpose === "other"}
              onChange={handleRadioChange}
            />
            {medicalCondition ? (
              <div className="extraText">
                <textarea
                  className="form-control"
                  value={purposeText}
                  onChange={(e) => setPurposeText(e.target.value)}
                ></textarea>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="menuInput">
            <label className="inputHeading">Medical conditions</label>
            <Form.Check
              inline
              type="radio"
              label="Yes , same as purpose of diet"
              name="medicalCondition"
              id="inlineRadio8"
              value="yes_diet"
              checked={medicalConditions === "yes_diet"}
              onChange={handleMedicalChange}
            />
            <br />
            <Form.Check
              inline
              type="radio"
              label="YES"
              name="medicalCondition"
              id="inlineRadio9"
              value="yes"
              checked={medicalConditions === "yes"}
              onChange={handleMedicalChange}
            />
            <Form.Check
              inline
              type="radio"
              label="NO"
              name="medicalCondition"
              id="inlineRadio11"
              value="no"
              checked={medicalConditions === "no"}
              onChange={handleMedicalChange}
            />
            {medical ? (
              <div className="extraText">
                <textarea
                  className="form-control"
                  value={medicalText}
                  onChange={(e) => setMedicalText(e.target.value)}
                ></textarea>
              </div>
            ) : (
              ""
            )}
          </div>
        </Col>
        <Col md={6}>
          <div className="menuInput">
            <label className="inputHeading">Weight (kg)</label>
            <input
              type="text"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              placeholder="Ex: 50 kg"
              className="form-control"
            />
          </div>
          <div className="menuInput">
            <label className="inputHeading">Age</label>
            <input
              type="number"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              placeholder="Age"
              className="form-control"
            />
          </div>

          <div className="menuInput">
            <label className="inputHeading">
              Menu Start Date(minimum 5 days after ordering)
            </label>
            <DatePicker
              locale="en-gb"
              className="form-control"
              selected={selectedDate}
              onChange={handleSelect}
              placeholderText="Select a date"
              dateFormat="yyyy/MM/dd"
            />
          </div>
          <div className="menuInput">
            <label className="inputHeading">
              In what time do you want to delivery lunch?
            </label>
            <select
              className="form-select"
              id="inputGroupSelect01"
              onChange={(e) => handleLunchChange(e)}
              disabled={deliveryTime !== "LUNCH" && deliveryTime !== "BOTH"}
            >
              <option value="">{`${lunchStartTime}-${lunchEndTime}`}</option>
              {lunchList.map((option, index) => {
                const value = `${option.startTime}-${option.endTime}`;
                return (
                  <option key={`lunch${index}`} value={value}>
                    {option.startTime}-{option.endTime}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="menuInput">
            <label className="inputHeading">Medications</label>
            <input
              type="text"
              value={medications}
              onChange={(e) => setMedications(e.target.value)}
              placeholder="Medications"
              className="form-control"
            />
          </div>

          <div className="menuInput">
            <label className="inputHeading">
              For how many days you want your menu?
            </label>
            <select
              onChange={(e) => setDaysOfMenu(parseInt(e.target.value))}
              value={daysOfMenu}
              className="form-control"
            >
              <option value="">Select Time</option>
              <option value="7">1 week/20лв</option>
              <option value="14">2 weeks/35лв</option>
              <option value="30">1 month/50лв</option>
            </select>
          </div>

          <div className="menuInput">
            <label className="inputHeading">
              Is there product that you don’t like of our menu?
            </label>
            <Form.Check
              inline
              type="radio"
              label="YES"
              name="disLike"
              id="disLike01"
              value="yes"
              checked={disLikedProduct === "yes"}
              onChange={handleDislikeChange}
            />
            <Form.Check
              inline
              type="radio"
              label="NO"
              name="disLike"
              id="disLike02"
              value="no"
              checked={disLikedProduct === "no"}
              onChange={handleDislikeChange}
            />
            {disLike ? (
              <div className="extraText">
                <textarea
                  className="form-control"
                  value={disLikedText}
                  onChange={(e) => setDisLikedText(e.target.value)}
                ></textarea>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="menuInput">
            <label className="inputHeading">Do you have any allergies ?</label>
            <Form.Check
              inline
              type="radio"
              label="YES"
              name="inlineRadioOptions"
              id="inlineRadio6"
              value="yes"
              checked={allergens === "yes"}
              onChange={handleAllergyChange}
            />
            <Form.Check
              inline
              type="radio"
              label="NO"
              name="inlineRadioOptions"
              id="inlineRadio7"
              value="no"
              checked={allergens === "no"}
              onChange={handleAllergyChange}
            />
            {allergy ? (
              <div className="extraText">
                <textarea
                  className="form-control"
                  value={allergensText}
                  onChange={(e) => setAllergensText(e.target.value)}
                ></textarea>
              </div>
            ) : (
              ""
            )}
          </div>
        </Col>
        <Col md={12}>
          <div className="menuInput">
            <label className="inputHeading">
              Do you consume meat or animal products?
            </label>
            <Form.Check
              inline
              type="radio"
              label="Yes, everyday"
              name="consumeMeat"
              id="meat01"
              value="Yes, everyday"
              checked={consumeMeat === "Yes, everyday"}
              onChange={handleMeatChange}
            />
            <Form.Check
              inline
              type="radio"
              label="Yes, few times a week"
              name="consumeMeat"
              id="meat02"
              value="Yes, few times a week"
              checked={consumeMeat === "Yes, few times a week"}
              onChange={handleMeatChange}
            />
            <Form.Check
              inline
              type="radio"
              label="No, I am vegan"
              name="consumeMeat"
              id="meat03"
              value="No, I am vegan"
              checked={consumeMeat === "No, I am vegan"}
              onChange={handleMeatChange}
            />
            <Form.Check
              inline
              type="radio"
              label="No, I am vegetarian"
              name="inlineRadioOptions"
              id="meat04"
              value="No, I am vegetarian"
              checked={consumeMeat === "No, I am vegetarian"}
              onChange={handleMeatChange}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="menuInput">
            <label className="inputHeading">Note</label>
            <textarea
              value={note}
              onChange={(e) => setNote(e.target.value)}
              placeholder="Note"
              className="form-control"
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="menuInput">
            <label className="inputHeading">Delivery Address</label>
            <textarea
              value={deliveryAddress}
              onChange={(e) => setDeliveryAddress(e.target.value)}
              placeholder="Delivery Address"
              className="form-control"
            />
          </div>
        </Col>
      </Row>
      <div className="text-center mt-5">
        <button type="submit" className="btn btn-info" onClick={updateProfile}>
          Update
        </button>
      </div>
    </>
  );
};

export default EditProfile;
