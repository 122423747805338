import React, { useEffect, useState } from 'react'
import Layout from '../Common/Layout/Layout'
import { Container, Row, Col, Carousel } from 'react-bootstrap'
import "./Home.css";
import { CountUp } from 'use-count-up'

// font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faCheck } from '@fortawesome/free-solid-svg-icons';

// image
import clock from "../../assets/image/clock.png";
import doctor from "../../assets/image/doktor.png";
import fresh from "../../assets/image/fresh.png";
import frozen from "../../assets/image/frozen.png";
import money from "../../assets/image/no-money.png";
import strong from "../../assets/image/strong.png";
import { getFoodList } from '../../api/foods';
import { FoodInterface } from '../../CommonInterface/CommonInterface';
import OrderSidebar from '../Common/OrderSidebar/OrderSidebar';
// OwlCarousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from '../Common/Card/Card';
import { showNotifications } from '../../lib/toaster';
import { countActiveFoods } from '../../lib/commonFunction';


const Home = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [itemList, setItemList] = useState<FoodInterface[] | undefined>([]);
  const [parentState, setParentState] = useState<number>(0);
  const [orderList, setOrderList] = useState<any>([]);
  //  number counting
  const onComplete = () => {
    return { shouldRepeat: true, delay: 2 }
  }
  // food list load
  useEffect(() => {
    let body = { "tags": "", "featured": true }
    getFoodList(1, 5, body).then((data) => {
      if (data.statusCode !== 200) {
        showNotifications('error', "Please connect to the backend server.!!");
      }
      else {
        const filteredItems = data?.data.foods.filter((food: any) => food.isActive === true);
        setItemList(filteredItems);
      }
    });

    const storedOrderList = localStorage.getItem('orderList');
    if (storedOrderList) {
      setOrderList(JSON.parse(storedOrderList));
    }
  }, []);

  // order side bar

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };


  const CustomPrevArrow: React.FC<React.HTMLProps<HTMLDivElement>> = (props) => (
    <div {...props} style={{ ...props.style, display: 'block', background: 'transparent' }}>
      Previous
    </div>
  );

  const CustomNextArrow: React.FC<React.HTMLProps<HTMLDivElement>> = (props) => (
    <div {...props} style={{ ...props.style, display: 'block', background: 'transparent' }}>
      Next
    </div>
  );

  const generateSettings = (itemCount: number) => {
    return {
      dots: false,
      infinite: true,
      autoplaySpeed: 2000,
      autoplay: false,
      slidesToShow: itemCount,
      slidesToScroll: 1,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1290,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            prevArrow: <CustomPrevArrow />,
            nextArrow: <CustomNextArrow />,
          },
        },
        {
          breakpoint: 1220,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            prevArrow: <CustomPrevArrow />,
            nextArrow: <CustomNextArrow />,
          },
        },
        {
          breakpoint: 994,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            prevArrow: <CustomPrevArrow />,
            nextArrow: <CustomNextArrow />,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            prevArrow: <CustomPrevArrow />,
            nextArrow: <CustomNextArrow />,
          },
        },
        {
          breakpoint: 660,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            prevArrow: null,
            nextArrow: null,
          },
        },
      ],
    }
  }



  return (
    <>
      <Layout>
        {/* banner */}
        <section className="bannerSection">
          <Carousel fade>
            <Carousel.Item>
              <img width="100%" src="https://www.kalanidhithemes.com/live-preview/landing-page/flavoury/all-demo/images/banner_slide01.png" alt="" />
              <Carousel.Caption className='carouselText'>
                <h3>На време</h3>
                <p>Наш служител ще достави поръчката Ви в определен от Вас час.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img width="100%" src="https://www.kalanidhithemes.com/live-preview/landing-page/flavoury/all-demo/images/banner_slide02.png" alt="" />
              <Carousel.Caption className='carouselText'>
                <h3>Индивидуално меню</h3>
                <p>Маг. Фармацефт ще се грижи за изготвянето на индивидуалните менюта.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img width="100%" src="https://www.kalanidhithemes.com/live-preview/landing-page/flavoury/all-demo/images/banner_slide03.png" alt="" />
              <Carousel.Caption className='carouselText'>
                <h3>Без замразяване</h3>
                <p>Това подобрява, както вкуса, така и качеството на вашата храна.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img width="100%" src="http://restabook.kwst.net/images/bg/bg2.jpg" alt="" />
              <Carousel.Caption className='carouselText'>
                <h3>Прясно и чисто</h3>
                <p>Държим, както на хигиената, така и на чистото фермерско производство</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </section>
        {/* menu carousel */}

        <div className="homeCarousel">
          {itemList && itemList.length ?
            <section className="topMenu">
              <Container>
                <Row>
                  <Col md={12}>
                    <div className="title_heading">
                      <h1>Топ менюта</h1>
                    </div>
                  </Col>
                </Row>
                <Row>
                  {itemList && countActiveFoods(itemList) === 1 ? <>
                    <div className='d-flex justify-content-center'>
                      <div className="oneSlider">
                        {itemList && itemList.map((data, i) => (
                          <div className="" key={`menuCard${i}`}>
                            {data.isActive === true &&
                              <Card styleClass={`menu_item`} data={data} setParentState={setParentState} parentState={parentState} />
                            }
                          </div>
                        ))}
                      </div>
                    </div>
                  </> : itemList && countActiveFoods(itemList) === 2 ? <>
                    <div className='d-flex justify-content-center'>
                      <div className="twoSlider">
                        <Slider {...generateSettings(2)}>
                          {itemList && itemList.map((data, i) => (
                            <div className="" key={`menuCard${i}`}>
                              {data.isActive === true &&
                                <Card styleClass={`menu_item`} data={data} setParentState={setParentState} parentState={parentState} />
                              }
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </div>
                  </> : countActiveFoods(itemList) === 3 ? <>
                    <div className='d-flex justify-content-center'>
                      <div className="threeSlider">
                        <Slider {...generateSettings(3)}>
                          {itemList && itemList.map((data, i) => (
                            <div className="" key={`menuCard${i}`}>
                              {data.isActive === true &&
                                <Card styleClass={`menu_item`} data={data} setParentState={setParentState} parentState={parentState} />
                              }
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </div>
                  </> : <>
                    <Slider {...generateSettings(4)}>
                      {itemList && itemList.map((data, i) => (
                        <div className="" key={`menuCard${i}`}>
                          {data.isActive === true &&
                            <Card styleClass={`menu_item`} data={data} setParentState={setParentState} parentState={parentState} />
                          }
                        </div>
                      ))}
                    </Slider>
                  </>}

                </Row>
              </Container>
            </section>
            : <></>}
        </div>
        {/* about us */}

        <section className="keySection" id="about">
          <Container>
            <Row>
              <Col md={12}>
                <div className="aboutUs_heading">
                  <h1>За нас</h1>
                </div>
              </Col>
              <Col md={4} className='mt-2' data-aos="zoom-in" data-aos-duration="1000">
                <div className="key-card">
                  <img src={clock} alt="" />
                  <h1>На време</h1>
                  <p>Наш служител ще достави поръчката Ви в определен от Вас час.</p>
                </div>
              </Col>
              <Col md={4} className='mt-2' data-aos="zoom-in" data-aos-duration="1000">
                <div className="key-card">
                  <img src={strong} alt="" />
                  <h1>Здравословно</h1>
                  <p><FontAwesomeIcon icon={faCheck} /> Витамини</p>
                  <p><FontAwesomeIcon icon={faCheck} /> Минерали</p>
                  <p><FontAwesomeIcon icon={faCheck} /> Антиоксиданти</p>
                  <p>и ВСИЧКО, от което имате нужда.</p>
                </div>
              </Col>
              <Col md={4} className='mt-2' data-aos="zoom-in" data-aos-duration="1000">
                <div className="key-card">
                  <img src={fresh} alt="" />
                  <h1>Прясно и чисто</h1>
                  <p>Държим, както на хигиената, така и на чистото фермерско производство</p>
                </div>
              </Col>
              <Col md={4} className='mt-2' data-aos="zoom-in" data-aos-duration="1000">
                <div className="key-card">
                  <img src={money} alt="" />
                  <h1>Не плащаш по време на доставка</h1>
                  <p>Спестете ВРЕМЕ и ПАРИ</p>
                </div>
              </Col>
              <Col md={4} className='mt-2' data-aos="zoom-in" data-aos-duration="1000">
                <div className="key-card">
                  <img src={doctor} alt="" />
                  <h1>Индивидуално меню</h1>
                  <p><b>Маг. Фармацефт</b> ще се грижи за изготвянето на индивидуалните менюта.</p>
                </div>
              </Col>
              <Col md={4} className='mt-2' data-aos="zoom-in" data-aos-duration="1000">
                <div className="key-card">
                  <img src={frozen} alt="" />
                  <h1>Без замразяване</h1>
                  <p>Това подобрява, както вкуса, така и качеството на вашата храна.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* food count */}
        <section className="countSection counterImage">
          <Container>
            <Row>
              <Col md={3}>
                <div className="counter-number">
                  <h1><CountUp isCounting end={100} duration={4} onComplete={onComplete} /></h1>
                  <p>Daily Customers</p>
                </div>
              </Col>
              <Col md={3}>
                <div className="counter-number">
                  <h1><CountUp isCounting end={100} duration={4} onComplete={onComplete} /></h1>
                  <p>Best Food</p>
                </div>
              </Col>
              <Col md={3}>
                <div className="counter-number">
                  <h1><CountUp isCounting end={100} duration={4} onComplete={onComplete} /></h1>
                  <p>Master chefs</p>
                </div>
              </Col>
              <Col md={3}>
                <div className="counter-number">
                  <h1><CountUp isCounting end={100} duration={4} onComplete={onComplete} /></h1>
                  <p>Our experience</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* what we are */}
        <section className="companySection">
          <Container>
            <Row>
              <Col md={5} data-aos="fade-right">
                <div className='our_company_image'>
                  <img className='firstImage' src="http://jellydemos.com/html/elixir/images/gallery/02.jpg" alt="" />
                  {/* <img className='secondImage' src="http://restabook.kwst.net/light/images/all/14.jpg" alt="" /> */}
                  {/* <img src="https://demo.web3canvas.com/themeforest/tomato/img/about.png" alt="" /> */}
                </div>
              </Col>
              <Col md={7} data-aos="fade-left" className='d-flex align-items-center'>
                <div className="companyInfo">
                  <h1>Кои сме ние</h1>
                  <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui dicta odit molestiae inventore cum voluptates sed modi vero atque? Tenetur quod labore vero id modi. Tempora perferendis nam temporibus vitae. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus quaerat quam reprehenderit eos, sunt repudiandae, cum eligendi voluptas rem quisquam alias voluptatum consectetur, ducimus eveniet vel aperiam laboriosam natus laudantium?</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* sidebar */}

        <div className={`rightSidebar ${isCollapsed ? "" : "collapsedSidebar"}`}>
          <button className='cartBtn' onClick={toggleSidebar}><FontAwesomeIcon icon={faCartShopping} /><span>{orderList.length}</span></button>
          {/* Sidebar content */}
          <div className="sideItems">
            <OrderSidebar parentState={parentState} orderList={orderList} setOrderList={setOrderList} />
          </div>
        </div>
      </Layout>

    </>
  )
}

export default Home